import { useContext } from "react"
import { AssessmentFilterHeaderContext } from "../AssessmentFilterHeader"
import { useQuery } from "@tanstack/react-query"
import { queryClient as qc } from 'keys'
import { getAssessmentDefsFetch } from "store/middlewares/assessment.crud"
import { useSelector } from "react-redux"

const useGetAssessmentDefOptions = () => {

    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters } = useContext(AssessmentFilterHeaderContext)

    return useQuery(
        {
            queryKey: qc.assessmentDefs,
            refetchOnWindowFocus: false,
            retry: 0,
            queryFn: async ({ signal }) => {
                const { data } = await getAssessmentDefsFetch({
                    headerFilters,
                    assessmentFilters,
                    signal
                })
                return data
            }
        }
    )
}

export default useGetAssessmentDefOptions