import { useCallback, useContext } from "react"
import { NormalizerContext } from "../NormalizerContext"
import { last, groupBy, cloneDeep } from "lodash"
import { useQueryClient } from "@tanstack/react-query"
import qc from "keys/queryClient"

const useViewUpdatedScoreRating = () => {
    const { scoreRatingSemesters, activeScoreItemStatus, setActiveScoreItemStatus, activeScoreRatingIndex, stats, setStats, scoreRatingOptions } = useContext(NormalizerContext)
    const queryClient = useQueryClient()

    const groupByRating = (statsToGroup) => {
        const nStats = groupBy(Object.entries(statsToGroup), ([key, value]) => value.rating_index);
        const groupStats = Object.entries(nStats).reduce((acc, [rating, items]) => {
            acc[parseInt(rating)] = items.map(([key, value]) => ({ key, ...value }));
            return acc;
        }, {});

        return groupStats
    }

    return useCallback(() => {
        const updatedGraph = stats[activeScoreRatingIndex]

        setActiveScoreItemStatus("view")

        let nStats = [...stats]
        let prevIndex = activeScoreRatingIndex - 1
        let nextIndex = activeScoreRatingIndex + 1

        let nextData = updatedGraph
        while (prevIndex >= 0) {
            const scoreRating = scoreRatingSemesters.data[prevIndex]
            let currentData = cloneDeep(queryClient.getQueryData([qc.fluencyStats, scoreRating.grade, scoreRating.semester])?.graph)
            // let currentData = { ...nStats[prevIndex] }
            let gSourceGraph = groupByRating(currentData)

            for (let i = 5; i > 0; i--) {
                const gPrev = groupByRating(currentData)
                const gNext = groupByRating(nextData)

                if (gPrev[i]) {
                    const graphKey = last(gPrev[i]).key
                    const nextGraphKey = last(gNext[i]).key

                    if (Number(nextGraphKey) < Number(graphKey) && i != 1) {
                        for (let j = nextGraphKey + 1; j <= graphKey; j++) {
                            currentData[j] = {
                                key: j,
                                count: 0, percent: 0,
                                ...currentData[j],
                                rating_id: currentData[graphKey].rating_id - 1,
                                rating_index: currentData[graphKey].rating_index - 1
                            }
                        }
                    }
                }
                else {
                    let startKey = gNext[i][0].key
                    let endKey = last(gNext[i]).key
                    const rating_index = gNext[i][1].rating_index
                    const rating_id = gSourceGraph[rating_index][1].rating_id

                    for (let j = startKey; j <= endKey; j++)
                        currentData[i] = {
                            key: j,
                            count: 0,
                            percent: 0,
                            ...currentData[j],
                            rating_id: rating_id,
                            rating_index: rating_index
                        }
                }
            }
            nStats[prevIndex] = currentData
            prevIndex--
            nextData = currentData
        }

        let prevData = updatedGraph
        while (nextIndex <= stats.length - 1) {
            const scoreRating = scoreRatingSemesters.data[nextIndex]
            let currentData = cloneDeep(queryClient.getQueryData([qc.fluencyStats, scoreRating.grade, scoreRating.semester])?.graph)
            // let currentData = { ...nStats[nextIndex] }
            let gSourceGraph = groupByRating(currentData)

            for (let i = 5; i > 0; i--) {
                const gNext = groupByRating(currentData)
                const gPrev = groupByRating(prevData)

                if (gNext[i]) {
                    const graphKey = last(gNext[i]).key
                    if (!gPrev[i])
                        debugger
                    const prevGraphKey = last(gPrev[i]).key

                    if (Number(prevGraphKey) > Number(graphKey) && i != 1) {
                        for (let j = graphKey; j < prevGraphKey + 1; j++)
                            currentData[j] = {
                                key: j,
                                count: 0,
                                percent: 0,
                                ...nextData[j],
                                rating_id: currentData[graphKey].rating_id,
                                rating_index: currentData[graphKey].rating_index
                            }
                    }
                }
                else {
                    let startKey = gPrev[i][0].key
                    let endKey = last(gPrev[i]).key
                    const rating_index = gPrev[i][1].rating_index
                    const rating_id = gSourceGraph[rating_index][1].rating_id

                    for (let j = Number(startKey); j <= Number(endKey); j++)
                        currentData[j] = {
                            key: j,
                            count: 0,
                            percent: 0,
                            ...currentData[j],
                            rating_id: rating_id,
                            rating_index: rating_index
                        }
                }
            }
            nStats[nextIndex] = currentData
            nextIndex++
            prevData = currentData
        }

        setStats(nStats)
    }, [scoreRatingSemesters, scoreRatingOptions, stats])

}

export default useViewUpdatedScoreRating
