import React, { useContext, useEffect, useRef, useState } from 'react';

// import './index.css';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

import {
    Column,
    Table as ReactTable,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    flexRender,
} from '@tanstack/react-table';

// import TablePaginationActions from './actions';


import { Checkbox, CircularProgress, IconButton, Skeleton, TableSortLabel, Toolbar, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, List } from '@mui/icons-material';
import { TableStyles } from './Style';
import { Input } from 'components/Form';
import { tableHooks, ToolbarColumnsButton } from '.';
import { useSelector } from 'react-redux';
import { actions } from 'store';
import { useDispatch } from 'react-redux';
import { dataGridModel } from 'keys';
import { AbilityContext } from 'components/Permissions/Can';
import { isEmpty } from 'lodash';
import { sortByFullName } from 'utils/sorting';

function TablePaginationActions(props) {
    const { count, page, hideSorting, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <KeyboardArrowRight />
            </IconButton>
        </div>
    );
}

const LocalTable = ({ withTitle, hideSorting, withTabs, hideToolbar, hidePagination, sorting, setSorting, refetch, visibilityModelKey, rowKey = "id", data, columns, loading, page, setPage, onRowSelection }) => {
    const dispatch = useDispatch()
    const [rowSelection, setRowSelection] = useState({})
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');

    const ability = useContext(AbilityContext)

    const { visibilityModel } = useSelector(state => state.dataGrid)

    const search = tableHooks.useSearchFilter()

    const tableData = React.useMemo(
        () => (loading ? Array(15).fill({}) : data?.results),
        [loading, data]
    );

    const tableColumns = React.useMemo(
        () =>
            loading
                ? columns.map((column, index) => ({
                    ...column,
                    cell: <Skeleton key={index} />,
                }))
                : columns.filter((field) =>
                    ability.can(field.role, field.feature) || !field.role
                ),
        [loading, columns]
    );


    const table = useReactTable({
        data: tableData,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onPaginationChange: (getPagination, y) => {
            const { pageIndex: currentIndex } = getPagination(pageIndex)
            setPage(currentIndex)
        },
        onColumnVisibilityChange: (newModel) => {
            let val = newModel()
            dispatch(actions.updateVisibilityModel({ key: dataGridModel[visibilityModelKey], value: val }))
        },
        enableSorting: !hideSorting,
        manualPagination: true,
        rowCount: data?.count,
        debugTable: true,
        manualSortBy: true,
        onSortingChange: (sortingUpdater) => {
            let nextSort = sortingUpdater(sorting)
            const prevSort = { ...sorting[0] }

            if (
                nextSort[0]?.id === 'first_name' ||
                nextSort[0]?.id === 'last_name' ||
                (isEmpty(nextSort) && prevSort?.id === 'first_name' || prevSort?.id === 'last_name')
            ) {
                const customSort = sortByFullName(prevSort)
                if (customSort)
                    setSorting(isEmpty(customSort) ? [] : [customSort]);
                else setSorting(nextSort)

            }
            else setSorting(nextSort)
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        state: {
            pagination: {
                pageSize: 15,
                pageIndex: page
            },
            rowSelection,
            sorting,
            columnVisibility: visibilityModel?.[visibilityModelKey]
        },
    });

    const { pageSize, pageIndex } = table.getState().pagination;

    useEffect(() => {
        onRowSelection?.(table.getFilteredSelectedRowModel().flatRows.map(
            row => row.original[rowKey]
        ))
    }, [table?.getSelectedRowModel()])

    return (
        <TableStyles withTitle={withTitle} withTabs={withTabs} hidePagination={hidePagination} hideToolbar={hideToolbar}>
            {!hideToolbar &&
                <Toolbar sx={[{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }]}>
                    <Input  {...search} border />
                    <ToolbarColumnsButton table={table} />
                </Toolbar>}
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {onRowSelection &&
                                    <TableCell key="checkbox-header" padding="checkbox">
                                        <Checkbox
                                            color='thirdly'
                                            indeterminate={table.getIsSomeRowsSelected()}
                                            checked={table.getIsAllRowsSelected()}
                                            onChange={table.getToggleAllPageRowsSelectedHandler()}
                                        />
                                    </TableCell>
                                }

                                {headerGroup.headers.map(header => (
                                    <TableCell key={header.id} colSpan={header.colSpan}>
                                        {header.column.getCanSort() ?
                                            <TableSortLabel
                                                key={header.id}
                                                active={orderBy === header.id}
                                                direction={
                                                    header.column.getCanSort() ?
                                                        header.column.getNextSortingOrder() === 'asc' ? "asc" :
                                                            header.column.getNextSortingOrder() === 'desc' ? "desc"
                                                                :
                                                                order :
                                                        undefined}
                                                onClick={header.column.getToggleSortingHandler()}
                                                colSpan={header.colSpan}
                                            >
                                                <div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </div>

                                            </TableSortLabel>
                                            :
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table?.getRowModel()?.rows.map(row => (
                            <TableRow key={row.id} sx={{ height: 52 }}>
                                {onRowSelection &&
                                    <TableCell key="checkbox" padding="checkbox">
                                        <Checkbox
                                            color='thirdly'
                                            checked={row.getIsSelected()}
                                            disabled={!row.getCanSelect()}
                                            onChange={row.getToggleSelectedHandler()}
                                        />
                                    </TableCell>}
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} size='small' component="th" scope="row">
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                !hidePagination && (loading ||
                    <TablePagination
                        enableSorting={false}
                        rowsPerPageOptions={[]}
                        component="div"
                        count={data?.count}
                        rowsPerPage={pageSize}
                        page={pageIndex - 1}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} מתוך ${count}`}
                        onPageChange={(_, page) => {
                            table.setPageIndex(page + 1);
                        }}
                        onRowsPerPageChange={e => {
                            const size = e.target.value ? Number(e.target.value) : 15;
                            table.setPageSize(size);
                        }}
                    // ActionsComponent={TablePaginationActions}
                    />)
            }

        </TableStyles>
    );
}

export default LocalTable
