import React, { useRef, useState } from 'react'
import Normalizer from './Normalizer'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { queryClient as qc } from 'keys'
import { getScoreRatingSemestersFetch } from 'store/middlewares/rating.crud'

const NormalizerPage = () => {
    const [assessmentFilters, setAssessmentFilters] = useState({
        category: null,
        method: null
    })
    const [scoreRatingOptions, setScoreRatingOptions] = useState([])
    const [applyDataConfirmOpen, setApplyDataConfirmOpen] = useState(false)
    const [activeScoreRatingIndex, setActiveScoreRatingIndex] = useState(null)
    const [activeScoreItemStatus, setActiveScoreItemStatus] = useState("")
    const [stats, setStats] = useState([])

    const scoreRatingSemesterOptions = queryOptions({
        queryKey: [qc.scoreRatingSemesters],
        queryFn: async () => {
            const { data } = await getScoreRatingSemestersFetch({ assessmentFilters })
            setScoreRatingOptions(new Array(data.length).fill({
                showChildren: false,
                edit: false,
                showStartBar: false,
                showEndBar: false,
                showTable: false
            }))
            setStats(new Array(data.length))
            return data
        },
        enabled: false
    })
    const scoreRatingSemesters = useQuery(scoreRatingSemesterOptions)

    const commonState = {
        scoreRatingSemesters,
        assessmentFilters, setAssessmentFilters,
        scoreRatingOptions, setScoreRatingOptions,
        activeScoreRatingIndex, setActiveScoreRatingIndex,
        applyDataConfirmOpen, setApplyDataConfirmOpen,
        stats, setStats,
        activeScoreItemStatus, setActiveScoreItemStatus
    }

    return (
        <NormalizerContext.Provider value={commonState}>
            <Normalizer />
        </NormalizerContext.Provider>
    )
}

export const NormalizerContext = React.createContext()
export default NormalizerPage