import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Button, fieldsTyps } from 'components/Form';
import { WrapDialogActions, WrapDialog } from './Style'
import { FlexBetween } from 'styles/GlobalStyle';
import { Close } from '@mui/icons-material';

const BasicAlert = (props) => {
    const { systemUI, children, title, handleAlert, footerActions } = props

    const handleClose = () => {
        handleAlert(false)
    }

    const onSubmit = (event) => {
        event.preventDefault();
        handleClose();
    }

    return (
        <WrapDialog
            fullWidth
            open={true}
            $systemUI={systemUI}
            PaperProps={{
                component: 'form',
                onSubmit: onSubmit
            }}
        >
            <DialogTitle>
                <FlexBetween $center={true}>
                    {title}
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </FlexBetween>
            </DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>
            {footerActions &&
                <WrapDialogActions>
                    {footerActions.map((act, index) => {
                        const Component = fieldsTyps[act.component || "button"]
                        return (
                            <Component key={index} {...act} />)
                    })}
                </WrapDialogActions>
            }
        </WrapDialog>
    );
}

export default BasicAlert
