import { isEmpty, map } from "lodash"
import { convertArrayToIdsString } from "utils/formatter"
import { isArray } from "lodash"
import { statuses } from "keys"

const getScoreRatingFilters = ({ assessmentFilters }) => {
    const { category, method } = assessmentFilters

    let params = {}

    if (category)
        params.category = category?.id || category
    if (method)
        params.method = method?.id || method

    return params
}

const getAssessmentFilters = (headerFilters, assessmentFilters, statusFilters, currentTestTaker) => {
    const { group, test_taker, affiliation, grade, t_class } = headerFilters || {}
    const { status: generalStatus, year, semester, assessment_def, gender, category, grade_of_allocation } = assessmentFilters || {}
    const { status, sub_status } = statusFilters || {}

    let params = {}
    if (group) params.group = group.id
    if (t_class) params.t_class = t_class.id
    else {
        if (affiliation) params.affiliation = affiliation.id
        if (grade) params.grade = grade.id
    }

    if (generalStatus) {
        if (generalStatus == 'completed') {
            params.status = statuses.inProgress
            params.sub_status = statuses.wordsComplete
        }
        else if (generalStatus == 'undone') {
            params.status = statuses.created
        }
    }

    if (semester && year) params.semester_and_year = `${semester?.id || semester || ""}_${year?.id || year}`
    if (semester && grade_of_allocation)
        params.semester = semester?.id || semester
    else if (year) params.year = `${year?.id || year}`

    if (!isEmpty(assessment_def)) params.assessment_defs =
        isArray(assessment_def) ?
            convertArrayToIdsString(assessment_def) :
            assessment_def.id
    if (test_taker) params.test_taker = test_taker.id
    else if (currentTestTaker) params.test_taker = currentTestTaker.test_taker_id
    if (status) params.status = map(status).join(',')
    if (sub_status) params.sub_status = sub_status
    if (gender) params.gender = gender.id
    if (grade_of_allocation) params.grade_of_allocation = grade_of_allocation?.id || grade_of_allocation
    if (!isEmpty(category)) {
        if (isArray(category))
            params.categories = convertArrayToIdsString(category)
        else {
            params.category = category?.id || category
        }
    }

    return params

}

export { getScoreRatingFilters, getAssessmentFilters }