import { useMemo } from "react";
import { Rating } from "components/Rating";
import { ratingList } from "keys";
import { ratingListTheme } from "keys/rating";
import { FlexCenter } from "styles/GlobalStyle";
import constants from './constants';

const useColumns = () => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'key',
                header: "דרוג",
                cell: info => constants[info.getValue()]
            },
            {
                accessorKey: '1',
                header: <FlexCenter><Rating label="גבוה" color={ratingListTheme[0]} /></FlexCenter>,
                cell: ({ getValue, row }) => <FlexCenter>{getValue()}{row.original.key === 'percent' && '%'}</FlexCenter>
            },
            {
                accessorKey: '2',
                header: <FlexCenter><Rating label='בטווח המצופה' color={ratingListTheme[1]} /></FlexCenter>,
                cell: ({ getValue, row }) => <FlexCenter>{getValue()}{row.original.key === 'percent' && '%'}</FlexCenter>

            },
            {
                accessorKey: '3',
                header: <FlexCenter><Rating label="מעט נמוך" color={ratingListTheme[2]} /></FlexCenter>,
                cell: ({ getValue, row })  => <FlexCenter>{getValue()}{row.original.key === 'percent' && '%'}</FlexCenter>

            },
            {
                accessorKey: '4',
                header: <FlexCenter><Rating label="נמוך" color={ratingListTheme[3]} /></FlexCenter>,
                cell: ({ getValue, row })  => <FlexCenter>{getValue()}{row.original.key === 'percent' && '%'}</FlexCenter>
            },
            {
                accessorKey: '5',
                header: <FlexCenter><Rating label="נמוך מאד" color={ratingListTheme[4]} /></FlexCenter>,
                cell: ({ getValue, row })  => <FlexCenter>{getValue()}{row.original.key === 'percent' && '%'}</FlexCenter>
            },
        ], []
    )

    return columns
}

export default useColumns