import React from 'react'
import ReactPlayer from 'react-player'
import { DashboardStyles, Video, WrapVideo, WrapHeader, WrapContent, WrapTitle, Title, GeneralDashboardStyles } from './Style'
import { constants as c, constants, useDashboardCards } from './hooks'
import { Button } from 'components/Form'
import { Img, LightButton } from 'styles/GlobalStyle'
import { Go2 } from 'assets'
import { CircularProgress, SvgIcon } from '@mui/material'
import './style.css'
import { thirdly2 } from 'styles/theme'

const DashboardPage = () => {
    const cards = useDashboardCards()
    const { title } = constants

    const handleFullScreen = () => {
        const player = document.querySelector('video');
        if (player) {
            player.requestFullscreen();
        }
    };

    return (
        <GeneralDashboardStyles cartoonHeight={240} cartoonWidth={160}>
            <div className='dashboard'>
                <div className="card-header">
                    <div className='dashboard-cartoon-card'>
                        <div className='dashboard-title'>
                            {title}
                        </div>
                        <div className='dashboard-cartoon'>
                            <Img src={Go2} />
                        </div>
                    </div>

                    <div className='dashboard-video'>
                        <ReactPlayer
                            onStart={handleFullScreen}
                            className="camera_video_discover video"
                            url={require('../../../records/Dashboard.mp4')}
                            width='100%'
                            height='100%'
                            controls={true}
                        />
                    </div>
                </div>
                <div className='card-content'>
                    {cards.map((card) => {
                        const { label, button, loading, icon } = card
                        return (
                            <div className='dashboard-card'>
                                {loading ?
                                    <CircularProgress color="gray" size={30} /> :
                                    <div className='card-item'>
                                        <div className='card-icon'>
                                            <SvgIcon component={icon} inheritViewBox />
                                        </div>
                                        <div className='card-label'>
                                            {<card.label />}
                                        </div>
                                        <div className='card-btn'>
                                            {button.color == 'light' ?
                                                <LightButton size="small" systemUI {...button} />
                                                :
                                                <Button size="small" systemUI {...button} />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </GeneralDashboardStyles>
    )
}

export default DashboardPage