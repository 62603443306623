import React from 'react'
import { Audio } from 'components/Form'

const AudioPlayer = (props) => {
    const { row, column } = props
    const value = row.original.audio_file_url
    const { meta } = column.columnDef

    const { width, height } = meta

    return (
        <Audio width={width} height={height} input={{ value: value }} />
    )
}

export default AudioPlayer
