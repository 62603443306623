import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend, } from 'chart.js';
import { map } from 'lodash';
import { rating1, rating2, rating3, rating4, rating5, dark } from 'styles/theme';
import { SpaceBetween } from 'styles/GlobalStyle';
import { semesters } from 'keys';
import { AssessmentDistributionStyles } from './Style'
import { customFormatDate, formatDate } from 'utils/formatter';
import ratingList, { ratingListLabel } from 'keys/rating';
import 'chartjs-adapter-date-fns'; // Import the date adapter
import 'chartjs-plugin-annotation';
import { RatingList } from 'components/Rating';

// Register the necessary components
ChartJS.register(LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend);

const AssessementDistribution = (props) => {
    const { graph, percent } = props
    const { assessment_distribution } = graph || {}

    const dateValues = assessment_distribution.map(assessment => {
        return formatDate(assessment.date)
    });
    const assessmentMap = assessment_distribution.reduce((acc, assessment, index) => {
        const formattedDate = formatDate(assessment.date)
        acc[formattedDate] = `${assessment?.grade} ${customFormatDate(assessment.date)}`;
        return acc;
    }, {});
    const wordsPerMinute = map(assessment_distribution, "value")
    const ratings = Array.from({ length: 5 }, (_, index) =>
        map(assessment_distribution, `rating${index + 1}`)
    );

    const datasets = useMemo(
        () => [
            {
                data: wordsPerMinute,
                fill: false,
                backgroundColor: dark,
                borderColor: dark,
                borderWidth: 4,
            },
            {
                data: ratings[0],
                fill: false,
                backgroundColor: rating1,
                borderColor: rating1,
                borderWidth: 1.5,
            },
            {
                data: ratings[1],
                fill: false,
                backgroundColor: rating2,
                borderColor: rating2,
                borderWidth: 1.5,
            },
            {
                data: ratings[2],
                fill: false,
                backgroundColor: rating3,
                borderColor: rating3,
                borderWidth: 1.5,
            },
            {
                data: ratings[3],
                fill: false,
                backgroundColor: rating4,
                borderColor: rating4,
                borderWidth: 1.5,
            },
            {
                data: ratings[4],
                fill: false,
                backgroundColor: rating5,
                borderColor: rating5,
                borderWidth: 1.5,
            },
        ], [graph]
    )

    const options = {
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
            },
        },
        plugins: {
            legend: {
                display: false, // Hides the legend
            },
            tooltip: {
                titleAlign: "center",
                bodyAlign: "center",
                footerAlign: "center",
                filter: function (tooltipItem) {
                    return tooltipItem.datasetIndex === 0
                },
                displayColors: false,
                onlyShowForDatasetIndex: [0],
                callbacks: {
                    title: function (context) {
                        let { dataIndex, datasetIndex } = context?.[0] || {};
                        //the first dataset is the test_taker graph
                        if (datasetIndex == 0)
                            return `${assessment_distribution?.[dataIndex]?.reading_assessment_name} - ${assessment_distribution[dataIndex].grade}/${semesters[assessment_distribution[dataIndex].semester - 1].name} `;
                        return null
                    },
                    beforeBody: function (context) {
                        let { dataIndex, datasetIndex } = context?.[0] || {};
                        //the first dataset is the test_taker graph
                        if (datasetIndex == 0)
                            return `${percent ? "דיוק" : "קצב"}: ${assessment_distribution[dataIndex].value}${percent ? '%' : ' מילים לדקה'}`;
                        return ""
                    },
                    label: function (context) {
                        let { dataIndex, datasetIndex } = context
                        //the first dataset is the test_taker graph
                        if (datasetIndex == 0)
                            return `${ratingListLabel[assessment_distribution[dataIndex].rating_index - 1]}`;
                        return ""
                    },
                    labelTextColor: (context) => {
                        let { dataIndex, datasetIndex } = context
                        //the first dataset is the test_taker graph
                        if (datasetIndex == 0)
                            return `${ratingList[assessment_distribution[dataIndex].rating_index - 1]}`;
                        return ""
                    },
                    labelPointStyle: () => ({ display: "none" }),
                    labelPointStyle: () => ({ textAlign: "end" })
                }
            }
        },
        scales: {
            scaleShowValues: true,
            x: {
                max: dateValues[dateValues.length - 1],
                type: "time",
                // min: dateValues[0],
                // max: dateValues[dateValues.length - 1],
                // autoSkip: false,
                ticks: {
                    minRotation: 45,
                    maxRotation: 45,
                    source: 'data',
                    autoSkip: false,
                    display: true,
                    padding: 10,
                    callback: function (value, index1, labels) {
                        const valueDate = formatDate(value)

                        if (assessmentMap[valueDate]) {
                            return `${assessmentMap[valueDate]}` // החזר את ה-label המתאים
                        }

                        return ""
                    },

                },
                time: {
                    unit: 'day',
                }
            }
        }
    }

    return (
        <AssessmentDistributionStyles>
            <div className='distribution-content'>
                <Line
                    responsive={false}
                    data={{
                        labels: dateValues,
                        datasets: datasets,
                    }}
                    options={options}
                />
            </div>
            <SpaceBetween />
            <RatingList title="ביצועי התלמיד" />
        </AssessmentDistributionStyles>
    );
};

export default AssessementDistribution;