
const useGradeOfAllocationCell = () => {
    const gradeOfAllocation = {
        accessorKey: 'reading_assessment_grade_of_allocation_name',
        header: "שכבת ביצוע",
        feature: "grade-of-allocation",
        role: "read"
    }

    return gradeOfAllocation
}

export default useGradeOfAllocationCell