import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { alerts as alertKeys } from "keys"
import { backToMarkingWordsFetch } from "store/middlewares/markingWords.crud"
import { useSelector } from "react-redux"
import { queryClient as qc } from "keys"

const useBackToConfirmation = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const { assessment } = useSelector(state => state.assessment)

    const { mutateAsync: backToMarkingWordsRQ, isPending: backToMarkingWordsLoading } = useMutation({
        mutationFn: backToMarkingWordsFetch,
        mutationKey: "backToMarkingWords"
    })

    const backToMarkingWords = useCallback(async (assessmentRow) => {
        const nAssessment = assessmentRow || assessment
        const res = await backToMarkingWordsRQ(nAssessment)
        dispatch(actions.updateAssessment(res.data))
    }, [assessment, backToMarkingWordsLoading])

    const setBackToConfirmation = useCallback(async (value, props) => {
        dispatch(actions.setAlerts({
            key: alertKeys.backTo, value: {
                open: value,
                props: props,
                onClose: (props) => props.onClose(props)
            }
        }))
    }, [])

    const openBackToMarkingWordsConfirmation = useCallback((assessmentRow, refetchAfterBackTo) => {
        setBackToConfirmation(true, {
            title: "החזר לסימון",
            content: "האם התכוונת להחזיר את המבדק לסימון?",
            loading: backToMarkingWordsLoading,
            onConfirm: async () => {
                await backToMarkingWords(assessmentRow)

                if (refetchAfterBackTo)
                    await queryClient.invalidateQueries({
                        queryKey: [qc.adminAssessments],
                    })
                setBackToConfirmation(false)

            },
            onClose: () => {
                setBackToConfirmation(false)
            }
        })
    }, [backToMarkingWordsLoading])

    return {
        action: openBackToMarkingWordsConfirmation,
        loading: backToMarkingWordsLoading
    }
}

export default useBackToConfirmation