import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { queryClient as qc } from "keys"
import { compareFetch, initAIFetch, whisperFetch } from "store/middlewares/audioTranscription.crud"

const useGetInit = () => {
    return useQuery({
        queryKey: qc.initAIServer,
        queryFn: initAIFetch,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchInterval: false,
    })
}

const useCompare = () => {
    return useMutation({ mutationFn: compareFetch})
}

const useWhisper = () => {
    const { mutateAsync: compareRQ } = useCompare()

    return useMutation({
        mutationFn: (blob) => whisperFetch(blob)
    })
}

export default { useGetInit, useWhisper, useCompare }