import { queryClient as qc } from "keys"

const sorting = {
    [qc.report]: {
        "reading_assessment_name": "reading_assessment_def__name",
        "first_name": "test_taker__first_name",
        "last_name": "test_taker__last_name",
        "reading_assessment_category_name": "reading_assessment_def__type__category__label",
        "rating_insertion_percent": "insertion_count",
        "rating_minor_error_percent": "minor_error_count",
        "rating_error_percent": "error_percent",
        "rating_factored_percent_accuracy": "factored_percent_accuracy",
        "rating_repetition_percent": "repetition_percent",
        "rating_insertion_percent": "insertion_percent",
        "rating_self_correction_percent": "self_correction_percent",
        "rating_minor_error_percent": "minor_error_percent",
        "rating_self_correction_and_minor_error_percent": "self_correction_and_minor_error_percent",
        "rating_num_words_per_minute": "num_words_per_minute",
        "fluency": "num_words_per_minute",
        "rating_omission_error_and_minor_error_percent": "omission_error_and_minor_error_percent"
    },
    [qc.getWithLastAssessments]: {
        "grade_name": "t_class__grade__name",
        "t_class_name": "t_class__name",
        'test_taker_id': "id",
        "grade_at_registration_name": "grade_at_registration__name",
        "affiliation_name": "t_class__affiliation__name"
    },
    [qc.assessments]: {
        "last_name": "test_taker__last_name",
        "first_name": "test_taker__first_name",
        "reading_assessment_category_name": "reading_assessment_def__type__category__label",
        "reading_assessment_name": "reading_assessment_def__name",
        "reading_assessment_grade_of_allocation_name": "grade_of_allocation"
    },
    [qc.users]: {

    },
    [qc.adminAssessments]: {
        "reading_assessment_name": "reading_assessment_def__name",
        "first_name": "test_taker__first_name",
        "last_name": "test_taker__last_name",
        "reading_assessment_grade_of_allocation_name": "grade_of_allocation",
        "marking_words_update_by_username": "marking_words_update_by__first_name",
        "audio_cutting_update_by_username": "audio_cutting_update_by__first_name",
        "transcription_update_by_username": "transcription_update_by__first_name"
    }
}

export default sorting