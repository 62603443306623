import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import { dark, thirdly } from "styles/theme";
import { Boy } from '@mui/icons-material';
import { Center, CenterStyle, Scroll } from "styles/GlobalCss";
import { white } from "styles/theme";
import { Accordion, Box, Button, SvgIcon, Tabs } from "@mui/material";
import { thirdly2 } from "styles/theme";
import { thirdly4 } from "styles/theme";

const graphH = '33px'

const RateAndAccuracyDescription = styled.div`
`

const WrapGraphTexts = styled.div`
display:flex;
flex-direction: column;
width: 70px;
font-size:15px;
row-gap:10px;

.wrap-graph-text{
display: flex;
height:38px;
column-gap:5px;
align-items: center;
}

.graph-text{
}

.graph-icon{
transform:rotate(180deg);
}
`

const ChildrenGraphStyles = muiStyled(Box)(({ isDrag, showAllChildren, theme }) => ({
    display: "flex",
    height: showAllChildren && isDrag && 190,
    columnGap: 15,
    '.dragging': {
        cursor: "grabbing"
    },
    '.children-count': {
        display: "flex",
        columnGap: 3,
        color: dark,
        direction: "ltr"
    },
    '.half-circle': {
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 30
    },
    '.items-count': {
        position: "relative",
        right: 13
    }

}))

const LineStyles = muiStyled(Box)(({ showAllChildren, theme, color, firstItem }) => ({
    color: color,
    'path': {
        fill: color
    },
    '.rating-details': {
        fontSize: 14,
        position: "relative",
        right: firstItem ? 0 : 13,
        height: 25,
        display: "flex",
        justifyContent: "center"
    },
    '.count': {
        height: 40,
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column"
    },
    '.percent': {
        alignItems: "end"
    }
}))



const NumberCircle = styled.div`
.circle {
    width: 30px; /* קוטר העיגול */
    height: 30px; /* קוטר העיגול */
    border-radius: 50%; /* הופך את התיבה לעיגול */
    background-color: white; /* צבע העיגול */
    position: relative; /* מאפשר מיקום של אלמנטים פנימיים */
    overflow: hidden; /* מונע תוכן לחרוג מהעיגול */
    box-shadow: inset 0 0 0 3px green; /* חצי פנימי ירוק */
}

.circle::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%; /* עיגול */
    clip-path: inset(0 50% 0 0); /* חותך חצי מהעיגול */
    box-shadow: inset 0 0 0 3px yellow; /* חצי פנימי ירוק */

}

.number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* מוודא שהמספר יהיה מעל ה-gradient */
    font-size: 24px; /* גודל המספר */
    color: black; /* צבע המספר */
}
`


const AssessmentDistributionStyles = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '.distribution-content': {
        backgroundColor: "white",
        height: 444,
        width: 888,
        ...CenterStyle
    }
}))


const WrapAccordion = muiStyled(Accordion)(({ theme }) => (
    {
        '&.MuiAccordion-root': {
            boxShadow: "none",
            borderBottom: `1px solid ${thirdly2}`,
            position: "unset",
        },
        '&.MuiAccordionSummary-root': {
            height: "100px !important",
        },
        fontSize: '16px',
        '.tab-title': {
            display: "flex",
            alignItems: "center",
            columnGap: "10px"
        }
    }))

const WrapTabs = muiStyled(Tabs)(({ theme }) => (
    {
        '& .Mui-selected': {
            color: `${dark} !important`,
            fontWeight: "bold"
        }
    }))

const RateAndAccuracyLabel = styled.div`
background-color:${white};
border-radius:7px;
padding:5px;
`

const GraphDisplay = styled(Button)`
width:30px !important;
min-width: 30px !important;
border-radius:5px !important;
`

const RateAndAccuracyItem = styled.div`
${Scroll};
`

const WrapReports = styled.div`
display: flex;
row-gap: 10px;
flex-direction: column;
height:100%;
flex-grow: 1;
overflow: auto;
font-size:16px;
`

const WrapTitle = styled.div`
font-size:18px;
`

const WrapChallengeItem = styled.div`
width:100%
`

const WrapChallenges = styled.div`
display: flex;
width: 100%;
padding-left:3px;
row-gap: 10px;
flex-direction: column;
overflow:auto;
${Scroll};

._in_soon{
padding-right:5px;
}
`

const WrapNumChild = styled.div`
font-size:11px;
height:18px;
color:${props => props.theme.color};
// text-shadow: ${props => `0.5px 0.5px 0.5px ${props.theme.color}`};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
direction: rtl;
padding: 4px 2px;
box-sizing: border-box;
text-align:${props => props.theme.isDrag ? "end" : "center"}
`

const WrapIcon = styled(SvgIcon)`
width:20px !important;
height:20px !important;
path{
fill:${props => props.theme.color} !important;
}
`

const WrapGraphItem = styled.div`
${props => props.isDrag && `
position: relative;
bottom:58px;
`}
flex-grow: 1;
display: flex;
flex-direction: column;
// align-items: center;
height:${props => props.height}px;
${props => props.current_test_taker && `
border-radius:8px;
border:1px solid ${thirdly};
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
`} 
`

const WrapChild = styled.div`
height:37px;
${Center};
justify-content:center;
position: relative;
`

const Number = styled.div`

`

const WrapGradeGraph = styled.div`

`

const HeaderSummary = styled.div`

`

const BoldSummary = styled.span`
color:${props => props.color};
`

const WrapDetailesTestTaker = styled.div`
display: flex;
height:70px;
width: 100%;
column-gap: 10px;
`

const WrapSelectRating = styled.span`
`

const WrapRating = styled.div`
display: flex;
row-gap: 8px;
flex-direction: column;
color: white;
`

const WrapSummaryData = styled.div`

`

const Rate = styled.div`
width:100%;
box-sizing:border-box;
background:${props => props.color};
border-radius: 2px;
padding:5px;
font-weight:400;
font-size:15px;
`

const WrapContentSection = styled.div`
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-between;
`

const WrapActions = styled.div`
display: flex;
row-gap: 10px;
flex-direction: column;
`

const WrapRatingSections = styled.div`
display: flex;
column-gap: 10px;
flex-grow:1;
overflow: auto;
${Scroll};
`

const WrapRate = styled.div`
font-size:20px;
display:flex;
column-gap: 5px;
align-items:center;
`

const WrapAccuracy = styled.div`
font-size:20px;

`

const WrapSummary = styled.div`
padding:15px;
font-size:22px;
line-height: 40px;
display: flex;
flex-direction: column;
row-gap: 10px;
width: 100%;
box-sizing: border-box;
height:100;
overflow:auto;
${Scroll};
`

const AssessmentDetails = styled.div`

`

const Summary = styled.div`

`

const GraphNumber = styled.span`

align-items:center;
// align-items:${props => !props.theme.isDrag && "center"};
height: 35px;
color:${props => props.theme.isDrag ? props.theme.textColor : props.theme.color};
width: 100%;
background:${props => props.theme.isDrag ?
        props.theme.firstRatingItem ?
            `linear-gradient( -90deg, ${props.theme.lightColor}, ${props.theme.lightColor} 49%,  ${props.theme.prevLightColor} 51%, ${props.theme.prevLightColor} 51% )`
            : props.theme.lightColor :
        white
    };
// background:${props => props.theme.isDrag ? props.theme.lightColor : white};
display: flex;
justify-content: center;
flex-direction: column;
font-size:12px;

`


const WrapGraph = styled.span`
opacity:${props => props.disabled && "0.5"};
flex-grow:1;
// justify-content:center;
overflow-y:hidden;
overflow-x:auto;
// overflow:hidden;
display:flex;
direction: ltr;
// height:${props => props.showFooter ? 155 : 100}px;
align-items: center;
width:100%;
${Scroll};


`



export { NumberCircle, RateAndAccuracyLabel, WrapChallengeItem, LineStyles, AssessmentDistributionStyles, WrapGraphTexts, ChildrenGraphStyles, WrapTitle, WrapTabs, RateAndAccuracyDescription, WrapAccordion, GraphDisplay, RateAndAccuracyItem, WrapChallenges, WrapNumChild, WrapIcon, WrapChild, WrapGraphItem, WrapGraph, GraphNumber, WrapGradeGraph, HeaderSummary, BoldSummary, WrapDetailesTestTaker, WrapSelectRating, WrapContentSection, WrapActions, WrapRating, Rate, Summary, WrapSummaryData, WrapRatingSections, Number, WrapRate, WrapAccuracy, WrapReports, WrapSummary, AssessmentDetails }