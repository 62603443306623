
import React from 'react'
import { WrapIconButton } from './Styled'
import { gTheme } from 'styles/theme';
import { Close } from '@mui/icons-material';

const CustomIconButton = (props) => {
    const { hideFill, transparent, className, gridArea, border, arrayIndex, onDoubleClick, height, width, icon, disabled, label, color, action, $bg, size, $border } = props;

    return (
        <WrapIconButton
            transparent={transparent}
            gridArea={gridArea ? `${gridArea}_${arrayIndex}` : null}
            size={size}
            onDoubleClick={onDoubleClick}
            width={width}
            height={height}
            className={className}
            // loadingPosition="end"
            onClick={() => action(props)}
            $border={$border}
            color={color}
            themeColor={gTheme[color]}
            $bg={$bg}
            $disabled={typeof disabled == 'function' ? disabled(arrayIndex) : disabled}
            $hideFill={hideFill}
        // variant='contained'
        >
            {icon}
            {label}
        </WrapIconButton>
    )
}

export default CustomIconButton