import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { verifyEmailFetch } from 'store/middlewares/auth.crud'
import { toastConfig } from 'config'
import { FlexCenter } from 'styles/GlobalStyle'


const VerifyEmail = () => {
    const [isVerify, setIsVerify] = useState(false)

    const { mutateAsync: verifyEmail, isPending: verifyEmailLoading } = useMutation({
        mutationFn: verifyEmailFetch,
        onSuccess: () => {
            toast("כתובת המייל מאומתת, הינך יכול לסגור חלון זה ולהמשיך בתהליך ההרשמה", toastConfig)
            // setIsVerify(true)
        },
        onError: (error) => {
            if (error?.response?.data?.error == "Verification token not found") {
                toast.error("לינק לא תקין/בתוקף", toastConfig)
            }
        }
    })

    const params = useParams()
    const token = params.token

    useEffect(() => {
        verifyEmail({ token: token })
    }, [])

    return (
        // isVerify ?
        //     <div>כתובת המייל מאומתת, הינך יכול לסגור חלון זה ולהמשיך בתהליך ההרשמה</div>
        //     :
        <FlexCenter>
            {verifyEmailLoading && <CircularProgress color='secondary' size={70} />}
        </FlexCenter>

    )
}

export default VerifyEmail