import { gender } from "keys"


const constants = {
    assessmentCompletionAlert: {
        finish: {
            [gender.female]: "סִיַּמְתְּ",
            [gender.male]: "סִיַּמְתָּ"
        }
    },
    instructionsTitle: "לביצוע האבחון:",
    micOff: 'נראה ששכחתם ללחוץ על המיקרופון על מנת להתחיל את ההקלטה .....',
    instructions: [
        {
            label: "לחצו על הכפתור הכתום כשאתם מוכנים לקרוא"
        },
        {
            label: "חכו שהספירה לאחור תסתיים ותתחילו לקרוא"
        },
        {
            label: "לחצו על עמודי הספר כדי לדפדף"
        },
        {
            label: 'כשגומרים לקרוא לוחצים על "סיימתי"'
        },
        {
            label: 'לוחצים על כפתור "המשך" לעבור לשלב הבא או "סיום"'
        }
    ]
}

export default constants