import { useQuery } from "@tanstack/react-query"
import { queryClient as qc } from 'keys'
import { getLetterAddOnFetch, getLetterDescriptionsFetch, getVowelCodesFetch } from "store/middlewares/transcription.crud"


const useGetLetterAddOns = (params) => {

    return useQuery({
        queryKey: qc.letterAddOns,
        queryFn: getLetterAddOnFetch,
        refetchOnWindowFocus: false,
        ...params
    })
}

const useGetVowelCodes = (params) => {

    return useQuery({
        queryKey: qc.vowelCodes,
        queryFn: getVowelCodesFetch,
        refetchOnWindowFocus: false,
        ...params
    })
}

const useGetLetterDescriptions = (params) => {

    return useQuery({
        queryKey: qc.letterDescriptions,
        queryFn: getLetterDescriptionsFetch,
        refetchOnWindowFocus: false,
        ...params,
    })
}


export default { useGetLetterAddOns, useGetVowelCodes, useGetLetterDescriptions }