import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { queryClient as qc } from 'keys'
import { LinguisticLabContext } from '../LinguisticLabContext'
import { getFrequencyFetch } from 'store/middlewares/assessment.crud'

const useFrequency = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { selectedTab, assessmentFilters, setAssessmentFilters } = useContext(LinguisticLabContext)

    return useQuery({
        queryKey: [qc.assessmentFrequency],
        enabled: false,
        refetchOnWindowFocus: false,
        queryFn: async ({ signal }) => {
            const { data } = await getFrequencyFetch({ factored: Boolean(!selectedTab), assessmentFilters, headerFilters, testTaker, signal })
            return data
        }
    }
    )

}

export default useFrequency