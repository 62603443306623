import React, { useContext, useEffect, useRef } from 'react'
import { AppRoutes } from 'components/Router'
import { Header, Snackbar, SideBar } from 'components'
import { MainCrud } from './hooks'
import { WrapContent, Wrap } from './Style'
import { WrapScreen } from 'styles/GlobalStyle'
import { WrapAlert } from 'components/Alerts'
import { useSelector } from 'react-redux'
import { audioTranscriptionRQ, pulldowns, StoreCrud, application, assessmentDefRQ, testTakerRQ } from 'store/hooks'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { Can } from 'components/Permissions'
import { AbilityContext } from 'components/Permissions/Can'
import { ThemeProvider } from 'styled-components'

const Main = () => {
    const { init } = MainCrud()
    const { getTestTaker } = StoreCrud()
    const ability = useContext(AbilityContext)

    const { testTaker } = useSelector(state => state.testTaker)

    const setAppData = application.useInitAppData()
    const cleanAppData = application.useCleanAppData()

    const previousTestTaker = useRef(testTaker)
    const dispatch = useDispatch()

    const { } = audioTranscriptionRQ.useGetInit()
    const { } = assessmentDefRQ.useGetAllAssessmentDefs()
    const { } = testTakerRQ.useGetLanguages()

    const getTestTakerOnChange = async () => {
        if (testTaker?.test_taker_id && !isEqual(testTaker, previousTestTaker.current)) {
            const nTestTaker = await getTestTaker(testTaker.test_taker_id)
            previousTestTaker.current = nTestTaker
            dispatch(actions.setTestTaker(nTestTaker))
        }
    }

    useEffect(() => {
        setAppData()
        init()
    }, [])

    useEffect(() => { getTestTakerOnChange() }, [testTaker])

    useEffect(() => {
        return (() => {
            cleanAppData()
        })
    }, [])

    const theme = {
        showSidebar: ability.can("read", "sidebar"),
        showToolbar: ability.can("read", "toolbar")
    }

    return (
        <WrapScreen>
            <WrapAlert />
            <Snackbar />
            <Can I="read" a="sidebar">
                <SideBar />
            </Can>
            <Can I="read" an="toolbar">
                <Header />
            </Can>

            <ThemeProvider theme={theme}>
                <Wrap>
                    <WrapContent>
                        <AppRoutes />
                    </WrapContent>
                </Wrap>
            </ThemeProvider>
        </WrapScreen>
    )
}

export default Main


