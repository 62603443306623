import React from 'react'
import TestTakerHeader from 'components/School/TestTakers/TestTakerHeader'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import AssessmentDetails from 'components/School/Assessment/AssessmentDetails'
import { Can } from 'components/Permissions'

const WrapAppRoute = (props) => {
    const { isGeneralPage, feature, children, hideHeader, title } = props

    const { assessment } = useSelector(state => state.assessment)

    console.log("feature", feature)
    return (
        <>
            <Can I="read" a={`${feature}-title`}>
                <h1 style={{ height: 23, fontSize: 19, padding: 0, margin: 0 }}>
                    {title}
                </h1>
            </Can>

            {!isGeneralPage && !hideHeader &&
                <>
                    {isEmpty(assessment) ?
                        <TestTakerHeader /> :
                        <AssessmentDetails />
                    }
                </>
            }
            {children}
        </>
    )

}

export default WrapAppRoute