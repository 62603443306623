import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import { queryClient as qc } from 'keys'
import { getAdminAssessmentsFetch } from 'store/middlewares/assessment.crud'

//TODO
const useGetAdminAssessments = (props = {}) => {
    const { enabled } = props
    const { testTaker } = useSelector(state => state.testTaker)
    const { statusFilters, assessmentFilters } = useSelector(state => state.assessment)
    const { headerFilters } = useSelector(state => state.header)

    return useQuery(
        {
            refetchOnMount: false,
            enabled:  enabled,
            queryKey: qc.adminAssessments,
            queryFn: ({ signal }) => getAdminAssessmentsFetch({ headerFilters, assessmentFilters, statusFilters, testTaker, signal, })
        })
}

export default { useGetAdminAssessments }