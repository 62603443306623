import React, { useCallback, useContext } from 'react'
import ReactPlayer from 'react-player'
import { DashboardStyles, Video, WrapVideo, WrapHeader, WrapContent, WrapTitle, Title, GeneralDashboardStyles, StudentDashboardStyles } from './Style'
import { constants as c, constants, useDashboardCards } from './hooks'
import { Button } from 'components/Form'
import { ReactComponent as HandIcon } from 'assets/icons/Hand.svg';
import { ReactComponent as HandDisabled } from 'assets/icons/HandDisabled.svg';
import { FlexAround, FlexBetween, FlexCenter, Img, LightButton } from 'styles/GlobalStyle'
import { Go5, Task } from 'assets'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg';
import { CircularProgress, SvgIcon } from '@mui/material'
import './style.css'
import { CardStyles } from './Style'
import { thirdly2 } from 'styles/theme'
import { useSelector } from 'react-redux'
import { DashboardContext } from './DashboardContext'
import { secondary } from 'styles/theme'
import { white } from 'styles/theme'
import { thirdly5 } from 'styles/theme'
import { useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { authHooks } from 'components/Auth'

const DashboardPage = () => {
    const { you,you2 } = constants
    const { dashboardData } = useContext(DashboardContext)
    const { pending_assessments_count, pending_assessments_ids } = dashboardData || {}
    const { user } = useSelector(state => state.user)
    const navigate = useNavigate()
    const logout = authHooks.useLogout()

    const handleFullScreen = () => {
        const player = document.querySelector('video');
        if (player) {
            player.requestFullscreen();
        }
    };

    const goPerformAssessments = useCallback(() => {
        const ssessmentId = pending_assessments_ids[0]
        navigate(`${app.makingRecordAssessments}?assessment=${ssessmentId}`);
    }, [pending_assessments_ids])

    return (
        <StudentDashboardStyles cartoonHeight={240} cartoonWidth={160}>
            <div className='logout'>
                <Button action={logout} width={"120px"} direction="rtl" color="light" label="לָצֵאת" icon={<SvgIcon fontSize="small" component={LogoutIcon} inheritViewBox />} />
            </div>
            <div className='dashboard-content'>
                <div className='dashboard-cards-content'>
                    <CardStyles bg={thirdly2}>
                        <div>
                            {`שָלוֺם ${user?.first_name || ""},`}
                            <div>
                                {`כֵּיף לִרְאוֹת ${you2[user?.gender]}`}
                            </div>
                        </div>
                        <div className='cartoon-img'>
                            <Img src={Go5} />
                        </div>
                    </CardStyles>
                    <div className='left-cards'>
                        <CardStyles height={"60%"}>
                            {pending_assessments_count ?
                                pending_assessments_count > 1 ?
                                    `יֵשׁ ${you[user?.gender]} ${pending_assessments_count}  מְשִׂימוֹת   ` :
                                    `מְחַכָּה ${you[user?.gender]} מְשִׂימָה` :
                                "אֵין מְשִׂימוֹת"
                            }
                            <div className='task-img'>
                                <Img src={Task} />
                            </div>
                        </CardStyles>
                        <CardStyles onClick={goPerformAssessments} button disabled={!pending_assessments_count} alignCenter color={pending_assessments_count ? white : thirdly5} bg={pending_assessments_count ? secondary : "#CAD3DF"} height={"40%"}>
                            <div className='start-a-task'>
                                {"לְהַתְחִיל"}
                                <div className='hand-img'>
                                    <SvgIcon fontSize="large" component={pending_assessments_count ? HandIcon : HandDisabled} inheritViewBox />
                                </div>
                            </div>
                        </CardStyles>
                    </div>
                </div>
            </div>
        </StudentDashboardStyles >
    )
}

export default DashboardPage