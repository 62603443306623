import React from 'react'
import { WrapGraphTexts } from './Style'

const StatsBar = (props) => {
    const { isDrag, showFooter, graphText, text, showAllChildren } = props

    return (
        graphText &&
        <WrapGraphTexts style={{
            position: "relative",
            top: showAllChildren && isDrag && "41px",
        }}>
            {showAllChildren &&
                <div className='wrap-graph-text'>
                    <div className='graph-text'>
                        {text}
                    </div>
                </div>
            }
            <div className='wrap-graph-text'>
                <div className='graph-text'>
                    {graphText}
                </div>
            </div>
            {showFooter && <div className='wrap-graph-text' />}
        </WrapGraphTexts>
    )
}

export default StatsBar