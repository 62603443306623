import React from 'react'
import { SvgIcon, Tooltip } from '@mui/material'
import { CustomIconButton } from './Styled'
import { gTheme } from 'styles/theme'
import { thirdly5 } from 'styles/theme'

const IconButtonGroup = (props) => {
    const { placement = "left", disabled, tooltip = "", fontSize, disabledColor = "thirdly5", color = "thirdly2", action, selected, label, icon, iconSize = {}, btnKey = "btn" } = props
    const { height, width } = iconSize || {}

    return (
        <Tooltip title={!disabled && tooltip} placement={placement}>
            <span>
                <CustomIconButton
                    label={label}
                    onClick={action}
                    disabled={disabled}
                    color={color}
                    variant={selected ? "contained" : "text"}>
                    {label && label}
                    {icon && <SvgIcon sx={{ height, width }} fontSize={fontSize} component={icon} inheritViewBox />}
                </CustomIconButton>
            </span>
        </Tooltip>
    )
}

export default IconButtonGroup