import React, { useEffect, useState, useRef, useContext } from 'react';
import { WrapProgressNum, WrapBackdrop } from './Style'
import { Comment } from 'components/Form';
import { AbilityContext } from 'components/Permissions/Can';

const Progress = (props) => {
    const { setClose } = props
    let nProegrss = 3
    const [progress, setProgress] = useState(nProegrss);
    const progressRef = useRef(nProegrss)
    const ability = useContext(AbilityContext)

    const handleClose = () => {
        setProgress(nProegrss)
        progressRef.current = nProegrss
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (progressRef.current == 0) {
                clearInterval(timer)
            }
            else {
                let nProgress = progressRef.current - 1
                setProgress(nProgress);
                progressRef.current = nProgress
            }
        }, 1000);

        return () => {
            clearInterval(timer)
            setClose(true)
            handleClose()
        };
    }, []);

    const comment = {
        title: "חכו עד סיום הספירה לאחור ואז מתחילים לקרוא"
    }

    return (
        <WrapBackdrop
            showSidebar={ability.can("read", "sidebar")}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Comment {...comment} open={true}>
                <WrapProgressNum>
                    {progress}
                </WrapProgressNum>
            </Comment>
        </WrapBackdrop>
    );
}

export default Progress