import React, { useContext } from 'react'
import { Button, RenderFields } from 'components/Form'
import { WrapStepFields, GridStep1, StepTitle, WrapFields } from './Style'
import { step1 } from './hooks'
import { RegisterContext } from './RegisterContext'
import { customUserRQ } from 'store/hooks'
import { useSelector } from 'react-redux'
import { formname } from './hooks/constants'

const Step1Fields = (props) => {
    const { handleNext, form } = props

    const formValues = useSelector(state => state.form[formname]?.values)
    
    const { step, setStep } = useContext(RegisterContext)

    const { mutateAsync: isVerifyEmail, isPending: isVerifyEmailLoading } = customUserRQ.useIsVerifyEmail()
    const { mutateAsync: validateEmail, isPending: validateEmailLoading } = customUserRQ.useValidateEmail()


    const fields = step1.useFields()
    const continueBtn = step1.useNext()
    const verificationEmailBtn = step1.useVerificationEmailBtn()

    const { title } = step1.constants

    const handleNextStep = async () => {
        const email = formValues?.email
        let isFormValid = handleNext(form)


        if (isFormValid) {
            isFormValid = await validateEmail(email)
            // isFormValid = await isVerifyEmail({ email: email })
        }

        if (isFormValid || isFormValid === undefined)
            setStep(step + 1)
    }

    const handleVerificationEmail = () => {
        const isFormValid = handleNext(form)

        if (isFormValid)
            verificationEmailBtn.action()
    }

    return (
        <WrapStepFields>
            <StepTitle>{title}</StepTitle>
            <GridStep1>
                <RenderFields fields={fields} />
                {/* <Button
                    {...verificationEmailBtn}
                    action={handleVerificationEmail}
                /> */}
                <Button
                    {...continueBtn}
                    action={handleNextStep}
                    loading={validateEmailLoading || isVerifyEmailLoading}
                />
            </GridStep1>
        </WrapStepFields>
    )
}

export default Step1Fields