import { styled as muiStyled } from '@mui/material/styles';
import { dark } from "styles/theme";
import { Tabs } from "@mui/material";


const TabStyles = muiStyled(Tabs)(({ theme }) => (
    {
        '& .Mui-selected': {
            color: `${dark} !important`,
            fontWeight: "bold"
        }
    }))


export { TabStyles }