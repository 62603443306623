import styled from 'styled-components'
import { LoadingButton } from '@mui/lab';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { alpha, styled as muiStyled } from '@mui/material/styles';
import { FormControl, TextField, Input, Button, InputBase, NativeSelect, Select, OutlinedInput, ButtonGroup, Fab, Autocomplete, ListItem, List, Paper, Tooltip, tooltipClasses, Box } from '@mui/material'
import { dark, ligthGray, error, gTheme, gray, light, lightBlue, lightBlue2, mandatory, primary, rating1, rating2, rating4, secondary, white, text, thirdly3 } from 'styles/theme';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Center, Disabled, Shadow } from 'styles/GlobalCss'
import { IconButton } from '@mui/material'
import { blink, FlexAround } from 'styles/GlobalStyle';
import { borderRadius, display, fontSize, fontWeight, textAlign } from '@mui/system';
import { thirdly } from 'styles/theme';
import { thirdly2 } from 'styles/theme';

const CommentTitle = muiStyled(Box)({
    '.comment-title': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: dark,
        fontWeight: 700,
        fontSize: 15,
        textAlign: "center"
    },
    '.comment-icon': {
        display: "flex",
        justifyContent: "center"
    }
})


const WrapComment = muiStyled(({ className, bottom, right, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ bottom, right }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        width: 130,
        height: 130,
        // padding: 10,
        position: "relative",
        bottom: bottom && bottom,
        right: right && right,
        borderRadius: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: white,
        boxShadow: "0px 0px 30px 4px #91BDFA40"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: white,
        marginRight: "-0.60em !important",
        marginLeft: "-0.60em !important"
    },
    [`& .${tooltipClasses.popper}`]: {
        transform: "translate3d(-826.4px, 587.6px, 0px) !important"
    }
}));

const WrapTooltip = styled.div`
background:${white};
width:12px;
padding:3px;
border-radius:30px;
${Center};
`

const Chip = styled.span`
background:${props => props.bg ? props.bg : "transparent"};
border:${props => props.borderColor && `1px solid ${props.borderColor}`};
border-radius:7px;
color:${dark};
padding:2px 6px;
font-size:16px;
height: 20px;
${Center};
`


const CustomIconButton = styled(Button)`
min-width: 30px !important;
border-radius:5px !important;
height:30px !important;
width:${props => props.label ? "auto" : "30px"} !important;
color:${props => props.label && "black !important"};

.MuiSvgIcon-root{
fill:transparent !important;
}
    
`

const WrapGender = styled(FlexAround)`
column-gap: 7px;
`

const SubTitle = styled.div`
color: rgba(0, 0, 0, 0.50);
font-size: 17px;
`

const SelectedSideBarItem = styled.div`
width: 5px;
height:100%;
flex-shrink: 0;
background:${props => props.$selected && !props.$hideBorder ? secondary : 'transparent'};
border-radius: 3.5px;
`

const AudioWrapper = styled.div`
   /* Optional: Style the container holding the audio player */
      max-width: 400px;
    //   margin: auto;
      width: ${props => props.width || '100%'};
      height: ${props => props.height || '100%'};
      background-color: #f0f0f0;
      border-radius: 8px;

    audio::-webkit-media-controls-volume-slider {
      display: none;
    }
    audio::-webkit-media-controls-mute-button {
      display: none;
    }
    audio::-webkit-media-controls-volume-control-container {
      display: none;
    }
`

const WrapActions = styled.div`
display:flex;
width:100%;
justify-content: space-between;
align-items: center;

`

const Actions = styled.div`
width: 65px;
`

const WrapMoreOptions = styled.div`
display:flex;
justify-content:center;
`

const AudioController = styled.div`
   /* Optional: Style the audio controls */
      display: flex;
      align-items: center;
      justify-content: center;
          width: 100%;
          height:100%;


          audio::-webkit-media-controls-mute-button {
    display: none !important;
    width:0px !important;
}

audio::-webkit-media-controls-volume-slider {
    display: none !important;
    width:0px !important;
}

 
`

const WrapHeaderUpload = styled.div`
display:flex;
justify-content: space-between !important;
align-items:center;
`

const WrapIconButton = styled(IconButton)`
width:${props => props.width}px !important;
height:${props => props.height}px !important;
background:${props => props.themeColor?.main} !important;
background:${props => props.$bg && 'white'} !important;

grid-area:${props => props.gridArea && props.gridArea} !important;
${props => props.$disabled && Disabled};
border:${props => props.$border && `1px solid ${props.themeColor?.main}`} !important;
${Center};

.MuiSvgIcon-root{
fill:${props => props.$hideFill && 'transparent !important;'}
}
`

const BpIcon = muiStyled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 20,
    height: 20,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = muiStyled(BpIcon)({
    backgroundColor: rating1,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

const WrapFab = muiStyled(Fab)`
width:${props => props.size}px !important;
height:${props => props.size}px !important;
font-weight: bold;
font-size:20px !important;
:hover{
      background-color: ${props => gTheme[props.color].main} !important;
      opacity:0.5 !important;
}
`

const WrapAudio = styled.audio`
width:100%;
height:100%
`


const WrapAudioContent = styled.div`
height:100% !important;

`


const MuiInput = styled(Input)`
width:100%
`

const WrapUploadFileButton = styled(Button)`
border: ${props => `1px dashed ${gray} !important`};
padding: 10px;
border-radius: 4px;
text-align: center;
background:${light};
color:${gray} !important;
height:100% !important;
width:100% !important;
${Shadow};
`

const WrapOutlinedInput = muiStyled(InputBase)(({ theme }) => (
    {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    }
))

const Mandatory = styled.span`
color:${mandatory};
`


const WrapButton1 = muiStyled(LoadingButton)(({ align, direction, width, height, fontsize, theme, $fullWidth, $systemUI }) => (
    {
        gap: "0px",
        width: !$fullWidth && 'auto !important',
        width: width && width,
        width: $fullWidth && '100%',
        borderRadius: $systemUI ? '35px' : "4px",
        display: "flex",
        justifyContent: "space-around",
        fontSize: $systemUI && '18px',
        fontSize: fontsize || 16,
        height: height || 43,
        direction: direction,
        justifyContent: align,


        // ${props => props.disabled && `

        // opacity:0.3;
        // background-color:${props.color?.main} !important;
        // color:${props.color?.contrastText} !important;
        // // background-color:${props.disabledcolor?.main} !important;
        // // color:${props.disabledcolor?.contrastText} !important;
        // `}

        // ${props => props.variant === 'outlined' && `
        // color:black !important;  
        // background-color:${props.disabledcolor?.contrastText} !important;
        // border:1px solid ${props.disabledcolor?.main} !important;

        // `}
        //     }
    }
))

const WrapButton = muiStyled(LoadingButton)`
gap: 0px;
width:${props => !props.$fullWidth && 'auto !important'};
width:${props => props.$fullWidth && '100% !important'};
border-radius: ${props => props.$systemUI ? '35px' : "4px"} !important;
display: flex !important;
justify-content: space-around !important;
column-gap:5px !important;

${props => props.gridArea && `
grid-area:${props.gridArea} !important;    
`}

font-size:${props => props.fontsize ? props.fontsize : '16px'} !important;

height:${props => props.height || "45px"} !important;

width:${props => props.width} !important;

${props => props.size === 'small' &&
        `
    padding: 1px 10px !important;
    height:  auto !important;
    font-size:15px;
    `
    }

direction:${props => props.direction} !important;

${props => props.align && `
justify-content: ${props.align} !important;
`}

${props => props.disabled && `
opacity:0.4;
background-color:${props.disabledcolor?.main} !important;
color:${props.disabledcolor?.contrastText} !important;
`}

${props => props.variant === 'outlined' && `
color:black !important;  
background-color:${props.disabledcolor?.contrastText} !important;
border:1px solid ${props.disabledcolor?.main} !important;

`}

${props => props.$blink && `
background-position: right center;
background-size: 200% auto;
-webkit-animation: pulse 2s infinite;
animation: pulse512 1.5s infinite;

@keyframes pulse512 {
 0% {
  box-shadow: 0 0 0 0 #e89f61;
 }

 70% {
  box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
 }

 100% {
  box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
 }
`}

.MuiSvgIcon-root{
fill:transparent !important;
}

`

const WrapBlinkButton = muiStyled(WrapButton)({
    animation: `${blink} 2s linear infinite`
})


const WrapButtonGroup = styled(ButtonGroup)`
border-color:red !important;
${props => props.disabled && `
opacity:0.3;
background-color:${props.disabledcolor?.main} !important;
color:${props.disabledcolor?.contrastText} !important;
`}
`

const Title = styled.div`
font-size: 23px;
line-height: 39px;
letter-spacing: -0.02em;
text-align: right;
`

const MuiFormControl = styled(FormControl)`
margin:0 !important;
`

const Wrap = styled.div`
display: flex;
flex-direction: column;
row-gap: ${props => props.rowGap || "20"}px;
justify-content: center;
flex-grow: 1;
${props => props.csswrap};
height:100%;

`

const Error = styled.div`
font-size:15px;
display: flex;
align-items: center;
column-gap: 10px;
margin-top: 5px;
color:${mandatory}
`

const IconError = styled(ErrorOutlineIcon)`
width: 15px !important;
height: 15px !important;
`

const VisuallyHiddenInput = muiStyled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const WrapField = styled.div`
--padding: 15px; 

// width: 100%;
display: flex;
flex-direction: column;

grid-area:${props => props.gridArea};

${props => props.section && `
background:${white};
padding:var(--padding);
border-radius: 4px;
width: calc(100% - var(--padding)*2);
${Shadow};
`
    }
`

const WrapFieldContent = styled.div`
display: flex;
flex-direction: column;
row-gap: 10px;
height:100%;
`

const Label = styled.div`
// color:${dark};
line-height: 21px;
text-align: right;
`

const Placeholder = styled.div`
color: #b2b9bf;

`

const AutoCompleteOptions = muiStyled(Paper)(({ theme, $systemUI }) => (
    {
        backgroundColor: white,
        borderRadius: '2px'
    }))

const WrapAutocomplete = muiStyled(Autocomplete)(({ border, theme, minWidth, $systemUI }) => (
    {
        '& .MuiInputBase-root.MuiFilledInput-root.Mui-focused': {
            background: "white"
        },
        '& .MuiInputBase-root.MuiFilledInput-root.Mui-hover': {
            background: "white"
        },
        '& .MuiFormControl-root.MuiTextField-root': {
            minWidth: minWidth || '200px',
        },
        '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            minWidth: '80px'
        },
        // '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
        //     minWidth: minWidth || '200px',
        // },
        ".MuiAutocomplete-popper": {
            background: white,
        },
        '& .MuiAutocomplete-inputRoot': {
            // height: "50px",
            // overflow: "auto",
            // padding: "2px",
            width: "100%",
            borderRadius: 35,
            border: `1px solid ${thirdly3}`,
            position: 'relative',
            background: white,
            fontSize: 16,
        },
        '& .Mui-focused': {
            background: "white"
        },
        ':hover': {
            borderRadius: 35,
            background: "white"
        }
    }))

const BootstrapInput = muiStyled(TextField)(({ theme }) => (
    {
        border: "none",
        '.MuiFormControl-root .MuiTextField-root .MuiInputBase-input': {
            borderRadius: "35px",
        },
        width: "100%",
        // borderRadius: "35px",
        // transition: theme.transitions.create([
        //     'border-color',
        //     'background-color',
        //     'box-shadow',
        // ]),
        // '&:focus': {
        //     boxShadow: `${alpha(theme.palette.thirdly.main, 0.25)} 0 0 0 0.2rem`,
        //     borderColor: theme.palette.thirdly.main,
        // },
        // '&:hover': {
        //     boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        //     borderColor: theme.palette.primary.main,
        // },

        // 'label + &': {
        //     marginTop: theme.spacing(3),
        // },
        // '&:-webkit-autofill': {
        //     WebkitBoxShadow: '0 0 0 100px #307ECC inset',
        //     WebkitTextFillColor: 'ffffff',
        // },
        '& .MuiInputBase-input': {
            width: "100%",
            // borderRadius: 4,
            // borderRadius: 35,
            position: 'relative',
            // background: "#F6F6F6",
            fontSize: 16,
            // padding: '10px 12px',
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                "Calibri", "sans-serif"
            ].join(','),

        },
    }))

const BootstrapInput2 = muiStyled(BootstrapInput)(({ theme, border }) => (
    {
        '& .MuiInputBase-input.MuiFilledInput-input:-webkit-autofill': {
            borderRadius: 35,
        },
        '& .MuiInputBase-root.MuiFilledInput-root': {
            background: "white",
            borderRadius: 35,
            border: `1px solid ${thirdly3}`
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
            paddingRight: 0
        },
        // '.MuiFormControl-root .MuiTextField-root': {
        //     borderRadius: "35px",
        //     border: border && `1px solid ${thirdly3}`,
        // },
        // '& .MuiInputBase-input': {
        //     width: "100%",
        //     borderRadius: "35px",
        //     background: white,
        // },
        '& MuiSvgIcon-root': {
            fill: "transparent !important"
        },
        '.MuiInputBase-root': {

        }
    }))

const BootstrapSelect = muiStyled(Select)(({ theme }) => (
    {
        // 'label + &': {
        //     marginTop: theme.spacing(3),
        // },
        'fieldset': {
            borderColor: 'transparent'
        },
        '& .MuiInputBase-input': {
            width: "100%",
            borderRadius: 4,
            position: 'relative',
            background: "#F6F6F6",
            border: '1px solid',
            borderColor: 'transparent',
            fontSize: 16,
            padding: '10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,

            },
        },
        "&.MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "transparent"
            }
        },
    }))

const BootstrapDatePicker = muiStyled(DatePicker)(({ theme, $systemUI }) => (
    {

        '& .MuiFormControl-root.MuiTextField-root': {
            borderRadius: 35,
            position: "relative",
            bottom: 7
        },
        '& .MuiInputBase-root.MuiFilledInput-root': {
            background: "white",
            borderRadius: 35,
            border: `1px solid ${thirdly3}`
        },
        // borderRadius: $systemUI ? 35 : 4,
        background: $systemUI ? white : '#F6F6F6',
        'fieldset': {
            borderColor: 'transparent',
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            border: '1px solid #909090 !important',
        },
        '& .MuiInputBase-input': {
            position: "relative",
            bottom: 7,
            width: "100%",
            position: 'relative',
            border: '1px solid',
            borderColor: 'transparent',
            fontSize: 16,
            direction: 'rtl',
            textAlign: "end",
            // padding: '10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            fontFamily: [
                "Calibri", "sans-serif"
            ].join(',')
        }
    }))


const WrapLabel = styled.div`
font-size:${props => props.fontSize || 14}px;
font-weight:${props => props.bold && 'bold'};
`

const WrapProgress = styled.div`
display: flex;
justify-content: center;
padding: 10px;
    `

const WrapFooterBtns = styled.div`
display: flex;
column-gap: 10px;
    `

const Form = styled.form`
display: flex;
height: 100%;
row-gap: 20px;
flex-direction: column;
    `

const WrapSideBarItem = styled(Button)`
display:flex;
column-gap: 10px;
display: flex !important;
justify-content: flex-start !important;
padding: 0px !important;
height:40px  !important;
width:100% !important;
box-sizing: border-box !important;
font-weight: ${props => props.$selected ? "bold" : 300} !important;

${props => props.disabled && `
color:${gray} !important;
opacity:0.5 !important;
`}

${props => props.$subItem && `
    color:${props.$selected ? secondary : white} !important;  
`}

.sub-item-icon{
width:30px;
${Center}
}
`

const WrapListItem = muiStyled(ListItem)(({ theme }) => ({
    width: '100%',
    borderRadius: "35px",
    border: "1px solid #CADDEA",
    height: 50,
    color: "#00000080"
}));

const Card = styled.div`
border-radius: 25px;
border: 1px #CADDEA;
width:${props => `${props.width}px` || '100%'};
height:${props => `${props.height}px` || '100%'};
background: #FFF;
`

export {
    Card, WrapListItem, WrapComment, AutoCompleteOptions, CommentTitle, Actions, CustomIconButton, Chip, WrapMoreOptions, SubTitle, BootstrapInput2, WrapSideBarItem, AudioWrapper, WrapAutocomplete, AudioController, BpIcon, WrapIconButton, BpCheckedIcon, WrapFab, WrapButtonGroup, WrapAudioContent, WrapAudio, WrapUploadFileButton, VisuallyHiddenInput, WrapOutlinedInput, BootstrapDatePicker,
    Form, WrapFieldContent, WrapGender, WrapBlinkButton, WrapTooltip, SelectedSideBarItem, WrapActions, WrapFooterBtns, WrapProgress, WrapLabel, Mandatory, Placeholder, BootstrapSelect, BootstrapInput, WrapButton, Title, WrapHeaderUpload, Label, MuiFormControl, MuiInput, Wrap, Error, IconError, WrapField
}
