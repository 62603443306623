import axios from 'config/axiosConfig'
import { assessmentUtils } from 'helpers';
import { statuses } from 'keys';
import api, { assessment, go, reading_assessment, assessments, score_rating_methods } from 'keys/apis';
import { keyBy } from 'lodash';
import { getAssessmentFilters, getScoreRatingFilters } from 'utils/filtering';

let CancelToken = axios.CancelToken;

export const getRating = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_RATING') {
        let { id } = getState().assessment.assessment
        if (!id) id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_rating/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getRatingFetch = ({ id }) => {
    if (id)
        return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_rating/`)
}

export const getFluencyStatsByAssessmentFetch = ({ id, params }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_fluency_stats_by_assessment/`, {
        params: params
    })
}


export const getFluencyDistributionFetch = ({ id, params }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_fluency_distribution/`, {
        params: params
    })
}

export const saveScoreRatingMethodsFetch = (data) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${go}${score_rating_methods}save_score_rating_methods/`, {
        score_rating: data
    })
}

export const getAccuracyDistributionFetch = ({ id, params }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_percent_accuracy_distribution/`, {
        params: params
    })
}

export const getFactoredAccuracyDistributionFetch = ({ id, params }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_factored_percent_accuracy_distribution/`, {
        params: params
    })
}

export const getFluencyStatsFetch = ({ headerFilters, assessmentFilters, statusFilters, testTaker }) => {
    let params = getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params.status = statuses.inProgress
    params.sub_status = statuses.wordsComplete
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_fluency_stats/`, {
        params: params
    })
}

export const getHesitationsStatsFetch = ({ headerFilters, assessmentFilters, statusFilters, testTaker }) => {
    let params = getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params.status = statuses.inProgress
    params.sub_status = statuses.wordsComplete
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_hesitations_stats/`, {
        params: params
    })
}

export const getAccuracyStatsByAssessmentFetch = ({ id, params }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_accuracy_stats_by_assessment/`, {
        params: params
    })
}

export const getAccuracyStatsFetch = ({ headerFilters, assessmentFilters, statusFilters, testTaker }) => {
    let params = getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params.status = statuses.inProgress
    params.sub_status = statuses.wordsComplete
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_accuracy_stats/`, {
        params: params
    })
}

export const getFactoredAccuracyStatsByAssessmentFetch = ({ id, params }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_factored_accuracy_stats_by_assessment/`, {
        params: params
    })
}

export const getFactoredAccuracyStatsFetch = ({ headerFilters, assessmentFilters, statusFilters, testTaker }) => {
    let params = getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params.status = statuses.inProgress
    params.sub_status = statuses.wordsComplete
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_factored_accuracy_stats/`, {
        params: params
    })
}

export const getMatchingLearningFetch = ({ rating }) => {
    const { percent_accuracy_rating_index, fluency_rating_index, fluency_rating_id, percent_accuracy_rating_id } = rating
    const maxIndicationRatingId = percent_accuracy_rating_index > fluency_rating_index ? percent_accuracy_rating_id : fluency_rating_id

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}score_rating_methods/${maxIndicationRatingId}/${api.get_matching_learning}/`)
}

export const getMatchingLearning = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_MATCHING_LEARNING') {
        let { rating } = getState().rating

        const { percent_accuracy_rating_index, fluency_rating_index, fluency_rating_id, percent_accuracy_rating_id } = rating
        const maxIndicationRatingId = percent_accuracy_rating_index > fluency_rating_index ? percent_accuracy_rating_id : fluency_rating_id

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}score_rating_methods/${maxIndicationRatingId}/${api.get_matching_learning}/`, {
                // cancelToken: new CancelToken(function executor(c) {
                //     // An executor function receives a cancel function as a parameter
                //     dispatch(actions.updateController({ [api.get_matching_learning]: c }))
                // })
            }
            )
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getScoreRatingMethod = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_SCORE_RATING_METHOD') {
        let { id } = getState().assessment.assessment
        if (!id) id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_score_rating_method/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getScoreRatingMethodFetch = ({ dispatch, id }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_score_rating_method/`)
}

export const getScoreRatingMethods = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_SCORE_RATING_METHODS') {
        const { testTaker } = getState().testTaker
        const { semester, assessment_def, t_class } = testTaker || {}

        let params = {}
        if (semester)
            params.semester = semester
        if (assessment_def)
            params.assessment_def = assessment_def
        if (t_class)
            params.t_class = t_class

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}score_rating_methods/`, {
                params: params
            })
                .then(response => {
                    const { data } = response
                    resolve(keyBy(data, "id"))
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}


export const getScoreRatingSemestersFetch = ({ signal, assessmentFilters }) => {
    let params = getScoreRatingFilters({ assessmentFilters })
    params.method = 2

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}${score_rating_methods}get_score_rating_semesters/`, {
        signal,
        params: params
    })
}






