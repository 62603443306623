import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { queryClient as qc } from 'keys'
import { deleteWordFetch, getNestedWordsFetch, getWordDefsFetch, getWordsFetch, saveWordFetch } from "store/middlewares/words.crud"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import { MarkingWordsAssessmentVM, NestedWordVM, WordVM } from "models/VM"
import actions from "store/actions"
import { isEmpty } from "lodash"

const useGetNestedWords = (props) => {
    const { assessment } = useSelector(state => state.assessment)
    const params = useParams()
    let assessmentID = assessment?.id
    if (!assessmentID)
        assessmentID = params.assessmentID

    return useQuery(
        {
            queryKey: qc.nestedWords,
            queryFn: async ({ signal }) => {
                const { data } = await getNestedWordsFetch({ id: assessmentID, signal, })
                const words = []
                data.words.forEach(word => words.push(new NestedWordVM(word)))
                props?.onSuccess?.(words)
            },
            refetchOnWindowFocus: false,
        })
}

const useSaveWord = () => {
    const { assessment } = useSelector(state => state.assessment)
    const { id: assessmentID } = assessment
    const { refetch: refetchNestedWordsRQ } = useGetNestedWords({ enabled: false })

    return useMutation({
        mutationFn: (word) => {
            return saveWordFetch({ word, assessmentID })
        },
        onSuccess: () => {
            refetchNestedWordsRQ()
        }
    })
}

const useDeleteWord = () => {
    const { assessment } = useSelector(state => state.assessment)
    const { id: assessmentID } = assessment
    const { refetch: refetchNestedWordsRQ } = useGetNestedWords({ enabled: false })

    return useMutation({
        mutationFn: (word) => {
            return deleteWordFetch({ wordID: word.id, assessmentID })
        },
        onSuccess: () => {
            return refetchNestedWordsRQ()
        }
    })
}

const useSaveAssessmentAndWordsStore = () => {
    const dispatch = useDispatch()

    return (wordsRes) => {
        let { data } = wordsRes || {}
        const mWords = []
        data.forEach((word, index) => mWords.push(new WordVM({ assessment: data, ...word }, index)))

        dispatch(actions.setWords(mWords))
    }
}

const useGetWords = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const params = useParams()
    let assessmentID = assessment?.id
    if (!assessmentID)
        assessmentID = params.assessmentID

    return useQuery({
        queryFn: async ({ signal }) => {
            let assessmentData = await getWordsFetch({ signal, id: assessmentID })
            let { data } = assessmentData || {}
            const mWords = []
            data.words.forEach((word, index) => mWords.push(new WordVM({ assessment: data, ...word }, index)))

            dispatch(actions.setWords(mWords))
            let nAssessment = new MarkingWordsAssessmentVM(data, data.words)
            dispatch(actions.updateAssessment(nAssessment))
        },
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        retry: 0,
        queryKey: qc.assessmentAndWords
    })
}

const useGetWordDefs = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const { words } = useSelector(state => state.words)
    const params = useParams()
    const [searchParams] = useSearchParams();
    const assessmentDef = searchParams.get('assessmentDef');

    const assessmentDefID = params.assessmentDefID
    const assessmentID =
        assessmentDefID ||
        assessmentDef ||
        assessment?.reading_assessment_def_id

    return useQuery({
        queryFn: [qc.wordDefs, assessmentID],
        enabled: isEmpty(words) && !!assessmentID,
        queryFn: async ({ signal }) => {
            const { data } = await getWordDefsFetch({ signal, id: assessmentID })
            dispatch(actions.setWordDefs(data))
        },
        refetchOnWindowFocus: false
    })
}

export default { useGetWordDefs, useSaveAssessmentAndWordsStore, useGetWords, useGetNestedWords, useSaveWord, useDeleteWord }
