import { useCallback, useMemo } from "react";
import { tableHooks } from "components/Table";
import { AssessmentStatusCell, performAssessmentsHooks } from "..";
import { timeAgoWithOutSeconds } from "utils/formatter";
import { IconButtonGroup } from "components/Form";
import { alerts, queryClient as qc, statuses } from 'keys'
import { ReactComponent as UploadFileIcon } from 'assets/icons/UploadFile.svg';
import { ReactComponent as GoIcon } from 'assets/icons/Go.svg';
import { ReactComponent as GoDisabledIcon } from 'assets/icons/GoDisabled.svg';
import { actions } from "store";
import { useDispatch } from "react-redux";

const useColumns = () => {
    const idCell = tableHooks.useIdCell()
    const testTakerNameCell = tableHooks.useTestTakerNameCell()
    const gradeOfAllocationCell = tableHooks.useGradeOfAllocationCell()
    const playCell = tableHooks.usePlayCell()
    const dispatch = useDispatch()

    const performAssessment = performAssessmentsHooks.usePerform()

    const openUploadAudioPopup = useCallback((id) => {
        dispatch(actions.setAlerts(
            {
                key: alerts.uploadAudio,
                value: { open: true, props: id, }
            }))
    }, [])

    const columns = useMemo(
        () => [
            idCell,
            testTakerNameCell,
            // {
            //     accessorKey: 'assessment_count',
            //     header: "מספר שלבים",
            //     enableSorting: false,
            // },
            {
                accessorKey: 'reading_assessment_category_name',
                header: "סוג אבחון",
            },
            {
                accessorKey: 'status',
                header: "סטטוס",
                cell: AssessmentStatusCell,
                enableSorting: false,
            },
            gradeOfAllocationCell,
            {
                accessorKey: 'file_uploaded_date',
                header: "מועד ביצוע",
                cell: ({ row }) =>
                    timeAgoWithOutSeconds(
                        row.original.assessment_group.reduce((latestDate, assessment) => {
                            const assessmentDate = new Date(assessment.file_uploaded_date);
                            return assessmentDate > latestDate ? assessmentDate : latestDate;
                        }, null))
                    || ""
            },
            {
                accessorKey: 'record',
                header: "ביצוע",
                enableSorting: false,
                cell: ({ row }) => {
                    const isBrokenFile = row.status === statuses.brokenFile
                    // const disabledRow = !isBrokenFile && row.audio_file_url

                    const { assessment_group } = row.original
                    const disabledRow = !(assessment_group.find((ass) => !ass.audio_file_url || ass.status === statuses.brokenFile))

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="לביצוע"
                            selected={!disabledRow}
                            icon={disabledRow ? GoDisabledIcon : GoIcon}
                            action={() => {
                                performAssessment(row.original)
                            }}
                            disabled={disabledRow}
                        />
                    );
                }
            },
            {
                accessorKey: 'upload',
                header: "העלאת אודיו",
                enableSorting: false,
                feature: "upload-audio",
                role: "create",
                cell: ({ row }) => {
                    const { assessment_group } = row.original

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="להעלאת אודיו"
                            selected={true}
                            icon={UploadFileIcon}
                            action={() => {
                                openUploadAudioPopup(row.original.id)
                            }}
                        />
                    );
                }
            },
            playCell
        ], []
    )

    return columns
}

export default useColumns