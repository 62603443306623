import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { ChildrenGraphStyles, WrapGraph, WrapIcon, WrapNumChild, WrapGraphTexts, GraphNumber, WrapGraphItem, WrapChild, LineStyles } from './Style'
import { firstWord } from 'utils/obj'
import DraggableItem from './DraggableItem'
import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { Progress } from 'components/Record'
import { FlexCenter } from 'styles/GlobalStyle'
import { cloneDeep, forEach, isEmpty, isEqual, last, slice, uniqBy } from 'lodash'
import ratingList, { lightRating, ratingText } from 'keys/rating'

import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { rating5 } from 'styles/theme'
import { rating1 } from 'styles/theme'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
    defaultDropAnimation,
} from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Draggable from './Draggable'
import { SvgIcon } from '@mui/material'

const StatsBar = (props) => {
    const {
        value,
        current_test_taker,
        count, percent,
        index, theme, graphKey,
        isDrag, viewPercent, fixed, showAllChildren,
        isPercent } = props
    const { testTaker = {} } = useSelector(state => state.testTaker)

    return (
        <ThemeProvider theme={theme} key={`${graphKey}-${index}`}>
            <WrapGraphItem isDrag={isDrag} height={showAllChildren ? 80 : 35} current_test_taker={current_test_taker}>
                {(showAllChildren || current_test_taker) &&
                    <React.Fragment>
                        <WrapNumChild>
                            {
                                // !hide &&
                                viewPercent ? (percent ? `${percent?.toFixed(1)}%` : "") :
                                    current_test_taker ?
                                        firstWord(testTaker.test_taker_name) :
                                        count > 1 && showAllChildren && (`+${count}`)}
                        </WrapNumChild>
                        <WrapChild>
                            {
                                // !hide &&
                                ((showAllChildren || current_test_taker) && count >= 1 &&
                                    <WrapIcon fontSize="small" component={ChildIcon} inheritViewBox />)}
                        </WrapChild>
                    </React.Fragment>
                }
                <GraphNumber>
                    {
                        !theme.isSourceItem &&
                        // !hide &&
                        <React.Fragment>
                            {fixed ? Number(value)?.toFixed(fixed) : value}
                            {isPercent ? "%" : ""}
                        </React.Fragment>
                    }

                </GraphNumber>


            </WrapGraphItem>
        </ThemeProvider>
    )
}

export default StatsBar