import React, { useState } from 'react'
import LinguisticLab from './LinguisticLab'

const LabContext = () => {
    const [selectedWordDef, setSelectedWordDef] = useState(null)
    const [selectedTab, setSelectedTab] = useState(0)
    const [openWordDrawer, setOpenWordDrawer] = useState(false)
    const [assessmentFilters, setAssessmentFilters] = useState({
        year: null,
        semester: null,
        assessment_def: null,
        gender: null,
        category: null,
        grade_of_allocation: null
    })

    const commonState = {
        selectedWordDef, setSelectedWordDef,
        selectedTab, setSelectedTab,
        openWordDrawer, setOpenWordDrawer,
        assessmentFilters, setAssessmentFilters
    }

    return (
        <LinguisticLabContext.Provider value={commonState}>
            <LinguisticLab />
        </LinguisticLabContext.Provider>
    )


}

export const LinguisticLabContext = React.createContext()
export default LabContext