
import React from 'react'
import { Divider } from '@mui/material'
import { LevelListStyles, RatingDot, RatingLabel } from './Style'
import ratingList, { ratingListLabel } from 'keys/rating'
import { dark } from 'styles/theme'

const RatingList = ({title}) => {

    return (
        <LevelListStyles>
            <div className='list-title'>
                <RatingDot color={dark} />
                <div>{title}</div>
            </div>
            <Divider />
            {ratingListLabel.map((rating, index) =>
                <div className='rating-item'>
                    <RatingDot color={ratingList[index]} />
                    <RatingLabel color={ratingList[index]}>{rating}</RatingLabel>
                </div>
            )}
        </LevelListStyles>
    )
}

export default RatingList

