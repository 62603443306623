import { AssessmentsAndTestTakersCrud, AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks';
import { StatusCell, RateAndAccuracy, RateAndAccuracyValue, RateAndAccuracyLabel } from 'components/School/AssessmentsAndTestTakers';
import { percent, timeAgoWithOutSeconds } from 'utils/formatter';
import { ArrowBack, ChangeCircle } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { queryClient as qc } from 'keys';
import { filter, get, map, pick } from 'lodash';
import { Button, IconButtonGroup } from 'components/Form';
import { app } from 'components/Router/routes';
import { reading_assessment } from 'keys/apis';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { assessmentHooks } from 'components/School/Assessment';
import { statuses } from 'keys';
import { ReactComponent as VisibilityDisabledIcon } from 'assets/icons/VisibilityDisabled.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import { assessmentsHooks } from '..';
import { testTakerHooks } from 'components/School/TestTakers';

//TODO
const categories = {
    LETTER_VOWEL_CLUSTERS: "צירופים",
    LETTER_NAMING: "שיום אותיות",
    NONSENSE_WORDS: "מילות תפל",
    REAL_WORDS: "מילים בעלות משמעות",
    BIBLICAL_TEXT: "טקסט מקראי",
    INFORMATIONAL_TEXT: "טקסט מידעי",
    NARRATIVE_TEXT: "טקסט סיפורי"
}

const useFields = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { playField } = AssessmentsAndTestTakersData()
    const { sortByRateAndAccuracy, sortNumbers, updateColumnItems } = AssessmentsAndTestTakersCrud()
    const { columnItems } = useSelector(state => state.general)

    const idField = assessmentsHooks.useIdField()
    const nameField = testTakerHooks.useNameField()
    const gradeOfAllocation = assessmentsHooks.useGradeOfAllocation()

    const goAssessment = async (item) => {
        const { id } = item
        const assessments = queryClient.getQueryData(qc.report)?.data
        const assessmentIds = map(filter(assessments, { status: statuses.inProgress, sub_status: statuses.wordsComplete }), 'id');
        dispatch(actions.setAssessmentIds(assessmentIds))
        navigate(`${app.assessments}/${id}`, {
            state: {
                id: id
            }
        })

    }

    const fields = [
        idField,
        nameField.field,
        {
            field: 'reading_assessment_name',
            headerName: "שם אבחון",
            width: 140,
            align: 'center',
            headerAlign: "center",
        },
        {
            field: 'reading_assessment_category_name',
            headerName: "סוג אבחון",
            width: 140,
            align: 'center',
            headerAlign: "center",
            // valueGetter: (({ value }) => categories[value])
        },
        {
            field: 'status',
            headerName: "סטטוס תוצאות",
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: StatusCell,
            valueGetter: (value) => `${value.row?.sub_status || ""}`,
        },
        {
            field: 'file_uploaded_date',
            headerName: "מועד ביצוע",
            headerAlign: 'center',
            type: 'numbr',
            width: 140,
            align: "center",
            valueGetter: ({ value }) => `${timeAgoWithOutSeconds(value) || ""}`,
        },
        gradeOfAllocation,
        {
            field: 'rate',
            headerName: 'קצב',
            width: 100,
            align: 'center',
            headerAlign: "center",
            renderCell: columnItems["rate"] ? RateAndAccuracyLabel : RateAndAccuracy,
            valueGetter: (value) => { return { ...value.row, fields: { id: "fluency_rating_id", index: "fluency_rating_index" } } },
            sort: (sortModel) => sortByRateAndAccuracy(sortModel, "fluency_rating_index", qc.report),
            customMenu: [
                {
                    action: () => updateColumnItems(["rate", "accuracy"]),
                    icon: ChangeCircle,
                    label: `הצג ${columnItems["rate"] ? "סמלים" : "טקסטים"}`
                }
            ]
        },
        {
            field: 'score_rate',
            headerName: "מילים לדקה",
            width: 100,
            align: 'center',
            headerAlign: 'center',
            color: "red",
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { field: "num_words_per_minute", index: "fluency_rating_index", precent: false } } },
            sort: (sortModel) => sortNumbers(sortModel, "num_words_per_minute")
        },
        {
            field: 'accuracy',
            headerName: "דיוק משוקלל",
            type: 'number',
            width: 100,
            align: 'center',
            headerAlign: "center",
            renderCell: columnItems["accuracy"] ? RateAndAccuracyLabel : RateAndAccuracy,
            valueGetter: (value) => { return { ...value.row, fields: { id: "factored_percent_accuracy_rating_id", index: "factored_percent_accuracy_rating_index" } } },
            sort: (sortModel) => sortByRateAndAccuracy(sortModel, "factored_percent_accuracy_rating_index", qc.report),
            customMenu: [
                {
                    action: () => updateColumnItems(["rate", "accuracy"]),
                    icon: ChangeCircle,
                    label: `הצג ${columnItems["accuracy"] ? "סמלים" : "טקסטים"}`
                }
            ]
        },
        {
            field: 'score_fluency',
            headerName: "אחוז דיוק",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { field: "percent_accuracy", index: "percent_accuracy_rating_index", precent: true } } },
            sort: (sortModel) => sortNumbers(sortModel, "percent_accuracy")
        },
        {
            field: 'factored_percent_accuracy',
            headerName: "אחוז דיוק משוקלל",
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { field: "factored_percent_accuracy", index: "factored_percent_accuracy_rating_index", precent: true } } },
            sort: (sortModel) => sortNumbers(sortModel, "factored_percent_accuracy")
        },
        {
            field: 'error_percent',
            headerName: " טעויות",
            width: 100,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.error_percent),
            sort: (sortModel) => sortNumbers(sortModel, "error_percent")
        },
        {
            field: 'repetition_percent',
            headerName: " חזרות",
            width: 100,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.repetition_percent),
            sort: (sortModel) => sortNumbers(sortModel, "repetition_percent")
        },
        {
            field: 'insertion_percent',
            headerName: "הוספות",
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueGetter: ({ row }) => percent(row?.rating?.insertion_percent),
            sort: (sortModel) => sortNumbers(sortModel, "insertion_percent")
        },
        {
            field: 'omission_error_and_minor_error_percent',
            headerName: "השמטות",
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueGetter: ({ row }) => percent(row?.rating?.omission_error_and_minor_error_percent),
            sort: (sortModel) => sortNumbers(sortModel, "omission_error_and_minor_error_percent")
        },
        {
            field: 'self_correction_percent',
            headerName: " תקון עצמי",
            width: 100,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.self_correction_percent),
            sort: (sortModel) => sortNumbers(sortModel, "self_correction_percent")
        },
        {
            field: 'minor_error_percent',
            headerName: " שגיאה קלה",
            width: 100,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.minor_error_percent),
            sort: (sortModel) => sortNumbers(sortModel, "minor_error_percent")
        },
        {
            field: 'self_correction_and_minor_error_percent',
            headerName: " שגיאה קלה עם תקון עצמי",
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (value) => percent(value.row?.rating?.self_correction_and_minor_error_percent),
            sort: (sortModel) => sortNumbers(sortModel, "self_correction_and_minor_error_percent")
        },

        playField,
        {
            field: 'reports',
            type: 'actions',
            headerName: "צפיה בדו”ח",
            width: 110,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                const { rating, id } = row || {}

                const disabled = !rating?.percent_accuracy_rating_id || !rating?.fluency_rating_id

                return [
                    <IconButtonGroup
                        color="bg"
                        tooltip="לצפיה"
                        icon={disabled ? VisibilityDisabledIcon : VisibilityIcon}
                        selected={!disabled}
                        disabled={disabled}
                        action={() => {
                            goAssessment({ ...row, id: row.id })
                        }}
                    />
                ];
            }
        }
    ]

    return fields
}

export default useFields