import { AbilityContext } from "components/Permissions/Can"
import { useContext } from "react"


const useTestTakerName = () => {
    const ability = useContext(AbilityContext)
    const student = ability.can("read", "student-label") ? "תלמיד" : "ילד"

    const testTakerName = {
        accessorKey: 'first_name',
        accessorFn: row => `${row?.first_name || ''} ${row?.last_name || ''}`,
        cell: info => info.getValue(),
        header: `שם ה${student}`,
        footer: props => props.column.id
    }

    return testTakerName
}
export default useTestTakerName