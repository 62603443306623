import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { WrapIcon, WrapNumChild, GraphNumber, WrapGraphItem, WrapChild } from './Style'
import { firstWord } from 'utils/obj'
import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';

const BarItem = (props) => {
    const {
        value,
        current_test_taker,
        count, percent,
        index, theme, graphKey,
        isDrag, viewPercent, fixed, showAllChildren,
        isPercent } = props
    const { testTaker = {} } = useSelector(state => state.testTaker)

    return (
        <ThemeProvider theme={theme} key={`${graphKey}-${index}`}>
            <WrapGraphItem isDrag={isDrag} height={showAllChildren ? 80 : 35} current_test_taker={current_test_taker}>
                {(showAllChildren || current_test_taker) &&
                    <React.Fragment>
                        <WrapNumChild>
                            {
                                // !hide &&
                                viewPercent ? (percent ? `${percent?.toFixed(1)}%` : "") :
                                    current_test_taker ?
                                        firstWord(testTaker.test_taker_name) :
                                        count > 1 && showAllChildren && (`+${count}`)}
                        </WrapNumChild>
                        <WrapChild>
                            {
                                // !hide &&
                                ((showAllChildren || current_test_taker) && count >= 1 &&
                                    <WrapIcon fontSize="small" component={ChildIcon} inheritViewBox />)}
                        </WrapChild>
                    </React.Fragment>
                }
                <GraphNumber>
                    {
                        // !hide &&
                        <React.Fragment>
                            {fixed ? Number(value)?.toFixed(fixed) : value}
                            {isPercent ? "%" : ""}
                        </React.Fragment>
                    }

                </GraphNumber>


            </WrapGraphItem>
        </ThemeProvider>
    )
}

export default BarItem