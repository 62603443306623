import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


export default function SortableItem(props) {
    const { isDraggable } = props
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: props.id,
        disabled: !isDraggable
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className={isDraggable ? 'dragging' : ''} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {props.children}
        </div>
    );
}