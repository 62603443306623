import React from 'react'
import { RatingStyles } from './Style'

const Rating = (props) => {
    const { color, label } = props

    return (
        <RatingStyles color={color}>
            {label}
        </RatingStyles>
    )
}

export default Rating