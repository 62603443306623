import createReducer from "./reducerUtils"
import dataGridModel from 'keys/dataGridModel';
import { produce } from 'immer'
import { cloneDeep, entries } from "lodash";

const initialState = {
    rowsLoading: {

    },
    visibilityModel: {
        [dataGridModel.assessments]: {
            id: false,
            score_fluency: false,
            reading_assessment_grade_of_allocation_name: false,
            reading_assessment_name: false,
            factored_percent_accuracy: true,
            paly_audio: false,
            sub_status: false,
            rating_self_correction_and_minor_error_percent: false,
            rating_minor_error_percent: false,
            rating_self_correction_percent: false,
            rating_repetition_percent: false,
            rating_error_percent: false,
            rating_omission_error_and_minor_error_percent: false,
            rating_insertion_percent: false,
            col_ID: false
        },
        [dataGridModel.all_assessments]: {
        },
        [dataGridModel.last_assessments]: {
            grade_at_registration_name: false
        },
        [dataGridModel.admin_assessments]: {
            back_to_marking: false,
            cut_audio: false
        }
    },

}

const dataGrid = {
    updateRowsLoading(state, action) {
        let nRowsLoading = { ...state.rowsLoading }
        Object.keys(action.payload).forEach((key) =>
            nRowsLoading[key] = action.payload[key]
        )
        state.rowsLoading = nRowsLoading
    },
    setVisibilityModel(state, action) {
        const { key, value } = action.payload
        let nVisibilityModel = cloneDeep(state.visibilityModel)
        nVisibilityModel[key] = value
        state.visibilityModel = nVisibilityModel
    },
    updateVisibilityModel(state, action) {
        const { key, value } = action.payload
        let nVisibilityModel = cloneDeep(state.visibilityModel)
        const obj = entries(value)
        const keys = obj.map(entry => entry[0]); // Extract keys
        const values = obj.map(entry => entry[1]); //
        nVisibilityModel[key] = { ...nVisibilityModel[key], [keys[0]]: values[0] }
        state.visibilityModel = nVisibilityModel
    },
    setVisibilityModelByKeys(state, action) {
        const { key, value } = action.payload
        let nVisibilityModel = cloneDeep(state.visibilityModel[key])
        Object.keys(value).forEach((fieldKey) => {
            nVisibilityModel[fieldKey] = value[fieldKey]
        })
        state.visibilityModel[key] = nVisibilityModel
    },
}

export default produce(
    (state, action) => createReducer(state, action, dataGrid),
    initialState
)

