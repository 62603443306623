import styled from "styled-components";
import { dark, sidebar, secondary, ligthGray, white } from "styles/theme";
import { Report } from '@mui/icons-material';
import { Button } from "@mui/material";

const WrapFooter = styled.div`
display: flex;
width: 100%;
height:50px;
align-items: center;
flex-shrink:0;
border-top: 1px solid var(--MS, #416783);
`

const WrapSubItems = styled.div`
// position: relative;
// right: 10px;
// width: calc(100% - 10px);
`

const UserName = styled.div`
font-weight: 600;
`

const UserEmail = styled.div`
font-size: 16px;
color: #B6B9C7;
font-weight: 400;
`

const UserDetails = styled.div`
color:${white};
padding: 10px 0px 10px 0px;
font-size:16px;
`

const WrapSideBar = styled.div`
position: absolute;
right: 0;
z-index: 1;
height:100%;
background:${dark};
width:${sidebar}px;
// padding:15px;
display: flex;
flex-direction: column;
row-gap: 15px;
`

const ItemButton = styled(Button)`
display:flex;
column-gap: 10px;
display: flex !important;
justify-content: flex-start !important;


${props => props.disabled && `
color:${ligthGray} !important;
opacity:0.5 !important;

${props.selected && `
background:${secondary} !important;
`}

`}
`

const Header = styled.div`
padding:15px;
height:100px;
flex-shrink:0;
`

const Content = styled.div`
display: flex;
flex-direction: column;
margin-top: 10px;
flex-grow:1;
`

const WReportIcon = styled(Report)`
color:white;
width:30px;
height:30px;
`

export { UserEmail, UserName, WrapFooter, ItemButton, UserDetails, WrapSubItems, Content, WrapSideBar, Header, WReportIcon }