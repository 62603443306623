

import { useMemo } from 'react';
import { forEach, slice } from 'lodash';

const useStatsGroupedByRating = (stats, showArrows) => {
    return useMemo(() => {
        const statsArray = stats ? Object.entries({ ...stats }) : [];
        let prevItem = {}; // אתחול של prevItem
        let groupedStats = statsArray.reduce((acc, [key, value]) => {
            let rating = value.rating_index?.toString();
            if (!rating) {
                rating = prevItem.rating;
            }
            if (!acc[rating]) {
                acc[rating] = { items: [], count: 0, percent: 0, rating_index: rating };
            }
            acc[rating].count += value.count;
            acc[rating].percent += value.percent;
            acc[rating].items.push({ key, ...value });
            prevItem = { rating: value.rating_index, key: key };

            return acc;
        }, {});

        if (showArrows) {
            if (groupedStats[5]) {
                forEach(slice(groupedStats[5].items, 0, -10), item => {
                    item.hideStartBar = true;
                });
            }
            if (groupedStats[1]) {
                forEach(slice(groupedStats[1].items, 10), item => {
                    item.hideEndBar = true;
                });
            }
        }

        return groupedStats;
    }, [stats, showArrows]); // תלויות עבור useMemo
};

export default useStatsGroupedByRating;