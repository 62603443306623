import React from "react"
import classess from "mock/classess"
import { testTakerRQ } from "store/hooks"
import { CreateTestTakerContext } from "../CreateTestTakerContext"
import { useContext } from "react"
import { useSelector } from "react-redux"


const useFields = () => {
    const { schools } = useContext(CreateTestTakerContext)
    const { languages } = useSelector(state => state.static)

    //TODO
    const { data: grades } = testTakerRQ.useGetAllGrades()

    const fields =
        React.useMemo(
            () =>
                [
                    {
                        name: "first_name",
                        component: "input",
                        placeholder: "שם פרטי",
                        className: "_child__first_name",
                        required: true
                    },
                    {
                        name: "last_name",
                        component: "input",
                        placeholder: "שם משפחה",
                        className: "_child__last_name",
                        required: true
                    },
                    {
                        name: "gender",
                        component: "gender",
                        placeholder: "מגדר",
                        className: "_child__gender",
                        required: true
                    },
                    {
                        name: "tz",
                        placeholder: "תעודת זהות",
                        component: "input",
                        className: "_child__tz",
                        required: true
                    },
                    {
                        name: "dob",
                        // placeholder: "תאריך לידה",
                        component: "datePicker",
                        systemUI: true,
                        className: "_child__db",
                        required: true
                    },
                    {
                        name: "school",
                        placeholder: "בית ספר",
                        component: "autoComplete",
                        minWidth: 100,
                        options: schools,
                        getOptionLabel: (opt) => `${opt.name} ${opt.area ? `- ${opt.area}` : ""}`,
                        noOptionsText: 'אם ביה"ס שלכם לא נמצא ברשימה, כתבו אלינו',//TOTO
                        className: "_child__school",
                        systemUI: true,
                        required: true,
                        fullWidth: true,
                        isOptionEqualToValue: (option, value) => option.id === value.id
                    },
                    {
                        name: "grade",
                        placeholder: "שכבה",
                        minWidth: 100,
                        component: "autoComplete",
                        className: "_child__grade",
                        systemUI: true,
                        options: grades,
                        required: true
                    },
                    {
                        name: "t_class",
                        placeholder: "מספר כיתה",
                        minWidth: 100,
                        component: "autoComplete",
                        className: "_child__t_class",
                        systemUI: true,
                        options: classess,
                        required: true,
                        isOptionEqualToValue: (option, value) => option.id === value.id
                    },
                    {
                        name: "languages",
                        minWidth: 100,
                        className: "_child__langauge",
                        component: "autoComplete",
                        options: languages,
                        multiple: true,
                        labelKey: "label",
                        systemUI: true,
                        placeholder: "שפת אם",
                        required: true,
                        isOptionEqualToValue: (option, value) => option.id === value.id
                    },
                ]
            ,
            [grades, schools]
        );


    return fields
}

export default useFields 