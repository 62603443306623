import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { DataGrid } from 'components'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { queryClient as qc, types } from 'keys'
import { getFrequencyByWordDefFetch } from 'store/middlewares/assessment.crud'
import { LinguisticLabContext } from './LinguisticLabContext'
import { labHooks } from '.'
import { useTheme } from 'styles'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { DrawerWordFrequency } from './Style'
import { cacheLtr } from 'styles/theme'
import { CacheProvider } from '@emotion/react'
import { isEmpty } from 'lodash'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid'

const WordFrequency = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { selectedWordDef, assessmentFilters, setSelectedWordDef, openWordDrawer, setOpenWordDrawer } = useContext(LinguisticLabContext)

    const queryClient = useQueryClient()

    const fields = labHooks.useFields()

    const { data: frequencyByWordDefData, refetch: refetchGetfrequencyByWordDef } =
        useQuery({
            queryKey: [qc.wordFrequency, selectedWordDef],
            queryFn: async () => {
                const { data } = await getFrequencyByWordDefFetch({ word_def: selectedWordDef.word_def_id, assessmentFilters, headerFilters, testTaker })
                return data
            },
            enabled: !!selectedWordDef, refetchOnMount: false, refetchOnWindowFocus: false,
        })

    const onClose = () => {
        setOpenWordDrawer(false)
        setSelectedWordDef(null)
        queryClient.removeQueries({ queryKey: qc.wordFrequency });
    }

    const assessmentLen = queryClient.getQueryData([qc.assessmentFrequency])?.assessment_count

    return (
        <CacheProvider value={cacheLtr}>
            <DrawerWordFrequency
                anchor={"left"}
                open={openWordDrawer}
                onClose={onClose}
            >
                <div className='drawer-content'>
                    <WrapDataGrid>
                        <DataGrid
                            hideSerialCol
                            hideToolbar
                            data={isEmpty(frequencyByWordDefData) ? null : frequencyByWordDefData}
                            fields={fields}
                            rowId="type"
                            CustomFooter={() =>
                                <GridFooterContainer className='custom-footer'>
                                    <span>
                                        {" מבדקים "}
                                    </span>
                                    <span>
                                        {assessmentLen}
                                    </span>

                                </GridFooterContainer>
                            }
                        />

                    </WrapDataGrid>
                </div>
            </DrawerWordFrequency>
        </CacheProvider>
    )
}

export default WordFrequency