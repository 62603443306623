import React, { useCallback, useState } from 'react';
import { graphsHooks } from '.';
import { StatsBar, Tabs } from 'components';
import { DrawerGraphStyles } from './Style'
import { Table } from 'components';
import { map } from 'lodash';

const Graphs = (props) => {
    const { setOpen, open, wordDefCount } = props

    const distributionTypeBtns = graphsHooks.useDistributionTypeBtns()

    const [selectedTab, setSelectedTab] = useState(0)

    const columns = graphsHooks.useColumns()

    const changeDistributionTab = useCallback((index) => {
        setSelectedTab(index)
    }, [])

    const distribution = distributionTypeBtns[selectedTab]

    const data = {
        results: map(distribution?.stats?.table, (value, key) => ({ ...value, key }))
    }

    return (
        <DrawerGraphStyles
            anchor={"bottom"}
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className='wrap-graph-content'>
                <div className='icon-buttons'>
                    <Tabs
                        tabs={distributionTypeBtns}
                        handleTab={changeDistributionTab}
                    />
                </div>
                <div className='wrap-table'>
                    <div className='assessment-count'>
                        {"מספר מילים במבדק"}{" "}
                        {wordDefCount}
                    </div>
                    <div className='table'>
                        {distribution?.stats?.table &&
                            <Table
                                hideSorting
                                hidePagination
                                hideToolbar
                                data={data}
                                columns={columns}
                            />
                        }
                    </div>
                </div>

                <div className='wrap-graph'>
                    <div className='graph'>
                        <StatsBar
                            showAllChildren
                            disabled={false}
                            viewPercent
                            type={distribution.type}
                            {...distribution}
                            stats={distribution.stats?.graph}
                            fullWidth={true}

                        />
                    </div>
                </div>
            </div>
        </DrawerGraphStyles >
    );
};

export default Graphs;