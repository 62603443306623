
const sortByFullName = (sortVal) => {
    let customSort = {}

    if (sortVal.id === 'first_name') {
        customSort = sortVal.desc ? { id: 'last_name', desc: false } : { id: 'first_name', desc: true };
    }
    else if (sortVal.id === 'last_name') {
        customSort = sortVal.desc ? { id: 'first_name', desc: false } : { id: 'last_name', desc: true };
    }
    else return null
    return customSort
}

export { sortByFullName }