import { isEmpty, map } from "lodash";


const formatTime = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    return ('0' + minutes).slice(-2) + ':' + ('0' + remainingSeconds).slice(-2);
}

function customFormatDate(nDate) {
    let date = new Date(nDate)
    const day = date.getDate();
    const month = date.getMonth() + 1; // January is 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function formatDayAndMonth(date) {
    const day =  String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
    return `${day}/${month}`;
}

function formatDateTime(date) {
    const now = new Date();
    const targetDate = new Date(date);

    const yearsDiff = now.getFullYear() - targetDate.getFullYear();
    const monthsDiff = now.getMonth() - targetDate.getMonth();
    const daysDiff = now.getDate() - targetDate.getDate();

    const hours = String(targetDate.getHours()).padStart(2, '0');
    const minutes = String(targetDate.getMinutes()).padStart(2, '0');

    if (yearsDiff > 0) {
        return `${String(targetDate.getDay()).padStart(2, '0')}/${String(targetDate.getMonth()).padStart(2, '0')}/${targetDate.getFullYear()}`;
    } else if (monthsDiff > 0) {
        return ` ${hours}:${minutes} ${formatDayAndMonth(targetDate)}`;
    } else if (daysDiff > 0) {
        return `${hours}:${minutes} ${formatDayAndMonth(targetDate)} `;
    } else {
        return `${hours}:${minutes} `;
    }
}

function currentDateTime(date) {
    const now = new Date();
    const targetDate = new Date(date);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set time to 00:00:00

    if (!date)
        return "";
    return formatDateTime(targetDate);
}


function timeAgo(date) {
    const now = new Date();
    if (date) {
        const targetDate = new Date(date)
        const diff = Math.abs(now - targetDate) / 1000; // Difference in seconds

        const years = Math.floor(diff / (365 * 24 * 60 * 60));
        const months = Math.floor(diff / (30 * 24 * 60 * 60));
        const days = Math.floor(diff / (24 * 60 * 60));
        const hours = Math.floor((diff % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((diff % (60 * 60)) / 60);
        const seconds = Math.floor(diff % 60);

        if (years > 0) {
            return `${years > 1 ? `לפני ${years} שנים` : 'חודש שעבר'}`;
        } else if (months > 0) {
            return `${months > 1 ? `לפני ${months} חדשים ` : 'חודש שעבר'}`;
        } else if (days > 0) {
            return ` ${days > 1 ? `לפני ${days} ימים` : 'אתמול'}`
        } else if (hours > 0) {
            return `${hours > 1 ? `לפני ${hours} שעות` : "לפני שעה"}, ${minutes > 1 ? `${minutes} דקות` : "דקה אחת"}, ${seconds > 1 ? `${seconds} ושניות` : "ושניה"}`;
        } else if (minutes > 0) {
            return `${minutes > 1 ? `לפני ${minutes} דקות` : "לפני דקה"}, ${`${seconds} שניות`}`;
        } else {
            return `${seconds > 1 ? `לפני ${seconds} שניות` : "לפני שניה"}`;
        }
    }
    return null
}

function timeAgoWithOutSeconds(date) {
    const now = new Date();
    if (date) {
        const targetDate = new Date(date)
        const diff = Math.abs(now - targetDate) / 1000; // Difference in seconds

        const years = Math.floor(diff / (365 * 24 * 60 * 60));
        const months = Math.floor(diff / (30 * 24 * 60 * 60));
        const days = Math.floor(diff / (24 * 60 * 60));
        const hours = Math.floor((diff % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((diff % (60 * 60)) / 60);

        if (days > 7) {
            // If more than a week, format as DD/MM/YYYY
            const day = String(targetDate.getDate()).padStart(2, '0');
            const month = String(targetDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const year = targetDate.getFullYear();
            return `${day}/${month}/${year}`
        }
        else if (years > 0) {
            return `${years > 1 ? `לפני ${years} שנים` : 'חודש שעבר'}`;
        } else if (months > 0) {
            return `${months > 1 ? `לפני ${months} חדשים ` : 'חודש שעבר'}`;
        } else if (days > 0) {
            return ` ${days > 1 ? `לפני ${days} ימים` : 'אתמול'}`
        } else if (hours > 0) {
            return `${hours > 1 ? `לפני ${hours} שעות` : "לפני שעה"}, ${minutes > 1 ? `${minutes} דקות` : "דקה אחת"}`;
        } else if (minutes > 0) {
            return `${minutes > 1 ? `לפני ${minutes} דקות` : "לפני דקה"}`;
        } else return "עכשיו"
    }
    return null
}

const getName = ({ first_name, last_name }) => {
    return `${first_name} ${last_name}`
}

const convertSelectToValue = (obj) => {
    let values = {}
    if (isEmpty(obj)) return
    Object.keys?.(obj)?.map((key) => {
        values[key] = obj[key]?.id || obj[key]
    })
    return values
}

const percent = (val) => {
    if (val) return `${val}%`
    return null
}

const convertArrayToIdsString = (array) => {
    const idsArray = map(array, 'id');
    const idsString = idsArray.join(',');
    return idsString
}

function getDecodedFilenameFromUrl(url) {
    if (!url) return
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
    return decodeURIComponent(filename);
}

const replaceId = (originalString, idString = ":id") => {
    // Use regular expression to find numbers in the string
    const regex = /\d+/g;

    // Replace all numeric sequences with the provided ID string
    const replacedString = originalString.replace(regex, idString);

    return replacedString;
};

function decimalToTime(decimal) {
    if (!decimal || decimal.toString() === "0.00") return null
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

function wavesurferToTime(time) {
    return [
        Math.floor((time % 3600) / 60), // minutes
        ('00' + Math.floor(time % 60)).slice(-2) // seconds
    ].join(':');
};

const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-CA') // פורמט DD/MM/YYYY
};

export { formatDate, customFormatDate, wavesurferToTime, decimalToTime, replaceId, getDecodedFilenameFromUrl, convertArrayToIdsString, percent, currentDateTime, convertSelectToValue, getName, timeAgo, formatTime, timeAgoWithOutSeconds }

