import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { ScrollCss } from 'styles/GlobalCss';
import { white } from 'styles/theme';


const NormalizerStyles = muiStyled(Box)(({ theme, $systemUI }) => ({
    height: "100%",
    overflow: "auto",
    '.sc-section': {
        width: "100%",
        padding: 10,
        border: `2px solid ${white}`
    },
    '.sc-sections': {
        display: "flex",
        flexDirection: "column",
        rowGap: 15,
        flexGrow: 1,
        overflow: "auto",
        ...ScrollCss,
    },
    '.sc-section-actions': {
        display: "flex",
        columnGap: 5
    },
    '.sc-actions': {
        background: white,
        height: 70,
        width: "100%",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 15,
        boxSizing: "border-box"
    },
    '.sc-header-btns': {
        display: "flex",
        columnGap: 5
    }
}));


const GraphStyles = muiStyled(Box)(({ edit, theme, $systemUI }) => ({
    padding: '20px 10px',
    border: `2px solid ${white}`,
    height: 400,
    background: edit && white,
    display: "flex",
    flexDirection: "column",
    rowGap: 15,
    borderRadius: 5,
    '.sc-table': {
        marginBottom: 20
    },
    '.sc-header-item': {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    '.sc-detail': {
        display: "flex",
        columnGap: 5
    }
}));

export { NormalizerStyles, GraphStyles }

