import React, { useEffect, useState } from 'react';
import { TestTakersCrud, useGenerateCouponCode } from './hooks';
import { useSelector } from 'react-redux';
import { Header, WrapDataGrid } from 'components/DataGrid/Style';
import { Button } from 'components/Form';
import { Table } from 'components';
import { dataGridModel } from 'keys';
import { WrapHeader } from 'styles/GlobalStyle'
import { testTakerHooks } from '.'
import { queryClient as qc } from 'keys';
import { getWithLastAssessmentsFetch } from 'store/middlewares/testTakers.crud';
import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { initialPage } from 'keys/pagination';

const TestTakers = () => {
    const { setRowSelectionModel } = TestTakersCrud()
    const { headerFilters } = useSelector(state => state.header)

    const [page, setPage] = useState(initialPage)
    const [sorting, setSorting] = useState([])
    const [searchVal, setSearchVal] = useState("")

    const generateCouponCodeBtn = useGenerateCouponCode()

    const fields = testTakerHooks.useTestTakerfields()
    const columns = testTakerHooks.useColumns()
    const headerBtns = testTakerHooks.useHeaderBtns()

    const testTakersOptions = queryOptions({
        queryKey: [qc.getWithLastAssessments, page, sorting],
        queryFn: async () => {
            const { data } = await getWithLastAssessmentsFetch({ headerFilters, page, search: searchVal, sorting, model: qc.getWithLastAssessments })
            return data
        },
        retry: 0
    })

    const testTakers = useQuery(testTakersOptions)

    useEffect(() => {
        testTakers.remove?.()
        testTakers.refetch()
        setPage(initialPage)
    }, [headerFilters])

    return (
        <WrapDataGrid>
            <WrapHeader>
                <Header>
                    {[...headerBtns, generateCouponCodeBtn].map((headerBtn, index) =>
                        <Button
                            key={index}
                            {...headerBtn}
                            action={() => {
                                headerBtn.action(page)
                            }}
                        />
                    )}
                </Header>
            </WrapHeader>
            <Table
                withTitle
                loading={testTakers.isFetching && !testTakers.data}
                data={testTakers.data}
                columns={columns}
                page={page}
                setPage={setPage}
                rowKey={"test_taker_id"}
                visibilityModelKey={dataGridModel.last_assessments}
                onRowSelection={setRowSelectionModel}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                sorting={sorting}
                setSorting={setSorting}
                queryKey={testTakersOptions.queryKey}
            />
        </WrapDataGrid>
    );
}

export default TestTakers
