import React from 'react'
import { WrapStatus } from './Style'
import { isEmpty } from 'lodash'
import { AllAssessmentsData } from './hooks'
import { statuses } from 'keys'
import { performAssessmentsHooks } from '.'

const StatusCell = (props) => {
    const { row, column } = props

    const { audio_file_url, assessment_group } = row.original
    const { uploaded } = statuses

    const mappingByStatus = performAssessmentsHooks.useStatuses()

    // let customStatus = mappingByStatus[status]
    // if (audio_file_url)
    //     customStatus = mappingByStatus[uploaded]

    const unCompleteInAssessmentGroup = assessment_group.filter((ass) => !ass.audio_file_url)
    const isBrokenFileInAssessmentGroup = assessment_group.filter((ass) => ass.status === statuses.brokenFile)

    let customStatus
    if (unCompleteInAssessmentGroup.length === assessment_group.length)
        customStatus = mappingByStatus.unComplete
    else if(isBrokenFileInAssessmentGroup.length)
        customStatus = mappingByStatus.brokenFile
    else if (isEmpty(unCompleteInAssessmentGroup))
        customStatus = mappingByStatus.complete
    else customStatus = mappingByStatus.part

    return (
        <div>
            <WrapStatus
                border={customStatus.border}
            >
                {customStatus.label}
            </WrapStatus>
        </div>
    )
}

export default StatusCell