import { ordering } from "keys";
import { isEmpty } from "lodash";

const setFilters = ({ page, search, sorting, model }) => {
    let params = {}

    if (page)
        params.page = page

    if (search) {
        const encodedQuery = btoa(unescape(encodeURIComponent(search))); // Base64 encoding
        params.search = encodedQuery
    }

    if (!isEmpty(sorting)) {
        const { id, desc } = sorting[0]

        let orderKey = ordering[model]?.[id] || id

        if (desc)
            params.o = orderKey
        else params.o = `-${orderKey}`
    }

    return params
}

export default setFilters