import React, { useContext } from 'react'
import { Notifications, ShortText } from '@mui/icons-material'
import { RateAndAccuracyHeaderStyles } from './Style'
import { AssessmentsContext } from './AssessmentsContext'

const RateAndAccuracyHeader = (props) => {
    const { column } = props
    const { meta, accessorKey } = column?.columnDef || {}

    const { viewPercent, setViewPercent } = useContext(AssessmentsContext)

    const updateHeaderView = (e) => {
        setViewPercent({
            ...viewPercent,
            [accessorKey]: !viewPercent[accessorKey]
        })
        e.stopPropagation()
        e.preventDefault()
    }

    return (
        <RateAndAccuracyHeaderStyles>
            {meta?.label}
            {viewPercent?.[accessorKey] ?
                <ShortText onClick={updateHeaderView} fontSize="small" /> :
                <Notifications onClick={updateHeaderView} fontSize="small" />}
        </RateAndAccuracyHeaderStyles>
    )
}

export default RateAndAccuracyHeader