import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"


const useEnd = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)

    const updateWordEnd = (index, word_def_id) => {
        if (assessment.endPoint?.word_def_id === word_def_id)
            dispatch(actions.updateAssessment({ endPoint: {} }))

        else
            dispatch(actions.updateAssessment({
                endPoint: {
                    index,
                    word_def_id
                }
            }))
    }

    return updateWordEnd
}

export default useEnd