import { Refresh } from "@mui/icons-material"
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/Refresh.svg';
import { SvgIcon } from "@mui/material";
import { useCallback, useContext } from "react";
import { NormalizerContext } from "../NormalizerContext";
import { ScoreRatingRM } from "models/RM";
import { useMutation } from "@tanstack/react-query";
import { saveScoreRatingMethodsFetch } from "store/middlewares/rating.crud";
import { last, uniqBy } from "lodash";
import { toast } from "react-toastify";
import { toastConfig } from "config";
import { normalizerHooks } from "..";

const useHeaderBtns = () => {
    const { stats, scoreRatingSemesters, activeScoreItemStatus, setActiveScoreItemStatus } = useContext(NormalizerContext)
    const viewUpdatedScoreRating = normalizerHooks.useViewUpdatedScoreRating()
    const applyScoreRating = normalizerHooks.useApplyScoreRating()
    const initScoreRating = normalizerHooks.useInitScoreRating()

    const headerBtns = [
        {
            label: "אפס תצוגת שינויים",
            disabled: true,
            action: initScoreRating,
            icon: <SvgIcon fontSize="small" component={RefreshIcon} inheritViewBox />,
            size: "small",
            disabled: !activeScoreItemStatus
        },
        {
            label: "תצוגת שינויים",
            action: viewUpdatedScoreRating,
            icon: <SvgIcon fontSize="small" component={VisibilityIcon} inheritViewBox />,
            size: "small",
            disabled: activeScoreItemStatus != "edit",
        },
        {
            label: "החל נתונים חדשים בDATA",
            action: applyScoreRating,
            disabled: activeScoreItemStatus != "view",
            size: "small",
        }
    ]

    return headerBtns
}

export default useHeaderBtns