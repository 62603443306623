import { app } from "components/Router/routes"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"


const useMatchingLeraningBtn = () => {
    const navigate = useNavigate()
    const params = useParams()

    const { assessment } = useSelector(state => state.assessment)
    const assessmentID = assessment.id || params?.assessmentID

    const goMatchingLeraning = useCallback(() => {
        navigate(`${app.matchingLearning}/${assessmentID}`)
    }, [])

    const matchingLeraningBtn = {
        label: "קריאה מותאמת",
        action: goMatchingLeraning,
        height: '35px',
        fontsize: 16
    }

    return matchingLeraningBtn
}

export default useMatchingLeraningBtn