import { bg } from "styles/theme"
import { useContext } from "react"
import { TableContext } from "../TableContext"
import { IconButton } from "components/Form"
import { Done } from "@mui/icons-material"
import { useQueryClient } from "@tanstack/react-query"
import { queryClient as qc } from "keys"

const useSearchFilter = () => {
    const { searchVal, setSearchVal, queryKey } = useContext(TableContext)

    const queryClient = useQueryClient()

    const handleSearch = () => {
        queryClient.fetchQuery({ queryKey: queryKey })
    }

    const search = {
        placeholder: "חפש...",
        input: {
            value: searchVal,
            onChange: setSearchVal
        },
        endAdornment: {
            component: () => <IconButton
                height={30}
                width={30}
                color='dark'
                icon={<Done color="white" />}
                action={handleSearch}
            />
        },
        bg: bg,
        onKeyDown: (event) => {
            if (event.key === 'Enter')
                handleSearch()
        }
    }

    return search
}

export default useSearchFilter