import { useMemo } from "react";
import { tableHooks } from "components/Table";
import { systems } from 'keys'
import { IconButton, IconButtonGroup } from "components/Form";
import { CallMissedOutgoing, RemoveRedEyeOutlined, Replay } from "@mui/icons-material";
import { adminAssessmentsHooks } from "components/Admin/AdminAssessments";
import { currentDateTime, decimalToTime } from "utils/formatter";
import { audioCuttingHooks, markingWordsHooks } from "..";
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import { ReactComponent as GoIcon } from 'assets/icons/Go.svg';
import { ReactComponent as BackToIcon } from 'assets/icons/BackTo.svg';

const useColumns = () => {
    const selectAndViewAssessment = adminAssessmentsHooks.useSelectAndCutting()

    const { action, loading } = audioCuttingHooks.useBackToConfirmation()

    const columns = useMemo(
        () => [
            {
                accessorKey: 'update_date',
                header: "עדכון אחרון",
                cell: info => currentDateTime(info.getValue()),
            },
            {
                accessorKey: 'audio_cutting_update_by_username',
                header: 'נחתך ע"י',
            },
            {
                accessorKey: 'audio_cutting_duration',
                header: "משך חיתוך",
                cell: info => decimalToTime(info.getValue()),
            },
            {
                accessorKey: 'audio_cutting_start_date',
                header: " התחלת חיתוך",
                cell: info => currentDateTime(info.getValue()),
            },
            {
                accessorKey: 'audio_cutting_end_date',
                header: " חיתוך סימון",
                cell: info => `${currentDateTime(info.getValue()) || ""}`,
            },
            {
                accessorKey: 'enter_audio_cutting',
                header: "כניסה",
                enableSorting: false,
                cell: ({ row }) => {

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="לכניסה"
                            selected={true}
                            icon={GoIcon}
                            action={() => {
                                selectAndViewAssessment(row.original)
                            }}
                        />
                    );
                }
            },
            {
                accessorKey: 'view_audio_cutting',
                header: "צפייה",
                enableSorting: false,
                cell: ({ row }) => {

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="לצפיה"
                            icon={VisibilityIcon}
                            selected={true}
                            action={() => {
                                selectAndViewAssessment(row.original)
                            }}
                        />
                    );
                }
            },
            {
                accessorKey: 'back_to_cutting',
                header: "החזר לחיתוך",
                enableSorting: false,
                cell: ({ row }) => {

                    const disabled = loading
                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="החזר לחיתוך"
                            icon={BackToIcon}
                            selected={!disabled}
                            disabled={disabled}
                            action={() => action(row.original, true)}
                        />
                    )
                }
            }
        ], []
    )

    return columns
}

export default useColumns