import React, { useCallback, useState } from 'react'
import { ChildrenGraphStyles, WrapGraph, WrapIcon, WrapNumChild, WrapGraphTexts, GraphNumber, WrapGraphItem, WrapChild, LineStyles } from './Style'
import { Progress } from 'components/Record'
import { FlexCenter } from 'styles/GlobalStyle'
import { isEmpty, uniqBy } from 'lodash'
import { ratingColors, ratingColors2, ratingColors3, ratingText } from 'keys/rating'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay, } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, } from '@dnd-kit/sortable';
import { BarLine, BarTexts, statsBarHooks } from '.'
import { Draggable, BarItem, DraggableItem } from '.'
import { gray } from 'styles/theme'

const StatsBar = (props) => {
    const { handleOnDragEnd, showArrows, setShowStartBar, setShowEndBar, showEndBar, showStartBar = true, updateStats, sourceStats, stats, updateGraphRating, fullWidth, graphKey, isDrag, showFooter, graphText, text, viewPercent, fixed, scoreRating, showAllChildren, percent: isPercent, disabled } = props
    const [destination, setDestination] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const groupedStats = statsBarHooks.useStatsGroupedByRating(stats, showArrows);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const sourceStatsEntries = Object.entries?.(sourceStats || {});
    const uniqueRatings = uniqBy(sourceStatsEntries, ([key, value]) => value.rating_id);// שימוש ב-Lodash כדי לשמור רק אובייקטים עם rating_id ייחודיים
    const ratingsObject = Object.fromEntries(uniqueRatings); // המרת המערך חזרה לאובייקט תוך שמירה על המפתחות

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragMove = useCallback((event) => {
        setDestination(event.over.id)
    }, []);

    return (
        <ChildrenGraphStyles isDrag={isDrag} showAllChildren={showAllChildren}>
            <BarTexts
                isDrag={isDrag}
                showFooter={showFooter}
                graphText={graphText}
                text={text}
                showAllChildren={showAllChildren}
            />

            <div style={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                top: showAllChildren && isDrag && "41px",
                display: "flex", flexDirection: "column", overflow: "auto"
            }}>
                <DndContext sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragMove={handleDragMove}
                    onDragEnd={(val) => {
                        handleOnDragEnd(val)
                        setActiveId(null);

                    }}
                >
                    <WrapGraph showFooter={showFooter} className="characters" disabled={disabled}
                    >
                        {showArrows && <div style={{ height: "69px" }} onClick={setShowStartBar}>
                            {showStartBar ? <ChevronRight color={"rating5"} /> : <ChevronLeft color={"rating5"} />}
                        </div>}

                        {!isEmpty(stats) ? Object.keys(groupedStats).reverse().map((rating, ratingIndex) => {
                            const ratingItems = groupedStats?.[rating]
                            const { items, count, percent, rating_index } = ratingItems

                            const textColor = ratingText[rating_index - 1]
                            const color = ratingColors[rating - 1]
                            const lightColor = ratingColors3[ratingIndex]
                            const prevColor = ratingColors[rating]
                            const prevLightColor = ratingColors3?.[ratingIndex - 1]

                            return (
                                <>
                                    <span style={{ flexGrow: fullWidth ? 1 : 0 }}>
                                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                                            <SortableContext items={items}                                           >
                                                {items?.map((item, index) => {
                                                    const { hideStartBar, hideEndBar, key, hide, percent, count, current_test_taker } = item
                                                    const isSourceItem = ratingsObject[key] && (rating != 5 || index != 0)

                                                    const theme = {
                                                        textColor: textColor,
                                                        color: item.rating_id ? color : null,
                                                        prevColor: prevColor,
                                                        lightColor: lightColor,
                                                        prevLightColor: prevLightColor,
                                                        isDrag: isDrag,
                                                        isSourceItem: isSourceItem,
                                                        firstItem: ratingIndex == 0 && index == 0,
                                                        firstRatingItem: ratingIndex != 0 && index == 0
                                                    }


                                                    return (
                                                        ((!hideStartBar || showStartBar) && (!hideEndBar || showEndBar)) && <>
                                                            <Draggable key={key.toString()} id={key.toString()}
                                                                isDraggable={
                                                                    isDrag && (ratingIndex != 0 && index == 0)
                                                                }>
                                                                <div style={{
                                                                    // flexGrow: 1,
                                                                    width: ((ratingIndex != 0 && index == 0) || showAllChildren || (ratingsObject[key] && (rating != 5 || index != 0))) ? 42 : 5,
                                                                    display: "flex",
                                                                    flexDirection: "column"
                                                                }}>
                                                                    {
                                                                        (isDrag || !showAllChildren) &&
                                                                        <div style={{ width: "100%", height: "100%" }}>
                                                                            <span style={{ width: "100%", height: "100%", display: "flex" }}
                                                                            >
                                                                                <div style={{ width: "100%", height: "100%", display: "flex" }}>
                                                                                    <DraggableItem
                                                                                        theme={theme}
                                                                                        showChildren={showAllChildren}
                                                                                        hide={!(!index && !theme.firstItem)}
                                                                                        fixed={fixed}
                                                                                        color={textColor}
                                                                                        colors={[
                                                                                            color,
                                                                                            color,
                                                                                            prevColor,
                                                                                            prevColor
                                                                                        ]}
                                                                                        value={key}
                                                                                    />
                                                                                </div>

                                                                            </span>
                                                                        </div>
                                                                    }

                                                                    {showAllChildren &&
                                                                        <BarItem
                                                                            viewPercent={viewPercent}
                                                                            index={index}
                                                                            theme={theme}
                                                                            value={key}
                                                                            current_test_taker={current_test_taker}
                                                                            percent={percent}
                                                                            count={count}
                                                                            isDrag={isDrag}
                                                                            graphKey={graphKey}
                                                                            fixed={fixed}
                                                                            showAllChildren={showAllChildren}
                                                                            isPercent={isPercent}
                                                                        />
                                                                    }
                                                                </div>
                                                            </Draggable>
                                                            {isSourceItem &&
                                                                <div style={{
                                                                    height: 15, transform: 'translateZ(0px)', flexShrink: 0, zIndex: 3
                                                                }}>
                                                                    <div style={{
                                                                        position: "absolute",
                                                                        right: 11,
                                                                        zIndex: 2
                                                                    }}>
                                                                        <DraggableItem
                                                                            theme={theme}
                                                                            showChildren={showAllChildren}
                                                                            zIndex={250}
                                                                            color={gray}
                                                                            hide={!(!index && !theme.firstItem) && !isSourceItem}
                                                                            fixed={fixed}
                                                                            colors={[
                                                                                ratingColors2[ratingsObject[key].rating_index - 1],
                                                                                ratingColors2[ratingsObject[key].rating_index - 1],
                                                                                ratingColors2[ratingsObject[key].rating_index],
                                                                                ratingColors2[ratingsObject[key].rating_index],
                                                                            ]}
                                                                            value={key}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </SortableContext>
                                        </span>
                                        {!showAllChildren &&
                                            <BarLine
                                                color={color}
                                                count={count}
                                                percent={percent}
                                                ratingIndex={ratingIndex}
                                            />
                                        }
                                    </span>

                                </>
                            )
                        }
                        ) :
                            <FlexCenter>
                                <Progress />
                            </FlexCenter>
                        }
                        {showArrows && <div style={{ height: "69px" }} onClick={setShowEndBar}>
                            {showEndBar ? <ChevronLeft color={"rating1"} /> : <ChevronRight color={"rating1"} />}
                        </div>}

                        {activeId &&
                            <DragOverlay>
                                <DraggableItem
                                    showChildren={showAllChildren}
                                    fixed={fixed}
                                    color={ratingText[stats[activeId].rating_index - 1]}
                                    colors={[
                                        ratingColors[stats[activeId].rating_index - 1],
                                        ratingColors[stats[activeId].rating_index - 1],
                                        ratingColors[stats[activeId].rating_index],
                                        ratingColors[stats[activeId].rating_index]
                                    ]}
                                    value={destination || activeId}
                                />
                            </DragOverlay>
                        }
                    </WrapGraph >
                </DndContext>
            </div>
        </ChildrenGraphStyles >
    )
}

export default StatsBar