import { SpeedDial, SpeedDialAction } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { thirdly4 } from 'styles/theme';
import { thirdly2 } from 'styles/theme';
import { black, gTheme } from 'styles/theme';

const DialAction = muiStyled(SpeedDialAction)(({ theme, opacity, borderStyle = "solid", borderColor }) => ({
    width: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    height: '30px',
    borderRadius: '0px',
    border: `2px ${borderStyle} ${borderColor}`,
    color: black,
    boxShadow: "none",
    opacity: opacity && 0.4
}));

const Dial = muiStyled(SpeedDial)(({ theme, width }) => ({
    borderRadius: '20px',
    '.MuiSpeedDial-fab': {
        width: width || '140px',
        textTransform: "none",
        height: '30px',
        borderRadius: '20px',
        border: `1px solid ${thirdly2}`,
        background: thirdly4,
        textTransform: "none",
        color: black,
        boxShadow: "none",
        '&:hover': {
            background: thirdly4,
        },
        '&:focus': {
            background: thirdly4,
            boxShadow: 'none'
        },
    }
}));

export { DialAction, Dial }
