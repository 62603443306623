import React, { useState } from 'react'
import Table from './Table'

const WrapTableContext = (props) => {
    const { searchVal, setSearchVal, page, sorting, queryKey } = props

    const commonState = {
        searchVal, setSearchVal,
        page,
        sorting,
        queryKey
    }

    return (
        <TableContext.Provider value={commonState}>
            <Table {...props} />
        </TableContext.Provider>
    )
}

export const TableContext = React.createContext()
export default WrapTableContext