import { useCallback, useContext } from "react"
import { TranscriptionContext } from "components/Admin/Transcription/TranscriptionContext"
import { wordsRQ } from "store/hooks"
import { correct, insertion, na, omission, substitution } from "keys/types"
import { addItem } from "utils/array"

const TranscriptionCurd = () => {
    const { setCurrentWord, currentWord } = useContext(TranscriptionContext)

    const { mutateAsync: deleteWordRQ, isPending: deleteWordLoading } = wordsRQ.useDeleteWord()

    const cleanTranscription = useCallback(() => {
        deleteWordRQ(currentWord)
    }, [currentWord])

    const addPhoneme = useCallback((index) => {
        let nPhoneme = {
            vowel_code: null,
            letter_add_on: null,
            letter: null,
            vowel_status: insertion,
            letter_status: insertion,
            letter_add_on_status: insertion,
            letter_add_ons: []
        }
        const nPhonemes = addItem(currentWord.phonemes, index, nPhoneme)
        setCurrentWord({ ...currentWord, phonemes: nPhonemes })
    }, [currentWord])

    return {
        addPhoneme,
        cleanTranscription,

        deleteWordLoading,
    }

}

export default TranscriptionCurd