import { actions } from 'store'
import { useSelector, useDispatch } from "react-redux";
import { StoreCrud } from "store/hooks";
import { secretStorage } from "config";
import { queryOptions, useQuery } from '@tanstack/react-query';
import { queryClient as qc } from 'keys'
import { getCategoriesFetch } from 'store/middlewares/assessment.crud';

const MainCrud = () => {
    const { affiliations, groups, methods } = useSelector(state => state.affiliation)
    const { definitionTypes, assessmentDefs } = useSelector(state => state.assessment)
    const { testTakers, grades, tClasses } = useSelector(state => state.testTaker)

    const dispatch = useDispatch()

    // const { getAssessmentDefs } = StoreCrud()

    const categoryOptions = queryOptions({
        queryKey: qc.categories,
        queryFn: async () => {
            const { data } = await getCategoriesFetch()
            dispatch(actions.setCategories(data))
        },
        refetchOnWindowFocus: false,
    })

    const categories = useQuery(categoryOptions)

    const getDefinitionTypes = async () => {
        try {
            const definitionTypes = await dispatch(actions.getDefinitionTypes())
            dispatch(actions.setDefinitionTypes(definitionTypes))
        }
        catch {
            console.log("error getDefinitionTypes")
        }
    }

    const init = async () => {
        const token = secretStorage.getItem("user")?.token
        if (token)
            dispatch(actions.setToken(token))
        // if (!assessmentDefs) {
        //     getAssessmentDefs()
        // }
        if (!methods)
            try {
                const methods = await dispatch(actions.getMethods())
                dispatch(actions.setMethods(methods))
            }
            catch {
                console.log("error")
            }
        // if (!testTakers)
        //     try {
        //         const testTakers = await dispatch(actions.getTestTakersOptions())
        //         if (testTakers?.length === 1) {
        //             dispatch(actions.updateHeaderFilters({ test_taker: testTakers[0] }))
        //         }
        //         dispatch(actions.setTestTakers(testTakers))
        //     }
        //     catch {
        //         console.log("error getAssessments")
        //     }

        if (!definitionTypes)
            getDefinitionTypes()

    }

    return {
        init
    }
}

export default MainCrud