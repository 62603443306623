import { useMemo } from "react";
import { tableHooks } from "components/Table";
import { systems } from 'keys'
import { IconButton, IconButtonGroup } from "components/Form";
import { CallMissedOutgoing, RemoveRedEyeOutlined, Replay } from "@mui/icons-material";
import { adminAssessmentsHooks } from "components/Admin/AdminAssessments";
import { currentDateTime, decimalToTime } from "utils/formatter";
import { markingWordsHooks, transcriptionHooks } from "..";
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import { ReactComponent as GoIcon } from 'assets/icons/Go.svg';
import { ReactComponent as BackToIcon } from 'assets/icons/BackTo.svg';


const useColumns = () => {
    const selectAndViewAssessment = adminAssessmentsHooks.useSelectAndTranscription()

    const { action, loading } = transcriptionHooks.useBackToConfirmation()

    const columns = useMemo(
        () => [
            {
                accessorKey: 'update_date',
                header: "עדכון אחרון",
                cell: info => currentDateTime(info.getValue()),
            },
            {
                accessorKey: 'transcription_update_by_username',
                header: 'תועתק ע"י',
            },
            {
                accessorKey: 'transcription_duration',
                header: "משך תעתוק",
                cell: info => decimalToTime(info.getValue()),
            },
            {
                accessorKey: 'transcription_start_date',
                header: " התחלת תעתוק",
                cell: info => currentDateTime(info.getValue()),
            },
            {
                accessorKey: 'transcription_end_date',
                header: " סיום תעתוק",
                cell: info => `${currentDateTime(info.getValue()) || ""}`,
            },
            {
                accessorKey: 'enter_transcription',
                header: "כניסה",
                enableSorting: false,
                cell: ({ row }) => {

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="לכניסה"
                            selected={true}
                            icon={GoIcon}
                            action={() => {
                                selectAndViewAssessment(row.original)
                            }}
                        />
                    );
                }
            },
            {
                accessorKey: 'view_transcription',
                header: "צפייה",
                enableSorting: false,
                cell: ({ row }) => {

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="לצפיה"
                            icon={VisibilityIcon}
                            selected={true}
                            action={() => {
                                selectAndViewAssessment(row.original)
                            }}
                        />
                    );
                }
            },
            {
                accessorKey: 'back_to_transcription',
                header: "החזר לתעתוק",
                enableSorting: false,
                cell: ({ row }) => {

                    const disabled = loading
                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="החזר לתעתוק"
                            icon={BackToIcon}
                            selected={!disabled}
                            disabled={disabled}
                            action={() => action(row.original, true)}
                        />
                    )
                }
            }
        ], []
    )

    return columns
}

export default useColumns