import React, { useCallback, useRef, useState } from 'react'
import Dashboard from './Dashboard';
import { useQuery } from '@tanstack/react-query';
import { getDashboardFetch } from 'store/middlewares/assessment.crud';
import { alerts, queryClient as qc } from 'keys'
import { AlertCrud } from 'components/Alerts/hooks';

const DashboardPage = () => {
    const { setAlerts } = AlertCrud()

    const { data: dashboardData = {} } = useQuery({
        queryKey: [qc.dashboard],
        queryFn: async ({ signal }) => {
            const { data } = await getDashboardFetch({ signal })
            if (!data.test_taker_count)
                openTestTakerPopup()
            return data
        },
        retry: 0,
    })

    const openTestTakerPopup = useCallback(() => {
        setAlerts(alerts.createTestTaker, true)
    }, [])

    const commonState = {
        dashboardData
    }

    return (
        <DashboardContext.Provider value={commonState}>
            <Dashboard />
        </DashboardContext.Provider>
    )
}

export const DashboardContext = React.createContext()
export default DashboardPage