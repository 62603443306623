import { AbilityContext } from "components/Permissions/Can"
import { useContext } from "react"


const useAssessmentNameCell = () => {

    const assessmentNameCell = {
        accessorKey: 'reading_assessment_name',
        header: "שם האבחון"
    }

    return assessmentNameCell
}
export default useAssessmentNameCell