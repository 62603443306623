import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { WrapSetHeader, WrapSet, Title, WrapFooterBtns, WrapFooter, WrapList, WrapDroppable, WrapDragIcon, AssessmentName, WrapSectionList, WrapDraggable } from './Style'
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { CreateAssessmentsCrud } from './hooks';
import { CustomSection, SpaceBetween, XSSpaceBetween } from 'styles/GlobalStyle';
import { DragIndicator } from '@mui/icons-material';
import { Button, IconButtons } from 'components/Form';
import CreateAssessmentsData from './hooks/CreateAssessments.data';
import { createAssessmentsHooks } from '.';
import { visibleTypes } from 'keys';

const App = () => {
    const { bateryData, title, footerBtns } = CreateAssessmentsData()
    const { handleOnDragEnd, handleLayout } = CreateAssessmentsCrud()
    const { selectedAssessmentDefs } = useSelector(state => state.assessment)

    const layoutTypeBtns = createAssessmentsHooks.useLayoutTypeBtns()

    return (
        <WrapSet>
            <WrapSetHeader>
                <Title>
                    {title}
                </Title>
            </WrapSetHeader>
            <WrapSectionList>
                <CustomSection $bg $grow $flex $border>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <WrapDroppable droppableId="characters">
                            {(provided) => (
                                <WrapList className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                    {map(selectedAssessmentDefs).map(({ id, name, layoutType, layoutTypeDef, visible_type }, index) => {
                                        return (
                                            <WrapDraggable key={id.toString()} draggableId={id.toString()} index={index}>
                                                {(provided) => (
                                                    <CustomSection $flex justify="space-between" align="center" height={50} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <div className='drag-and-name'>
                                                            <WrapDragIcon>
                                                                <DragIndicator fontSize='small' color="white" />
                                                            </WrapDragIcon>
                                                            <AssessmentName>
                                                                {name}
                                                            </AssessmentName>
                                                        </div>
                                                        <XSSpaceBetween />
                                                        {
                                                            visible_type === visibleTypes.WORD_GROUP &&
                                                            <IconButtons
                                                                btns={layoutTypeBtns}
                                                                setSelected={(e, val) => handleLayout(val, index)}
                                                                selected={layoutType || layoutTypeDef}
                                                            />
                                                        }
                                                    </CustomSection>
                                                )}
                                            </WrapDraggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </WrapList>
                            )}
                        </WrapDroppable>
                    </DragDropContext>
                </CustomSection>
            </WrapSectionList>
            <WrapFooter>
                <WrapFooterBtns>
                    {footerBtns.map((btn, index) =>
                        <Button key={index} {...btn} />
                    )}
                </WrapFooterBtns>
            </WrapFooter>
        </WrapSet>
    );
}

export default App;
