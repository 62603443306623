import { useMutation } from "@tanstack/react-query";
import { logoutFetch } from "store/middlewares/auth.crud";
import { useNavigate } from "react-router-dom";
import { app } from "components/Router/routes";
import { secretStorage } from "config";
import { useDispatch } from "react-redux";
import { useCallback } from "react";

const useLogout = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { mutateAsync: logoutRQ } = useMutation({
        mutationFn: logoutFetch,
        onSettled: () => {
            navigate(app.login)
        }
    })

    const logout = useCallback(async () => {
        dispatch({ type: "RESET_ALL_REDUCERS" })
        await logoutRQ()
        secretStorage.clean()
    }, [])

    return logout
}

export default useLogout