import React, { useState } from 'react';
import { Tab, Box } from '@mui/material';
import { AbilitiesGroup, RateAndAccuracy, ratingHooks } from '.';
import { WrapTabs, Soon } from './Style'
import { dark } from 'styles/theme';
import { useTheme } from 'styles';
import { LightButton, ScrollBox } from 'styles/GlobalStyle';
import { thirdly2 } from 'styles/theme';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { getAbilitiesGroupFetch } from 'store/middlewares/assessment.crud';
import { queryClient as qc } from 'keys';

const TabComponent = (props) => {
    const { index, value, tab, data } = props

    const { Layout } = useTheme()

    return (
        <ScrollBox
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ ...Layout.grow }}
        >
            {value === index &&
                <Box>
                    <props.component {...tab} data={data} />
                </Box>
            }
        </ScrollBox>
    )
}

const ChallengesTabs = (props) => {
    const { ability_groups, category } = props

    const [value, setValue] = useState(0);

    const { Layout, Fonts } = useTheme()

    const matchingLeraningBtn = ratingHooks.useMatchingLeraningBtn()
    const { title } = ratingHooks.constants

    const abilitiesGroupOptions = queryOptions({
        queryKey: [qc.abilitiesGroup, value],
        queryFn: async () => {
            const params = {
                //אנחנו מפחיתים 1 בגלל הטאב הראשון הקבוע
                group: ability_groups[value - 1].id,
                category: category
            }
            const { data } = await getAbilitiesGroupFetch({ params })
            return data
        },
        refetchOnWindowFocus: false
    })
    const abilitiesGroup = useQuery(abilitiesGroupOptions)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ ...Layout.fullWidth, color: dark, ...Layout.grow, ...Layout.scroll, ...Layout.col }}>
            <div style={{ ...Layout.rowHCenter }}>
                <div style={{ width: '100px', ...Layout.shrink, ...Fonts.textSmall }}>{title}</div>
                <Box sx={{ ...Layout.grow, borderBottom: 1, borderColor: thirdly2, ...Layout.rowHCenter, ...Layout.justifyContentBetween, ...Layout.shrink, height: 45 }}>
                    <WrapTabs
                        value={value}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: dark,
                                fontWeight: 'bold'
                            }
                        }}
                        onChange={handleChange}
                        aria-label="basic tabs example">
                        <Tab label={"קצב ודיוק"} />
                        {ability_groups?.map((tab, index) =>
                            <Tab
                                disabled={tab.disabled}
                                label={tab.label}
                                id={`simple-tab-${index}`}
                                aria-controls={`simple-tabpanel-${index}`}
                            />
                        )}
                    </WrapTabs>
                    <LightButton {...matchingLeraningBtn} />
                </Box>
            </div>
            <TabComponent
                value={value}
                index={0}
                component={RateAndAccuracy}
            />
            {ability_groups?.map((tab, index) =>
                <TabComponent
                    value={value}
                    index={index + 1}
                    tab={tab}
                    component={AbilitiesGroup}
                    data={abilitiesGroup.data}
                />
            )}
        </Box>
    );
}

export default ChallengesTabs
