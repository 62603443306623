const alerts = {
    createAffliation: "createAffliation",
    createTestTaker: "createTestTaker",
    backTo: "backTo",
    createGrade: "createGrade",
    createClass: "createClass",
    newChild: "newChild",
    recordInstructions: "recordInstructions",
    noBalance: "noBalance",
    finishPerformAseessment: "finishPerformAseessment",
    initializeAssessment: "initializeAssessment",
    createCoupon: "createCoupon",
    uploadAudio: "uploadAudio"
}

export default alerts