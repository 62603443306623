import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Header } from './Style'
import { AdminAssessmentsData, AdminAssessmentsCrud } from './hooks'
import { Button, TabButton } from 'components/Form'
import { useSelector } from 'react-redux'
import { dataGridModel } from 'keys'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { WrapHeader } from 'styles/GlobalStyle'
import { DataGrid, Table } from 'components'
import { adminAssessmentsRQ } from 'store/hooks'
import { isEmpty, isEqual, map } from 'lodash'
import { queryClient as qc } from 'keys'
import assessmentHooks from 'components/School/Assessments/hooks';
import testTakerHooks from 'components/School/TestTakers/hooks'
import { assessmentsHooks } from 'components/School/Assessments'
import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAdminAssessmentsFetch } from 'store/middlewares/assessment.crud'
import { initialPage } from 'keys/pagination'
import { tableHooks } from 'components/Table'
import AssessmentFiltersHeader from 'components/School/Assessments/AssessmentFiltersHeader'

const AdminAssessments = (props) => {
    const { columns = [], fields = [], updateVisibilityModel = () => { }, tabs = [] } = props
    const [assessmentFilters, setAssessmentFilters] = useState({
        year: null,
        semester: null,
        assessment_def: null,
        gender: null,
        category: null,
        grade_of_allocation: null
    })
    const [statusFilters, setStatusFilters] = useState({})
    const [currentTab, setCurrentTab] = useState(0)
    const [page, setPage] = useState(initialPage)
    const [sorting, setSorting] = useState([])
    const [searchVal, setSearchVal] = useState("")
    const queryClient = useQueryClient()

    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)

    const { cleanData, setRowSelectionModel, } = AdminAssessmentsCrud()

    const idCell = tableHooks.useIdCell()
    const testTakerNameCell = tableHooks.useTestTakerNameCell()
    const assessmentNameCell = tableHooks.useAssessmentNameCell()
    const gradeOfAllocationCell = tableHooks.useGradeOfAllocationCell()
    const playCell = tableHooks.usePlayCell()

    const headerItems = assessmentsHooks.useHeaderItems()

    const adminAssessmentOptions = queryOptions({
        // refetchOnMount: false,
        // enabled: false,
        queryKey: [qc.adminAssessments, page, sorting, statusFilters, headerFilters],
        queryFn: async ({ signal }) => {
            const { data } = await getAdminAssessmentsFetch({ headerFilters, assessmentFilters, statusFilters, testTaker, signal, page, sorting, search: searchVal, model: qc.adminAssessments })
            return data
        }
    })

    const adminAssessments = useQuery(adminAssessmentOptions)

    let defaulTabIndex

    const refetchData = () => {
        adminAssessments.remove?.()
        adminAssessments.refetch()
    }

    useEffect(() => {
        if (isEmpty(statusFilters))
            defaulTabIndex = 0
        else
            defaulTabIndex = tabs.findIndex(({ status, sub_status }) => isEqual(status, statusFilters.status) && isEqual(sub_status, statusFilters.sub_status))

        if (defaulTabIndex < 0)
            defaulTabIndex = 0

        const { status, sub_status } = tabs[defaulTabIndex]
        setStatusFilters({
            status: status, sub_status: sub_status
        })
        updateVisibilityModel(tabs[defaulTabIndex])
        setCurrentTab(defaulTabIndex)
    }, [])

    useEffect(() => {

        return (() => {
            cleanData()
        })
    }, [])

    const handleTab = (index) => {
        const { status, sub_status } = tabs[index]
        setCurrentTab(index)
        setStatusFilters({
            status: status, sub_status: sub_status
        })
        updateVisibilityModel(tabs[index])
        setPage(initialPage)
        queryClient.resetQueries({ queryKey: adminAssessmentOptions.queryKey, exact: true })
    }

    let nHeaderItems = map(headerItems)
    nHeaderItems.pop()

    return (
        <WrapDataGrid>
            <AssessmentFiltersHeader
                refetchData={refetchData}
                loading={adminAssessments.isFetching}
                headerItems={nHeaderItems}
                assessmentFilters={assessmentFilters}
                setAssessmentFilters={setAssessmentFilters}
            />
            <WrapHeader>
                <Header>
                    {tabs.map((filterBtn, index) =>
                        <TabButton
                            key={index}
                            handleTab={() => handleTab(index)}
                            selected={currentTab === index}
                            {...filterBtn}
                        />
                    )}
                </Header>
            </WrapHeader>
            <Table
                withTitle
                withTabs={true}
                loading={adminAssessments.isFetching && !adminAssessments.data}
                data={adminAssessments?.data}
                columns={[idCell, assessmentNameCell, testTakerNameCell, gradeOfAllocationCell, ...columns, playCell]}
                page={page}
                setPage={setPage}
                visibilityModelKey={dataGridModel.admin_assessments}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                sorting={sorting}
                setSorting={setSorting}
                queryKey={adminAssessmentOptions.queryKey}
            />
        </WrapDataGrid>
    )
}

export default AdminAssessments