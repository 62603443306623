import { assessmentsHooks } from 'components/School/Assessments';


const useHeaderItems = () => {
    const header = assessmentsHooks.useHeaderItems()

    const headerItems = [
        {
            ...header.year,
            disabled: true,

        },
        {
            ...header.semester,
            disabled: true,
        },
        header.category,
        header.assessment_def
    ]

    return headerItems
}

export default useHeaderItems