import { ratingList } from "keys"


const constants = {
    graphViews: {
        graph: "graph",
        table: "table"
    },
    types: {
        percent_accuracy: "percent_accuracy",
        factored_percent_accuracy: "factored_percent_accuracy",
        num_words_per_minute: "num_words_per_minute",
        repetition_percent: "repetition_percent",
        self_correction_percent: "self_correction_percent",
        minor_error_percent: "minor_error_percent",
        self_correction_and_minor_error_percent: "self_correction_and_minor_error_percent",
        correct_words_per_minute: "correct_words_per_minute"
    },
    percent: "אחוז",
    count: "כמות",
    range: "טווח",
    frequency: [

        {
            label: "גבוהה",
            color: ratingList[0]
        },
        {
            label: "טובה",
            color: ratingList[1]
        },
        {
            label: "קצת נמוכה",
            color: ratingList[2]
        },
        {
            label: "נמוכה",
            color: ratingList[3]
        },
        {
            label: "נמוכה מאד",
            color: ratingList[4]
        },
        {
            label: "אין נתונים",
            color: ratingList[5]
        },
    ]
}

export default constants