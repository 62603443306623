import React from 'react'
import { useSelector } from 'react-redux'
import { NoBalance } from 'components/Payments'
import { CreateTestTaker, NewChild, CreateAffliation, CreateGrade, CreateClass } from 'components'
import { alerts as alertKeys } from 'keys'
import { BackToConfirmation } from 'components/Admin/AdminAssessments'
import { AssessmentCompletionAlert, RecordInstructions } from 'components/School/MakingRecordAssessments'
import { InitializeConfirmation } from 'components/Admin/MarkingWords'
import { CreateCoupon } from 'components/Coupon'
import { UploadAudio } from 'components/School/UploadAudio'

const WrapAlert = () => {
    const { alerts } = useSelector(state => state.general)

    return (
        <>
            {
                alerts[alertKeys.createCoupon].open &&
                <CreateCoupon />
            }
            {
                alerts[alertKeys.createAffliation].open &&
                <CreateAffliation />
            }
            {
                alerts[alertKeys.createTestTaker].open &&
                <CreateTestTaker />
            }
            {
                alerts[alertKeys.createGrade].open &&
                <CreateGrade />
            }
            {
                alerts[alertKeys.createClass].open &&
                <CreateClass />
            }
            {
                alerts[alertKeys.initializeAssessment].open &&
                <InitializeConfirmation />
            }
            {
                alerts[alertKeys.backTo].open &&
                <BackToConfirmation />
            }
            {
                alerts[alertKeys.recordInstructions].open &&
                <RecordInstructions />
            }
            {
                alerts[alertKeys.uploadAudio].open &&
                <UploadAudio />
            }
            {
                alerts[alertKeys.noBalance].open &&
                <NoBalance />
            }
            {
                alerts[alertKeys.finishPerformAseessment].open &&
                <AssessmentCompletionAlert />
            }
        </>
    )
}

export default WrapAlert