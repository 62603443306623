import { Box, Button } from "@mui/material";
import { lightBlue2 } from "styles/theme";
import { lightPrimary } from "styles/theme";
import { Header as HeaderDataGrid } from 'components/DataGrid/Style'
import { styled as muiStyled } from '@mui/material/styles';
import styled, { css } from "styled-components";
import { Scroll } from "styles/GlobalCss";

const headercss = css`
grid-template-columns:  120px 120px 120px 100px auto 130px 130px;
`

const WrapBasicButton = styled(Button)`
box-shadow: none !important;
border:1px solid ${lightPrimary} !important;
`

const WrapFullButton = styled(Button)`
box-shadow: none !important;
border:1px solid ${lightBlue2} !important;
`

const IndicationLabel = styled.div`
color:${props => props.color};
`


const WrapIndication = styled.div`
.MuiSvgIcon{
    color:pink !important
}
`

const RateAndAccuracyHeaderStyles = muiStyled(Box)(({ theme, $systemUI }) => ({
    display: "flex",
    alignItems: "center",
    columnGap: "5px"
}));


const Header = styled(HeaderDataGrid)`
width:100%;
display: flex;
overflow-y:hidden;
overflow-x:auto;
row-gap: 10px;
justify-content: flex-start;
padding:15px 0px;
${props => props.headercss && props.headercss};
${Scroll};
`

const WrapFiltersHeader = styled.div`
display:flex;
align-items:center;
height:${props => props.height ? `${props.height}px` : "70px"};
`

export { Header, WrapFiltersHeader, RateAndAccuracyHeaderStyles, IndicationLabel, WrapIndication, headercss, WrapBasicButton, WrapFullButton }