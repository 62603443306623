import React, { useContext } from 'react'
import { DashboardStyles, GeneralDashboardStyles } from './Style'
import './style.css'
import { General, Student } from '.'
import { Can } from 'components/Permissions'
import { AbilityContext } from 'components/Permissions/Can'

const DashboardPage = () => {
    const ability = useContext(AbilityContext)


    return (
        <GeneralDashboardStyles>
            {ability.can("read", "general-dashboard") ?
                <General />
                :
                ability.can("read", "student-dashboard") &&
                <Student />
            }
        </GeneralDashboardStyles>
    )
}

export default DashboardPage