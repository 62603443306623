import { useCallback, useContext, useMemo } from "react";
import { tableHooks } from "components/Table";
import { RateAndAccuracy, RateAndAccuracyHeader, RateAndAccuracyLabel, RateAndAccuracyValue, StatusCell } from "..";
import { percent, timeAgoWithOutSeconds } from "utils/formatter";
import { AssessmentsContext } from "../AssessmentsContext";
import { IconButtonGroup } from "components/Form";
import { ReactComponent as VisibilityDisabledIcon } from 'assets/icons/VisibilityDisabled.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import { useQueryClient } from "@tanstack/react-query";
import { queryClient as qc, statuses } from 'keys'
import { filter, map } from "lodash";
import { actions } from "store";
import { useDispatch } from "react-redux";
import { app } from "components/Router/routes";
import { useNavigate } from "react-router-dom";

const useColumns = () => {
    const idCell = tableHooks.useIdCell()
    const testTakerNameCell = tableHooks.useTestTakerNameCell()
    const gradeOfAllocationCell = tableHooks.useGradeOfAllocationCell()
    const playCell = tableHooks.usePlayCell()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { viewPercent } = useContext(AssessmentsContext)
    const queryClient = useQueryClient()

    const goAssessment = (item) => {
        const { id } = item
        const assessments = queryClient.getQueryData([qc.report])?.data
        const assessmentIds = map(filter(assessments, { status: statuses.inProgress, sub_status: statuses.wordsComplete }), 'id');
        dispatch(actions.setAssessmentIds(assessmentIds))
        navigate(`${app.assessments}/${id}`, {
            state: {
                id: id
            }
        })
    }

    const columns = useMemo(
        () => [
            idCell,
            testTakerNameCell,
            {
                accessorKey: 'reading_assessment_name',
                header: "שם אבחון",
            },
            {
                accessorKey: 'reading_assessment_category_name',
                header: "סוג אבחון",
            },
            {
                accessorKey: 'sub_status',
                header: "סטטוס תוצאות",
                cell: StatusCell,
                enableSorting: false
            },
            {
                accessorKey: 'file_uploaded_date',
                header: "מועד ביצוע",
                cell: info => timeAgoWithOutSeconds(info.getValue())
            },
            gradeOfAllocationCell,
            {
                //sort
                accessorKey: 'fluency',
                header: RateAndAccuracyHeader,
                cell: viewPercent.fluency ? RateAndAccuracyLabel : RateAndAccuracy,
                meta: {
                    id: "fluency_rating_id",
                    index: "fluency_rating_index",
                    label: "קצב"
                }
            },
            {
                accessorKey: 'rating.num_words_per_minute',
                header: "מילים לדקה",
                cell: RateAndAccuracyValue,
                meta: {
                    field: "num_words_per_minute",
                    index: "fluency_rating_index",
                    precent: false
                }
            },
            {
                //sort
                accessorKey: 'accuracy',
                header: RateAndAccuracyHeader,
                enableSorting: false,
                cell: viewPercent.accuracy ? RateAndAccuracyLabel : RateAndAccuracy,
                meta: {
                    id: "factored_percent_accuracy_rating_id",
                    index: "factored_percent_accuracy_rating_index",
                    label: "דיוק משוקלל",
                }
            },
            {
                accessorKey: 'percent_accuracy',
                header: "אחוז דיוק",
                enableSorting: false,
                cell: RateAndAccuracyValue,
                meta: {
                    field: "percent_accuracy",
                    index: "percent_accuracy_rating_index",
                    precent: true
                }
                // sort: (sortModel) => sortNumbers(sortModel, "percent_accuracy")
            },
            {
                accessorKey: 'factored_percent_accuracy',
                header: "אחוז דיוק משוקלל",
                cell: RateAndAccuracyValue,
                meta: {
                    field: "factored_percent_accuracy",
                    index: "factored_percent_accuracy_rating_index",
                    precent: true
                }
                // sort: (sortModel) => sortNumbers(sortModel, "factored_percent_accuracy")
            },
            {
                accessorKey: 'rating.error_percent',
                header: " טעויות",
                cell: info => percent(info.getValue())
            },
            {
                accessorKey: 'rating.repetition_percent',
                header: " חזרות",
                cell: info => percent(info.getValue())
            },
            {
                accessorKey: 'rating.insertion_percent',
                header: "הוספות",
                cell: info => percent(info.getValue()),
            },
            {
                accessorKey: 'rating.omission_error_and_minor_error_percent',
                header: "השמטות",
                // enableSorting: false,
                cell: info => percent(info.getValue()),
            },
            {
                accessorKey: 'rating.self_correction_percent',
                header: " תקון עצמי",
                cell: info => percent(info.getValue()),
            },
            {
                accessorKey: 'rating.minor_error_percent',
                header: " שגיאה קלה",
                cell: info => percent(info.getValue()),
            },
            {
                accessorKey: 'rating.self_correction_and_minor_error_percent',
                header: " שגיאה קלה עם תקון עצמי",
                cell: info => percent(info.getValue()),
            },
            playCell,
            {
                accessorKey: 'reports',
                header: "צפיה בדו”ח",
                enableSorting: false,
                cell: ({ row }) => {
                    const { rating, id } = row.original || {}

                    const disabled = !rating?.percent_accuracy_rating_id || !rating?.fluency_rating_id

                    return [
                        <IconButtonGroup
                            key={row.id}
                            color="bg"
                            tooltip="לצפיה"
                            icon={disabled ? VisibilityDisabledIcon : VisibilityIcon}
                            selected={!disabled}
                            disabled={disabled}
                            action={() => {
                                goAssessment({ ...row.original, id: row.original.id })
                            }}
                        />
                    ];
                }
            }
        ], [viewPercent]
    )

    return columns
}

export default useColumns