import { Box, SpeedDialAction } from "@mui/material";
import { styled as muiStyled } from '@mui/material/styles';
import styled from "styled-components";
import { CenterStyle } from "styles/GlobalCss";
import { gTheme } from "styles/theme";
import { black } from "styles/theme";


const WrapLevels = styled.div`
display: flex;
row-gap: 8px;
flex-direction: column;
color: white;
width:100%
`

const Title = styled.div`
color:black;
font-size:18px;
`

const Level = styled.div`
width:100%;
box-sizing:border-box;
background:${props => props.color};
border-radius: 2px;
padding:5px;
font-weight:400;
font-size:15px;
`

const DialAction = muiStyled(SpeedDialAction)(({ theme, color, borderStyle = "solid", borderColor }) => ({
    width: '170px',
    height: '30px',
    borderRadius: 8,
    color: black,
    boxShadow: "none",
    borderWidth: 2,
    borderColor: color,
    borderStyle: "solid",
    fontWeight: 400,
    background: "transparent"


    // width:100%;
    // // box-sizing:border-box;
    // background:${props => props.color};
    // border-radius: 2px;
    // padding:5px;
    // font-weight:400;
    // font-size:15px;
}));


const LevelListStyles = muiStyled(Box)(({ theme, color }) => ({
    borderRadius: 5,
    border: "1px solid white",
    padding: 30,
    display: "flex",
    rowGap: 10,
    flexDirection: 'column',
    '.list-title': {
        color: theme.dark,
        display: "flex",
        alignItems: "center",
        columnGap: 15
    },
    '.rating-item': {
        display: "flex",
        alignItems: "center",
        columnGap: 15
    }
}));

const RatingDot = muiStyled(Box)(({ theme, color }) => ({
    width: 10,
    height: 10,
    borderRadius: 30,
    background: color
}));

const RatingLabel = muiStyled(Box)(({ theme, color }) => ({
    color: color
}));


const RatingStyles = muiStyled(Box)(({ color, theme }) => ({
    width: 150,
    height: 30,
    color: gTheme[color].main,
    border: `2px solid ${gTheme[color].main}`,
    borderRadius: 6,
    ...CenterStyle
}));


export { RatingLabel, RatingDot, LevelListStyles, RatingStyles, DialAction, Title, WrapLevels, Level }