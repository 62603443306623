import React, { useContext, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { ReportContext } from "../ReportContext"
import { RateAndAccuracyDescription } from "../Style"
import { useTheme } from "styles"
import { Chip, Tooltip } from "components/Form"
import { Top } from "styles/GlobalStyle"


const useRateAndAccuracyItems = () => {
    const {
        scoreRating, factoredAccuracyDistribution, accuracyDistribution, fluencyDistribution, factoredAccuracyStats, accuracyStats, fluencyStats,
        refetchAccuracyStats, refetchFactoredAccuracyStats, refetchFluencyStats
    } = useContext(ReportContext)

    const { rating } = useSelector(state => state.rating)
    const { Layout, Common } = useTheme()
    const { percent_accuracy = "?", num_words_per_minute, factored_percent_accuracy, factored_percent_accuracy_rating_id, percent_accuracy_rating_id, correct_words_per_minute = "?", fluency_rating_id } = rating || {}

    const { rating: accuracy_rating, f_rating, color: accuracyColor, bg: accuracyBg } = scoreRating[percent_accuracy_rating_id] || {}
    const { rating: factored_rating, color: scColor, bg: scBg } = scoreRating[factored_percent_accuracy_rating_id] || {}
    const { percent_accuracy_rating, color: rateColor, bg: rateBg } = scoreRating[fluency_rating_id] || {}


    const rateAndAccuracyItems = useMemo(
        () => [
            {
                label: "קצב",
                type: "fluency",
                text: () =>
                    <React.Fragment>
                        <RateAndAccuracyDescription>
                            מילים לדקה
                        </RateAndAccuracyDescription>
                        {num_words_per_minute && percent_accuracy_rating &&
                            <Chip borderColor={rateColor} bg={rateBg}>
                                {` ${num_words_per_minute} מילים - ${percent_accuracy_rating} `}
                            </Chip>}
                    </React.Fragment>,
                color: rateColor,
                percent: false,
                expand: true,
                showGraphBtns: true,
                stats: fluencyStats,
                graph: fluencyDistribution,
                refetch: refetchFluencyStats
            },
            {
                label: "דיוק",
                type: "factored_accuracy",
                graph: "graph",
                text: () =>
                    <React.Fragment>
                        <RateAndAccuracyDescription style={{ ...Layout.flex }}>
                            אחוז דיוק משוקלל
                            <Top top={-7}>
                                <Tooltip title="שגיאות שאינן פוגמות בהבנת הטקסט כמו מילים עם היסוס, חזרה, תיקון עצמי ושגיאה קלה כמו השמטת דגש, משוקללות כמילים קבילות" />
                            </Top>
                        </RateAndAccuracyDescription>

                        {factored_percent_accuracy && factored_rating &&
                            <Chip borderColor={scColor} bg={scBg}>
                                {`  ${factored_percent_accuracy}% - ${factored_rating}`}
                            </Chip>}
                    </React.Fragment>
                ,
                percent: true,
                expand: true,
                showGraphBtns: true,
                stats: factoredAccuracyStats,
                graph: factoredAccuracyDistribution,
                refetch: refetchFactoredAccuracyStats
            },
            {
                label: "דיוק",
                type: "accuracy",
                graph: "graph",
                text: () =>
                    <React.Fragment>
                        <RateAndAccuracyDescription>
                            אחוז דיוק לא משוקלל
                        </RateAndAccuracyDescription>
                        {percent_accuracy && accuracy_rating &&
                            <Chip borderColor={accuracyColor} bg={accuracyBg}>
                                {` ${percent_accuracy}% - ${accuracy_rating}`}
                            </Chip>}
                    </React.Fragment >
                ,
                percent: true,
                expand: true,
                showGraphBtns: true,
                stats: accuracyStats,
                graph: accuracyDistribution,
                refetch: refetchAccuracyStats
            },
            // {
            //     label: "שטף",
            //     type: "accuracy",
            //     text: () =>
            //         <React.Fragment>
            //             <RateAndAccuracyDescription>
            //                 אופן הקריאה
            //             </RateAndAccuracyDescription>
            //             <BoldSummary color={accuracyColor}>
            //                 {" ??"}
            //             </BoldSummary>
            //         </React.Fragment>,
            // },
            {
                label: "",
                type: "correct_words_per_minute",
                text: () =>
                    <div>
                        {`  מילים נכונות לדקה - ${correct_words_per_minute} מילים`}
                    </div>,
            }
        ], [rating, scoreRating, factoredAccuracyDistribution, accuracyDistribution, fluencyDistribution, factoredAccuracyStats, accuracyStats, fluencyStats]
    )

    return rateAndAccuracyItems
}

export default useRateAndAccuracyItems
