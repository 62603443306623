import React from 'react'
import { HelpOutline, QuestionMark } from '@mui/icons-material'
import { Tooltip } from 'components/Form'

const LabelWithTooltip = () =>
    <React.Fragment>
        משוקלל
        <div style={{ position: "relative", right: "4px", bottom: "15px" }}>
            <Tooltip title=" שגיאות שאינן פוגמות בהבנת הטקסט כמו מילים עם היסוס, חזרה, תיקון עצמי ושגיאה קלה כמו השמטת דגש, משוקללות כמילים קבילות" />
        </div>
    </React.Fragment>

const useTabs = () => {

    return [
        {
            id: 0,
            label: <LabelWithTooltip />,
            width: "80px"
        },
        {
            id: 1,
            label: "לא משוקלל",
        }
    ]

}



export default useTabs
