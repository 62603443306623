import React, { useCallback, useState } from 'react'
import { Error, IconButton } from 'components/Form'
import { Label } from 'components/Form'
import { WrapField, BootstrapInput2, WrapFieldContent } from './Styled'
import './style.css'
import { InputAdornment, SvgIcon, TextField } from '@mui/material'
import { ReactComponent as Visibility } from 'assets/icons/Visibility.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/VisibilityOff.svg';

//Render input field
const Input = (props) => {
    const { border, onKeyDown, rows = 1, autocomplete, endAdornment, required, label, name, input, type, placeholder, className, gridArea, arrayIndex, mandatory } = props
        ,
        { onChange, value = null } = input

    const [showPassword, setShowPassword] = useState(type === 'password')
    const [fieldHasValue, setFieldHasValue] = useState(false)

    const makeAnimationStartHandler = (stateSetter) => (e) => {
        const autofilled = !!e.target?.matches("*:-webkit-autofill");
        if (e.animationName === "mui-auto-fill") {
            stateSetter(autofilled);
        }

        if (e.animationName === "mui-auto-fill-cancel") {
            stateSetter(autofilled);
        }
    }


    const _onChange = useCallback((e) => {
        onChange(e.target.value);
        setFieldHasValue(e.target.value !== "")
    }, [onChange])

    return (
        <WrapField className={className} style={arrayIndex ? { gridArea: `${gridArea}_${arrayIndex}` } : {}}>
            <WrapFieldContent>
                {label && <Label label={label} mandatory={mandatory} />}
                <BootstrapInput2
                    onKeyDown={onKeyDown}
                    id="filled-basic"
                    size='small'
                    border={border}
                    name={name}
                    color="thirdly"
                    value={value}
                    autocomplete={autocomplete}
                    type={showPassword ? "password" : "text"}
                    onChange={_onChange}
                    label={`${required ? "*" : ""}${placeholder}`}
                    multiline={rows > 1}
                    rows={rows}
                    variant="filled"
                    inputProps={{
                        // onAnimationStart: makeAnimationStartHandler(setFieldHasValue),
                        autocomplete: autocomplete,

                    }}
                    // InputLabelProps={{
                    //     shrink: fieldHasValue,
                    // }}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            endAdornment ?
                                <InputAdornment position="end">
                                    <endAdornment.component {...props} />
                                </InputAdornment> :
                                type != 'password' ||
                                <InputAdornment position="end">
                                    <IconButton
                                        hideFill
                                        aria-label="toggle password visibility"
                                        action={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        icon={
                                            <SvgIcon fontSize="small" component={showPassword ? Visibility : VisibilityOff} color="black" inheritViewBox />
                                        }
                                    />
                                </InputAdornment>
                        )
                    }}
                // hiddenlabel="true"
                // placeholder={placeholder}
                />
            </WrapFieldContent>
            <Error {...props} />
        </WrapField>
    )
}

export default Input