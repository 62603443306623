import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { actions } from 'store'

const useOnPlayPause = () => {
    const dispatch = useDispatch()
    const { wavesurfer, isPlaying } = useSelector(state => state.wavesurfer)

    const setIsPlaying = (value) => {
        dispatch(actions.setIsPlaying(value))
    }

    const playPause = () => {
        if (wavesurfer)
            wavesurfer.playPause()
    }

    const onPlayPause = useCallback(() => {
        setIsPlaying(!isPlaying)
        playPause()
    }, [isPlaying, wavesurfer])

    return onPlayPause
}

export default useOnPlayPause 