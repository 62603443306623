import { ReactComponent as ChildrenIcon } from 'assets/icons/Children.svg';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ReactComponent as TableIcon } from 'assets/icons/Table.svg';
import { useMemo } from 'react';

const useScoreRatingBtns = () => {

    const scoreRatingBtns = useMemo(
        () => [
            {
                key: "table",
                icon: TableIcon,
                tooltip: "",
                placement: "top",
                action: () => { },
                iconSize: {
                    width: 20,
                    height: 20
                }
            },
            {
                key: "all",
                icon: ChildrenIcon,
                tooltip: "",
                placement: "top",
                action: () => { },
                iconSize: {
                    width: 20,
                    height: 20
                }
            },
            {
                key: "edit",
                icon: EditIcon,
                placement: "top",
                tooltip: "עריכה",
                action: () => { },
                iconSize: {
                    width: 20,
                    height: 20
                }
            },
        ], []
    )
    return scoreRatingBtns

}

export default useScoreRatingBtns
