import React, { useEffect } from 'react'
import { Alert } from 'components'
import { Go2 } from 'assets'
import { WrapSuccessCartoon, AssessmentCompletionAlertStyles } from './Style'
import { useSelector } from 'react-redux'
import { Img } from 'styles/GlobalStyle'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { alerts } from 'keys'
import { constants } from './hooks'

const AssessmentCompletionAlert = () => {
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const { finish } = constants.assessmentCompletionAlert

    const handleAlert = (val) => {
        dispatch(actions.setAlerts({ key: alerts.finishPerformAseessment, value: { open: val } }))
    }

    useEffect(() => {
        setTimeout(() => {
            handleAlert(false)
        }, 5000);
    }, [])

    return (
        <Alert systemUI
            handleAlert={handleAlert}>
            <AssessmentCompletionAlertStyles>
                <div>
                    <div>
                        כָּל הַכָּבוֹד!
                    </div>
                    <div>
                        {`${finish[user?.gender]} מְשִׂימָה!`}
                    </div>
                </div>
                <WrapSuccessCartoon>
                    <Img src={Go2} />
                </WrapSuccessCartoon>
            </AssessmentCompletionAlertStyles>
        </Alert>
    )
}

export default AssessmentCompletionAlert