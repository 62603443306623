import React from 'react'
// import AssessmentRecordFooter from 'components/School/CreateAssessmentRecord/AssessmentRecordFooter'
import AssessmentHeader from 'components/School/Assessment/AssessmentHeader';

const componentKeys = {
    recordFooter: "recordFooter",
    recordHeader: "recordHeader",
    assessmentHeader: "assessmentHeader",
}

const components = {
    // [componentKeys.recordFooter]: <AssessmentRecordFooter />,
    [componentKeys.assessmentHeader]: <AssessmentHeader />,
}

export { componentKeys }
export default components