import { useDispatch, useSelector } from "react-redux"
import { orderBy } from "lodash"
import { useNavigate } from "react-router-dom"
import { actions } from 'store'
import { alerts, dataGridModel } from "keys"
import { AlertCrud } from "components/Alerts/hooks"
import { app } from "components/Router/routes"
import api from "keys/apis"
import { useQueryClient } from "@tanstack/react-query"
import { queryClient as qc } from "keys"
import { useCallback } from "react"

const TestTakersCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { setAlerts } = AlertCrud()
    const { selectedTestTakers } = useSelector(state => state.testTaker)
    const { controllers } = useSelector(state => state.general)
    const queryClient = useQueryClient()

    const getTestTakers = async () => {
        try {
            const testTakers = await dispatch(actions.getWithLastAssessment())
            dispatch(actions.setTestTakerWithLastAssessment(testTakers.map((item, index) => ({ index, ...item }))))
        }
        catch {
            console.log("error getTestTakers")
        }
    }

    const cleanData = () => {
        abortApis()
        dispatch(actions.setTestTakerWithLastAssessment(null))
    }

    const abortApis = () => {
        controllers[api.get_with_last_assessment]()
    }

    const updateMasterDetails = (val) => {
        dispatch(actions.setVisibilityModelByKeys({
            key: [dataGridModel.last_assessments],
            value: {
                affiliation_name: val,
                grade_name: val,
                t_class_name: val
            }
        }))
    }

    const addNewGrade = useCallback(() => {
        setAlerts(alerts.createGrade, true)
    }, [])

    const addNewClass = useCallback(() => {
        setAlerts(alerts.createClass, true)
    }, [])

    const goAssessments = () => {
        navigate(app.assessments)
    }

    const setRowSelectionModel = (newRowSelectionModel) => {
        dispatch(actions.setSelectedTestTakers(newRowSelectionModel))
    }



    return {
        getTestTakers,
        cleanData,
        setRowSelectionModel,
        updateMasterDetails,
        goAssessments,
        addNewGrade,
        addNewClass
    }
}

export default TestTakersCrud