import { IconButtonGroup } from 'components/Form';
import { Edit } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { alerts } from 'keys';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { useCallback, useState } from 'react';
import { TestTakerVM } from 'models/VM';
import { app } from 'components/Router/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as GoIcon } from 'assets/icons/Go.svg';
import { ReactComponent as PlusDisabledIcon } from 'assets/icons/PlusDisabled.svg';
import { assessmentRQ } from 'store/hooks';
import testTakerHooks from '.'

const useTestTakerFields = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const nameField = testTakerHooks.useNameField()
    const { mutateAsync: bulkCreateByLevel, isPending: bulkCreateByLevelLoading } = assessmentRQ.useBulkCreateByLevel()

    const updateOrCreateTestTaker = useCallback((test_taker) => {
        dispatch(actions.setAlerts(
            {
                key: alerts.createTestTaker,
                value: { open: true, props: test_taker }
            }))
    }, [])

    const fields = [
        nameField.field,
        {
            field: 'tz',
            headerName: "תעודת זהות",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 'affiliation_name',
            headerName: "בית ספר",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 'grade_at_registration_name',
            headerName: "שכבה בעת ההרשמה",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 'grade_name',
            headerName: "שכבה נכחית",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 't_class_name',
            headerName: "כיתה",
            headerAlign: 'start',
            align: "start",
            width: 100,
        },
        {
            field: 'new_assessment',
            type: 'actions',
            headerName: "התאמת אבחון",
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <IconButtonGroup
                        color={"bg"}
                        tooltip={row.last_assessment ? "התאימו לי אבחון נוסף" : "התאימו לי אבחון"}
                        selected={!row.last_assessment}
                        icon={row.last_assessment ? PlusDisabledIcon : GoIcon}
                        disabled={row.last_assessment}
                        action={async () => {
                            await bulkCreateByLevel(row.test_taker_id)
                            if (pathname != app.dashboard)
                                navigate(app.allAssessments)
                        }}
                    />
                ];
            }
        },
        {
            field: 'edit',
            type: 'actions',
            headerName: "עריכה",
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<Edit color='dark' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            const testTaker = new TestTakerVM(row)
                            updateOrCreateTestTaker(testTaker)
                        }}
                        color="dark"
                    />
                ];
            }
        }
    ]

    return fields
}

export default useTestTakerFields
