import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AllAssessmentsCrud } from './hooks';
import { dataGridModel, queryClient as qc } from 'keys';
import { actions } from 'store';
import { assessmentUtils } from 'helpers';
import { useDispatch } from 'react-redux';
import { performAssessmentsHooks } from '.';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { getAssessmentsFetch } from 'store/middlewares/assessment.crud';
import { initialPage } from 'keys/pagination';
import { Table } from 'components';
import { WrapDataGrid } from 'components/DataGrid/Style';
import { isEmpty } from 'lodash';
import AssessmentFiltersHeader from 'components/School/Assessments/AssessmentFiltersHeader';

const PerformAssessments = () => {
    const dispatch = useDispatch()
    const [assessmentFilters, setAssessmentFilters] = useState({
        semester: assessmentUtils.getCurrentSemester(),
        year: new Date().getFullYear(),
        assessment_def: null,
        category: null,
        grade_of_allocation: null
    })
    const { headerFilters } = useSelector(state => state.header)
    const { testTaker } = useSelector(state => state.testTaker)

    const [page, setPage] = useState(initialPage)
    const [sorting, setSorting] = useState([])
    const [searchVal, setSearchVal] = useState("")

    const headerItems = performAssessmentsHooks.useHeaderItems()
    const columns = performAssessmentsHooks.useColumns()

    // const {
    //     cleanData,
    // } = AllAssessmentsCrud()

    const assessmentOptions = queryOptions({
        queryKey: [qc.assessments, page, sorting, headerFilters, testTaker],
        queryFn: async ({ signal }) => {
            const { data } = await getAssessmentsFetch({ assessmentFilters, headerFilters, testTaker, signal, page, sorting, search: searchVal, model: qc.assessments })
            const allAssessments = data && Object.values?.(
                data.results.reduce((acc, obj) => {
                    acc[obj.id] = { ...obj, assessment_count: 1, assessment_group: [obj] };
                    return acc;
                }, {})
            );
            return { ...data, results: allAssessments }
        },
        enabled: !!(assessmentFilters?.year && assessmentFilters?.semester)
    })

    const assessments = useQuery(assessmentOptions)

    const refetchData = () => {
        // assessments.remove?.()
        assessments.refetch()
    }

    // useEffect(() => {
    //     refetchData()
    // }, [
    //     headerFilters,
    //     testTaker,
    //     assessmentFilters?.year,//const value
    //     assessmentFilters?.semester//const value
    // ])

    // useEffect(() => {
    //     dispatch(actions.updateAssessmentFilters({
    //         semester: assessmentUtils.getCurrentSemester(),
    //         year: new Date().getFullYear()
    //     }))
    //     return (() => {
    //         cleanData()
    //     })
    // }, [])

    return (
        <WrapDataGrid>
            <AssessmentFiltersHeader
                refetchData={refetchData}
                headerItems={headerItems}
                loading={assessments.isFetching}
                assessmentFilters={assessmentFilters}
                setAssessmentFilters={setAssessmentFilters}
            />
            <Table
                withTitle
                loading={assessments.isFetching || isEmpty(assessments.data)}
                data={assessments.data}
                columns={columns}
                page={page}
                setPage={setPage}
                visibilityModelKey={dataGridModel.all_assessments}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                sorting={sorting}
                setSorting={setSorting}
                queryKey={assessmentOptions.queryKey}
            />
        </WrapDataGrid>
    );
}

export default PerformAssessments


//group assessments
// const firstAssessments = assessments?.data && Object.values?.(
//     assessments?.data.results.reduce((acc, obj) => {
//         if (!acc[obj.test_taker_id]) {
//             acc[obj.test_taker_id] = { ...obj, assessment_count: 1, assessment_group: [obj] };
//         } else {
//             acc[obj.test_taker_id].assessment_count += 1;
//             acc[obj.test_taker_id].assessment_group.push(obj)
//         }
//         return acc;
//     }, {})
// );

// const allAssessments = assessments?.data && Object.values?.(
//     assessments?.data.results.reduce((acc, obj) => {
//         acc[obj.id] = { ...obj, assessment_count: 1, assessment_group: [obj] };
//         return acc;
//     }, {})
// );
