import { IconButtonGroup } from "components/Form";
import { AbilityContext } from "components/Permissions/Can";
import { useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as GoIcon } from 'assets/icons/Go.svg';
import { ReactComponent as PlusDisabledIcon } from 'assets/icons/PlusDisabled.svg';
import { assessmentRQ } from "store/hooks";
import { app } from "components/Router/routes";
import { Edit } from "@mui/icons-material";
import { TestTakerVM } from "models/VM";
import { useDispatch } from "react-redux";
import { actions } from "store";
import { alerts } from "keys";
import { IconButton } from "@mui/material";
import { tableHooks } from "components/Table";

const useColumns = () => {
    const ability = useContext(AbilityContext)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const testTakerNameCell = tableHooks.useTestTakerNameCell()
    const idCell = tableHooks.useIdCell()

    const { pathname } = useLocation()

    const student = ability.can("read", "student-label") ? "תלמיד" : "ילד"

    const { mutateAsync: bulkCreateByLevel, isPending: bulkCreateByLevelLoading } = assessmentRQ.useBulkCreateByLevel()

    const updateOrCreateTestTaker = useCallback((test_taker) => {
        dispatch(actions.setAlerts(
            {
                key: alerts.createTestTaker,
                value: { open: true, props: test_taker }
            }))
    }, [])

    const columns = useMemo(
        () => [
            {
                ...idCell,
                accessorKey: "test_taker_id"
            },
            testTakerNameCell,
            // {
            //     accessorKey: 'tz',
            //     header: "תעודת זהות",
            //     enableSorting: false
            // },
            {
                accessorKey: 'affiliation_name',
                header: "בית ספר",
            },
            {
                accessorKey: 'grade_at_registration_name',
                header: "שכבה בעת ההרשמה",
            },
            {
                accessorKey: 'grade_name',
                header: "שכבה נכחית",
            },
            {
                accessorKey: 't_class_name',
                header: "כיתה",
            },
            {
                accessorKey: 'new_assessment',
                header: "התאמת אבחון",
                enableSorting: false,
                cell: ({ row }) => {
                    const { last_assessment, test_taker_id } = row.original;

                    return (
                        <IconButtonGroup
                            color={"bg"}
                            tooltip={last_assessment ? "התאימו לי אבחון נוסף" : "התאימו לי אבחון"}
                            selected={!last_assessment}
                            icon={last_assessment ? PlusDisabledIcon : GoIcon}
                            disabled={last_assessment}
                            action={async () => {
                                await bulkCreateByLevel(test_taker_id);
                                if (pathname !== app.dashboard)
                                    navigate(app.allAssessments);
                            }}
                        />
                    );
                }
            },
            {
                accessorKey: 'edit',
                header: "עריכה",
                enableSorting: false,
                cell: ({ row }) => {
                    return (
                        <IconButton
                            label="Edit"
                            className="textPrimary"
                            onClick={() => {
                                const testTaker = new TestTakerVM(row.original);
                                updateOrCreateTestTaker(testTaker);
                            }}
                        >
                            <Edit color='dark' />
                        </IconButton>
                    );
                }
            }
        ], []
    )

    return columns
}

export default useColumns