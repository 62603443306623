import { layoutTypes } from "keys"

class Battery {

    constructor(battery) {
        const { assessment_defs, test_takers } = battery
        this.assessment_defs = getAssessmentDefs(assessment_defs)
        this.test_takers = test_takers

    }

}

export default Battery

const getAssessmentDefs = (assessmentDefs) => {
    const assessments = assessmentDefs.map((ass) => ({
        id: ass.id,
        layout_type: ass.layoutType || ass.layoutTypeDef
    }))
    return assessments
}