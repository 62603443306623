import React, { useCallback, useContext, useEffect } from 'react'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { NormalizerContext } from './NormalizerContext'
import { assessmentsHooks } from 'components/School/Assessments'
import { NormalizerStyles } from './Style'
import { FixedSizeList as List } from 'react-window';
import { Divider } from '@mui/material'
import { thirdly2 } from 'styles/theme'
import { LightButton, Space } from 'styles/GlobalStyle'
import { AutoComplete } from 'components/Form'
import { ApplyDataConfirmation, normalizerHooks } from '.'
import { changeItem } from 'utils/array'
import { ScoreRatingItem } from '.'
import { useQueryClient } from '@tanstack/react-query'
import { queryClient as qc } from 'keys'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import AssessmentFiltersHeader from 'components/School/Assessments/AssessmentFiltersHeader'

const Normalizer = () => {
    const { applyDataConfirmOpen, setApplyDataConfirmOpen, activeScoreItemStatus, activeScoreRatingIndex, setActiveScoreRatingIndex, stats, setStats, scoreRatingOptions, setScoreRatingOptions, assessmentFilters, setAssessmentFilters, scoreRatingSemesters } = useContext(NormalizerContext)
    const headerItems = assessmentsHooks.useHeaderItems()
    const headerBtns = normalizerHooks.useHeaderBtns()
    const queryClient = useQueryClient()

    const changeActiveScoreItem = useCallback((index) => {
        if (activeScoreRatingIndex != null && activeScoreItemStatus) {
            setApplyDataConfirmOpen(true)
        }

        else setActiveScoreRatingIndex(isEqual(activeScoreRatingIndex, index) ? null : index)
    }, [activeScoreRatingIndex, activeScoreItemStatus])

    const editScoreRatingOption = useCallback((index, key) => {
        let scOptions = { ...scoreRatingOptions[index] }
        setActiveScoreRatingIndex(index)
        scOptions[key] = !scOptions[key]
        if (key === "edit") {
            // scOptions.showChildren = true

            //edit = false, change the show start and end bar to false
            if (!scOptions.edit) {
                // updateGraphs(index)
                scOptions.showStartBar = false
                scOptions.showEndBar = false
            }
        }

        const nScoreRatingOptions = changeItem(scoreRatingOptions, index, scOptions)
        setScoreRatingOptions(nScoreRatingOptions)
    }, [scoreRatingOptions, stats])

    const refetchData = useCallback(async () => {
        queryClient.removeQueries([qc.fluencyStats])
        await scoreRatingSemesters.refetch()
        queryClient.invalidateQueries([qc.fluencyStats])
    }, [])

    const updateStats = useCallback((index, val) => {
        setStats(prevStats => {
            const nStats = changeItem(prevStats, index, val);
            return nStats;
        });
    }, [stats])

    useEffect(() => {
        return (() => {
            queryClient.removeQueries()
        })
    }, [])

    const Row = ({ index, style }) => {
        const scoreRating = scoreRatingSemesters.data[index];
        return (
            <div style={style}>
                <ScoreRatingItem
                    key={index}
                    graphKey={index}
                    changeActiveScoreItem={changeActiveScoreItem}
                    activeScoreRatingIndex={activeScoreRatingIndex}
                    editScoreRatingOption={editScoreRatingOption}
                    index={index}
                    scoreRating={scoreRating}
                    scoreRatingOptions={scoreRatingOptions[index]}
                    stats={stats[index]}
                    updateStats={(val) => updateStats(index, val)}
                />
            </div>
        );
    };

    return (
        <NormalizerStyles>
            <WrapDataGrid>
                <AssessmentFiltersHeader
                    searchLabel="הצג"
                    disabledSearch={!assessmentFilters.category}
                    refetchData={refetchData}
                    loading={scoreRatingSemesters.isFetching}
                    headerItems={[{
                        ...headerItems.category,
                        multiple: false
                    },
                        // {
                        //     id: 0, placeholder: "שיטה",
                        //     options: ,
                        //     name: "category",
                        //     component: "autoComplete",
                        //     labelKey: "label",
                        //     multiple: true,
                        // }
                    ]}
                    showLoading={true}
                    assessmentFilters={assessmentFilters}
                    setAssessmentFilters={setAssessmentFilters}
                />
                <Divider sx={{ borderColor: thirdly2 }} />
                <Space />
                <div className='sc-actions'>
                    <AutoComplete
                        options={[{ id: "fluency", name: "קצב" }, { id: "accuracy", name: "דיוק" }]}
                        input={{
                            value: { id: "fluency", name: "קצב" }
                        }}
                        disabled
                        placeholder="סוג הבדיקה"
                        border
                        width={110}
                    />
                    <div className='sc-header-btns'>
                        {headerBtns.map((act, index) =>
                            <LightButton key={index} size="small" systemUI  {...act} />
                        )}
                    </div>
                </div>
                <Space />
                {/* <div className='sc-sections'>
                    <List
                        height={300} // גובה התצוגה
                        itemCount={scoreRatingSemesters.data?.length || 0}
                        itemSize={300}
                        width={'100%'} // רוחב התצוגה
                    >
                        {Row}
                    </List>
                </div> */}
                <div className='sc-sections'>
                    {scoreRatingSemesters.data?.map((scoreRating, index) => {

                        return (
                            <ScoreRatingItem
                                key={index}
                                changeActiveScoreItem={changeActiveScoreItem}
                                activeScoreRatingIndex={activeScoreRatingIndex}
                                graphKey={index}
                                editScoreRatingOption={editScoreRatingOption}
                                index={index}
                                scoreRating={scoreRating}
                                scoreRatingOptions={scoreRatingOptions[index]}
                                stats={stats[index]}
                                updateStats={(val) => updateStats(index, val)}
                            />
                        )

                    })}
                </div>
            </WrapDataGrid>
            {applyDataConfirmOpen && <ApplyDataConfirmation handleAlert={setApplyDataConfirmOpen} />}
        </NormalizerStyles>
    )
}

export default Normalizer