import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Header, WrapFiltersHeader } from './Style';
import { Button, fieldsTyps } from 'components/Form';
import { assessmentsHooks } from 'components/School/Assessments';
import { assessmentDefRQ } from 'store/hooks';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { assessmentHooks } from '.';
import { useQuery } from '@tanstack/react-query';

const Context = (props) => {
    const { assessmentFilters, setAssessmentFilters } = props
    // const [assessmentFilters, setAssessmentFilters] = useState({
    //     year: null,
    //     semester: null,
    //     assessment_def: null,
    //     gender: null,
    //     category: null
    // })
    // const [statusFilters, setStatusFilters] = useState()

    const commonState = {
        assessmentFilters, setAssessmentFilters,
    }

    return (
        <AssessmentFilterHeaderContext.Provider value={commonState}>
            <HeaderFilters {...props} />
        </AssessmentFilterHeaderContext.Provider>
    )
}

const HeaderFilters = (props) => {
    const dispatch = useDispatch()
    const headerItemsP = assessmentsHooks.useHeaderItems()
    const { showLoading, headerItems = headerItemsP, disabledSearch, headercss, loading, queryFn, queryKey } = props

    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { statusFilters, assessmentFilters, setAssessmentFilters } = useContext(AssessmentFilterHeaderContext)

    const { data, refetch: refetchAssessmentDefs } = assessmentHooks.useGetAssessmentDefOptions()

    const { refetch: refetchData } = useQuery({
        queryFn: ({ signal }) => queryFn({ signal, headerFilters, assessmentFilters, statusFilters, testTaker }),
        queryKey: queryKey,
        enabled: false
    })

    useEffect(() => {
        refetchAssessmentDefs()
    }, [headerFilters, assessmentFilters?.category])

    // useEffect(() => {
    //     dispatch(actions.cleanAssessmentFilters())
    // }, [])

    const updateHeaderItem = useCallback(({ name }, val) => {
        const nAssessmentFilters = { ...assessmentFilters }
        nAssessmentFilters[name] = val
        setAssessmentFilters(nAssessmentFilters)
    }, [assessmentFilters])

    return (
        <WrapFiltersHeader>
            <Header headercss={headercss}>
                {headerItems.map((headerBtn, index) => {
                    let Component = fieldsTyps[headerBtn.component]

                    return (
                        <Component
                            {...headerBtn}
                            key={index}
                            fullwidth
                            options={headerBtn.name === 'assessment_def' ? data : headerBtn.options}
                            input={{
                                // ...headerBtn?.input,
                                onChange: (val) => updateHeaderItem(headerBtn, val),
                                value: assessmentFilters[headerBtn.name]

                                // headerBtn?.input?.value
                            }}
                        />
                    )
                })}
                <Button
                    disabled={disabledSearch}
                    label="חיפוש"
                    action={refetchData}
                    height={'40px'}
                    width={'80px'}
                    color={"thirdly"}
                    loading={showLoading && loading}
                />
            </Header>
        </WrapFiltersHeader>
    );
}

const isRender = (prev, next) => {
    return false
}

export const AssessmentFilterHeaderContext = React.createContext()
export default React.memo(Context, isRender)
