import React from 'react'
import { ChildrenGraphStyles, WrapGraph, WrapIcon, WrapNumChild, WrapGraphTexts, GraphNumber, WrapGraphItem, WrapChild, LineStyles } from './Style'
import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { SvgIcon } from '@mui/material'

const BarLine = (props) => {
    const { color, count, percent, ratingIndex} = props

    return (
        <LineStyles color={color} firstItem={!ratingIndex} style={{ position: "relative", bottom: 36, display: "flex", justifyContent: "center" }}>
            {/* {!ratingIndex &&
                                                <React.Fragment>
                                                    <div style={{ position: "relative", top: 35 }}> 0</div>
                                                    <div style={{ width: 10 }} /> */}

            {/* <div style={{ position: "relative", top: 38, height: 15, width: 2, background: color }}> */}
            {/* </div> */}
            {/* </React.Fragment>
                                            } */}
            <div style={{
                height: "25px", textAlign: "center", width: "100%", alignItems: "center", position: "relative"
            }}>
                <div className='rating-details count'>
                    {!count ||
                        <React.Fragment>
                            {count}
                            <SvgIcon fontSize={"14"} component={ChildIcon} inheritViewBox />
                        </React.Fragment>}
                </div>
                <div style={{ height: 2, width: "102%", background: color, marginTop: 5, marginBottom: 5 }} />
                <div className='rating-details percent'> {!percent || `${percent.toFixed(0)}%`}</div>
            </div>
            {/* {ratingIndex == 4 &&
                                                <React.Fragment>
                                                    <div style={{ width: 15 }} />
                                                    <div style={{ position: "relative", top: 35 }}>
                                                        200
                                                    </div> */}
            {/* <div style={{ right: 10, position: "relative", top: 38, height: 15, width: 2, background: color }}></div> */}
            {/* </React.Fragment>} */}
        </LineStyles>
    )
}

export default BarLine