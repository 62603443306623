import { Drawer, drawerClasses } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { CenterStyle } from 'styles/GlobalCss';
import { thirdly2 } from 'styles/theme';
import { bg } from 'styles/theme';

const DrawerGraphStyles = muiStyled(Drawer)(({ theme, $systemUI }) => ({
    [`& .${drawerClasses.paper}, & .${drawerClasses.root}`]: {
        background: bg,
    },
    '.wrap-graph-content': {
        height: 470,
        display: "flex",
        flexDirection: 'column',
        width: "100%",
        padding: "15px",
        rowGap: 15,
        boxSizing: "border-box"
    },
    '.table': {
        flexGrow: 1,
        overflow: "auto"
    },
    '.wrap-table': {
        display: "flex",
        columnGap: 5,
        height: 172
    },
    '.wrap-graph': {
        display: "flex",
        alignItems: "end",
        columnGap: 10,
        flexGrow: 1,
        paddingBottom: 20
    },
    '.graph': {
        width: "100%"
    },
    '.icon-buttons': {
        height: "50px",
        flexShrink: 0,
        display: "flex",
        columnGap: '5px',
        flexDirection: "column",
        justifyContent: 'start',
        rowGap: 10
    },
    '.assessment-count': {
        background: thirdly2,
        borderRadius: 5,
        width: 170,
        padding: 40,
        boxSizing: "border-box",
        height: "100%",
        fontWeight: "bold",
        ...CenterStyle
    }
}));

export { DrawerGraphStyles }