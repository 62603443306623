import { Add } from "@mui/icons-material"
import { Tooltip } from "@mui/material"

const InsertionIcon = () => {
    return (
        <Tooltip title="הוספה" placement="top">
            <Add fontSize="small" color="error" />
        </Tooltip>
    )
}

export default InsertionIcon