import { TestTakerRM } from "models/RM"
import { actions } from "store"
import { AlertCrud } from "components/Alerts/hooks"
import { useDispatch, useSelector } from "react-redux"
import { alerts } from "keys"
import { TestTakersCrud } from "components/School/TestTakers/hooks"
import { convertSelectToValue } from "utils/formatter"
import { change, reset } from "redux-form"
import { toastConfig } from "config"
import { toast } from "react-toastify"
import { testTakerRQ } from "store/hooks"
import { queryClient as qc } from 'keys'
import { useQueryClient } from "@tanstack/react-query"

const CreateUserCrud = () => {
    const formName = "createTestTaker"
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const { setAlerts } = AlertCrud()
    const { getTestTakers } = TestTakersCrud()
    const createTestTakerValues = useSelector(state => state.form[formName]?.values)

    const resetData = () => {
        dispatch(reset(formName))
    }

    const createTestTaker = async (values) => {
        try {
            const RMTestTaker = new TestTakerRM(values)
            await dispatch(actions.createTestTaker(RMTestTaker))
            getTestTakers()
            handleCreateTestTakerAlert(false)
            toast("התלמיד נוצר.ה בהצלחה", toastConfig)
            resetData()
            queryClient.invalidateQueries({ queryKey: [qc.testTakers] })
        }
        catch {
            console.log("error createTestTaker")
        }
    }

    const handleCreateTestTakerAlert = (value) => {
        dispatch(actions.setAlerts(
            {
                key: alerts.createTestTaker,
                value: { open: value, props: null }
            }))
    }

    const cleanFormValues = (keys) => {
        keys.map((key) =>
            dispatch(change(formName, key, null))
        )
    }

    const handleChangeAffiliationGroup = async (key, value) => {
        // cleanFormValues(["affiliation", "grade", "t_class"])

        const values = convertSelectToValue({ ...createTestTakerValues, [key]: value })
        try {
            const affiliations = await dispatch(actions.getAffiliations(values))
            dispatch(actions.setAffiliations(affiliations))
        }
        catch {
            console.log("error getAffiliations")
        }
    }

    const handleChangeAffiliation = async (key, value) => {
        cleanFormValues(["grade", "t_class"])

        const values = convertSelectToValue({ ...createTestTakerValues, [key]: value })
        try {
            const grades = await dispatch(actions.getGrades(values))
            dispatch(actions.setGrades(grades))
        }
        catch {
            console.log("error getGrades")
        }
    }

    const handleChangeGrade = async (key, value) => {
        cleanFormValues(["t_class"])

        const values = convertSelectToValue({ ...createTestTakerValues, [key]: value })
        try {
            const tClasses = await dispatch(actions.getTClasses(values))
            dispatch(actions.setTClasses(tClasses))
        }
        catch {
            console.log("error getTClasses")
        }
    }

    const handleChangeTClasses = async () => {
    }

    const cancelAction = () => {
        resetData()
        handleCreateTestTakerAlert(false)
    }

    return {
        handleCreateTestTakerAlert,
        cancelAction,
        createTestTaker,
        handleChangeAffiliation,
        handleChangeAffiliationGroup,
        handleChangeGrade,
        handleChangeTClasses
    }
}

export default CreateUserCrud