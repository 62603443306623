import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { alerts as alertKeys } from "keys"
import { useSelector } from "react-redux"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { backToAudioCuttingFetch } from "store/middlewares/markingWords.crud"
import { queryClient as qc } from "keys"

const useBackToConfirmation = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const { assessment } = useSelector(state => state.assessment)

    const { mutateAsync: backToAudioCuttingRQ, isPending: backToAudioCuttingLoading } = useMutation({ mutationFn: backToAudioCuttingFetch })


    const backToAudioCutting = useCallback(async (assessmentRow) => {
        const nAssessment = assessmentRow || assessment
        const res = await backToAudioCuttingRQ(nAssessment)
        dispatch(actions.updateAssessment(res.data))
    }, [assessment])

    const setBackToConfirmation = useCallback(async (value, props) => {
        dispatch(actions.setAlerts({
            key: alertKeys.backTo, value: {
                open: value,
                props: props,
                onClose: (props) => props.onClose(props)
            }
        }))
    }, [])

    const openBackToAudioCuttingConfirmation = useCallback(async (assessmentRow, refetchAfterBackTo) => {
        setBackToConfirmation(true, {
            title: "החזר לחיתוך",
            content: "האם התכוונת להחזיר את המבדק לחיתוך?",
            onConfirm: async () => {
                await backToAudioCutting(assessmentRow)
                setBackToConfirmation(false)
                if (refetchAfterBackTo)
                    await queryClient.invalidateQueries({
                        queryKey: [qc.adminAssessments],
                    })
            },
            onClose: () => {
                setBackToConfirmation(false)
            }
        })
    }, [])

    return {
        action: openBackToAudioCuttingConfirmation,
        loading: backToAudioCuttingLoading
    }
}
export default useBackToConfirmation