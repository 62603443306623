const ratingList = ["#75BCE0", "#8BBC01", "#CCB300", "#FF9357", "#e86e5e", "#6b6b6bfa",]

const ratingColors = ["#75BCE0", "#8BBC01", "#CCB300", "#FF9357", "#e86e5e", "#6b6b6bfa",]
const ratingColors2 = ["#CBE7F9", "#DBF4C1", "#F8F1C1", "#F8D2C1", "#F6C2BF",]
const ratingColors3 = ["#FFD6D4", "#FEE4D8", "#FEFAD8", "#EBFED8", "#D8EFFE", "#6b6b6bfa",]

const ratingText = ["#4D9BC8", "#779A11", "#AB9603", "#E06700", "#C70404",]
const ratingBgColors = ["#add6ef", "#b8d67f", "#d9d17f", "#f3c1aa", "#e7afae", "#6b6b6bfa",]
const ratingListTheme = ["rating1", "primary", "rating3", "rating4", "rating5"]
const ratingListLabel = ["גבוה", "בטווח המצופה", "מעט נמוך", "נמוך", "נמוך מאד"]
const ratingListLabel2 = ["גבוהה", "מותאמת", "מעט נמוכה", "נמוכה", "נמוכה מאד"]
const ratingFrequency = ["98-101%", "94-98%", "89-94%", "80-89%", "1-80%",]
const frequencyRagtingWithLabels = [
    `${ratingListLabel2[0]} ${ratingFrequency[0]}`,
    `${ratingListLabel2[1]} ${ratingFrequency[1]}`,
    `${ratingListLabel2[2]} ${ratingFrequency[2]}`,
    `${ratingListLabel2[3]} ${ratingFrequency[3]}`,
    `${ratingListLabel2[4]} ${ratingFrequency[4]}`,
]

const naLabel = "ל/ת"
const deviationRatingIndex = 2

export { ratingColors, ratingColors2, ratingColors3, naLabel, ratingText, ratingFrequency, frequencyRagtingWithLabels, deviationRatingIndex, ratingListTheme, ratingListLabel, ratingBgColors }

export default ratingList