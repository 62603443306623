import { useQuery, useQueryClient } from "@tanstack/react-query"
import { queryClient as qc } from 'keys'
import { getAssessmentDefFetch, getAssessmentDefsFetch } from "store/middlewares/assessment.crud"
import { useParams, useSearchParams } from "react-router-dom"
import { actions } from "store"
import { useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { useSelector } from "react-redux"

const useGetAssessmentDef = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
    const params = useParams()

    const assessmentDefID = searchParams.get('assessmentDef');

    const assessmentDef = assessmentDefID || params.assessmentDefID

    return useQuery(
        {
            queryKey: qc.assessmentDef,
            queryFn: async ({ signal }) => {
                const { data } = await getAssessmentDefFetch({ id: assessmentDef, signal, })
                dispatch(actions.setAssessmentDef(data))
            },
            enabled: !!assessmentDef
        })
}

// //not in use
// const useGetAssessmentDefs = () => {
//     const { headerFilters } = useSelector(state => state.header)
//     const { assessmentFilters } = useSelector(state => state.assessment)

//     return useQuery(
//         {
//             queryKey: qc.assessmentDefs,
//             queryFn: ({ signal }) => () => {
//                 return getAssessmentDefsFetch({ headerFilters, assessmentFilters, signal, })
//             }
//         })
// }

const useGetAllAssessmentDefs = () => {
    const { assessmentDefs } = useSelector(state => state.assessment)

    const dispatch = useDispatch()

    return useQuery(
        {
            queryKey: qc.allAssessmentDefs,
            enabled: isEmpty(assessmentDefs),
            refetchOnWindowFocus: false,
            queryFn: async ({ signal }) => {
                const { data } = await getAssessmentDefsFetch({ signal, })
                dispatch(actions.setAssessmentDefs(data))
            }
        })
}


export default { useGetAssessmentDef, useGetAllAssessmentDefs }