import { StudentParagraph, Paragraph, WordList } from 'components/Words'
import { visibleTypes } from 'keys'

const useAssessmentCategory = () => {
    const getPointsL = (sumWords) => {
        return sumWords * 2
    }

    const getPointsP = (sumWords) => {
        return sumWords
    }

    const categories = {
        [visibleTypes.PARAGRAPH]: {
            component: Paragraph,
            student: {
                component: StudentParagraph,
                fullHeight: true
            },
            points: getPointsP,
            bg: true,
            shadow: 3
        },
        [visibleTypes.WORD_GROUP]: {
            component: WordList,
            student: {
                component: WordList,
            },
            studentC: WordList,
            points: getPointsL,
            shadow: 0
        }
    }

    const mappingMarkByType = {
        "TEHILIM": {
            type: "TEHILIM",
            ...categories.PARAGRAPH
        },
        "PARAGRAPH": {
            type: "PARAGRAPH",
            ...categories.PARAGRAPH
        },
        "WORD_GROUP": {
            type: "WORD_GROUP",
            ...categories.WORD_GROUP
        },
        "NONSENSE_WORDS": {
            type: "NONSENSE_WORDS",
            ...categories.WORD_GROUP
        },
        "null": {
            type: "null",
            ...categories.WORD_GROUP
        },
        "DEFAULT": {
            type: "DEFAULT",
            ...categories.PARAGRAPH
        }
    }

    return mappingMarkByType
}
export default useAssessmentCategory