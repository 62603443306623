import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'store'
import { alerts as alertsKeys } from 'keys'
import { adminAssessmentsRQ, StoreCrud } from 'store/hooks'
import { useMutation } from '@tanstack/react-query'
import { backToTranscriptionFetch, continueMarkingWordsFetch, startMarkingWordsFetch } from 'store/middlewares/markingWords.crud'
import { useCallback } from 'react'

const AdminAssessmentsDrud = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const { updateAssessmentStatus } = StoreCrud()

    const { mutateAsync: backToTranscriptionRQ, isPending: backToTranscriptionLoading } = useMutation({ mutationFn: backToTranscriptionFetch })

    const { refetch: refetchAdminAssessments, isFetching: adminAssessmentsLoading } = adminAssessmentsRQ.useGetAdminAssessments({ enabled: false })

    const cleanData = () => {
        dispatch(actions.cleanStatusFilters())
        dispatch(actions.cleanAssessments(null))
        dispatch(actions.cleanAssessmentFilters())
        dispatch(actions.cleanSelectedAssessments())
        dispatch(actions.cleanTestTaker())
    }

    const setRowSelectionModel = (newRowSelectionModel) => {
        dispatch(actions.setSelectedAssessments(newRowSelectionModel))
    }

    const selectAssessment = async (assessment) => {
        dispatch(actions.setAssessment(assessment))
        dispatch(actions.setTestTaker({ test_taker_id: assessment.test_taker_id }))
    }

    const setBackToConfirmation = useCallback(async (value, props) => {
        dispatch(actions.setAlerts({
            key: alertsKeys.backTo, value: {
                open: value,
                props: props,
                onClose: (props) => props.onClose(props)
            }
        }))
    }, [])

    const openBackToTranscriptionConfirmation = useCallback(async (assessmentRow, refetchAfterBackTo) => {
        setBackToConfirmation(true, {
            title: "החזר לתעתוק",
            content: "האם התכוונת להחזיר את המבדק לתעתוק?",
            onConfirm: async () => {
                await backToTranscription(assessmentRow)
                setBackToConfirmation(false)
                //TODO
                // refetchAfterBackTo && refetchAdminAssessments()
            },
            onClose: () => {
                setBackToConfirmation(false)
            }
        })
    }, [])

    const backToTranscription = useCallback(async (assessmentRow) => {
        const nAssessment = assessmentRow || assessment
        const res = await backToTranscriptionRQ(nAssessment)
        dispatch(actions.updateAssessment(res.data))
    }, [assessment])

    return {
        selectAssessment,
        setRowSelectionModel,
        cleanData,
        updateAssessmentStatus,
        setBackToConfirmation,
        refetchAdminAssessments,
        openBackToTranscriptionConfirmation,

        backToTranscriptionLoading
    }
}

export default AdminAssessmentsDrud