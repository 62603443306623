import { useCallback, useContext } from 'react';
import { cloneDeep } from 'lodash';
import { changeItem } from 'utils/array';
import { NormalizerContext } from '../NormalizerContext';

const useHandleOnDragEnd = (index) => {
    const { stats, setStats, activeScoreItemStatus, setActiveScoreItemStatus } = useContext(NormalizerContext)

    const updateStats = useCallback((index, val) => {
        setStats(prevStats => {
            const nStats = changeItem(prevStats, index, val);
            return nStats;
        });
    }, [stats])

    return useCallback((result) => {
        const { active, over } = result;
        const sNumber = active.id, dNumber = over.id;

        setActiveScoreItemStatus("edit")

        const nStats = cloneDeep(stats[index]);

        let { rating_index, rating_id } = nStats[sNumber];

        nStats[dNumber].rating_id = nStats[sNumber].rating_id;

        let range = nStats[sNumber].rating_index - nStats[dNumber].rating_index;
        if (!nStats[dNumber].rating_index) {
            alert("פעולה אסורה - למשבצת זו אין rating עליך לעבור אחורה וקדימה עד שהמשבצת תקבל צבע ");
            return;
        }
        if (range >= 1 || range < -1) {
            alert("דלגת rating - פעולה אסורה");
            return;
        }

        // עדכון האוביקטים בטווח למעלה
        let currentNumber = parseInt(sNumber);
        while (currentNumber < dNumber) {
            if (nStats[dNumber].rating_id === rating_id) {
                nStats[currentNumber].rating_id = rating_id + 1;
                nStats[currentNumber].rating_index = rating_index + 1;
            } else {
                nStats[currentNumber].rating_id = rating_id;
                nStats[currentNumber].rating_index = rating_index;
            }
            currentNumber++;
        }

        // עדכון האוביקטים בטווח למטה
        let prevNumber = parseInt(sNumber) - 1;
        while (prevNumber > 0 && prevNumber >= dNumber) {
            nStats[prevNumber].rating_id = rating_id;
            nStats[prevNumber].rating_index = rating_index;
            prevNumber--;
        }

        updateStats(index, nStats);
    }, [stats, index]);
}

export default useHandleOnDragEnd