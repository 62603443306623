import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { actions } from "store"
import { SourceWordVM } from 'models/VM'
import { AssessmentRM } from "models/RM"
import { app } from "components/Router/routes"
import { useLocation, useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { markingWordsCompleteFetch, updateBrokenFileStatusFetch } from "store/middlewares/markingWords.crud"
import { saveWordsFetch } from "store/middlewares/words.crud"
import { queryClient as qc, updateType } from "keys"
import { isEmpty } from "lodash"
import { adminAssessmentsHooks } from "components/Admin/AdminAssessments"
import { surferHooks } from "components/Record"

const MarkingWordsCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const onPause = surferHooks.useOnPause()

    const { words } = useSelector(state => state.words)
    const { wavesurfer } = useSelector(state => state.wavesurfer)
    const { assessment } = useSelector(state => state.assessment)
    const { state } = useLocation()

    const selectAndViewAssessment = adminAssessmentsHooks.useSelectAndMarking()

    const { mutateAsync: saveWordsRQ, isPending: saveWordsLoading } = useMutation({ mutationFn: saveWordsFetch })
    const { mutateAsync: markingWordsCompleteRQ, isPending: markingWordsCompleteLoading } = useMutation({
        mutationFn: markingWordsCompleteFetch,
        onError: () => queryClient.invalidateQueries({ queryKey: qc.assessmentAndWords })
    })
    const { mutateAsync: updateBrokenFileStatusRQ, isPending: updateBrokenFileStatusLoading } = useMutation({ mutationFn: updateBrokenFileStatusFetch })

    const goAdminAssessments = () => {
        //TODO not working
        // if (!isEmpty(state?.statusFilters))
        //     dispatch(actions.setStatusFilters(state.statusFilters))
        // if (!isEmpty(state?.assessmentFilters))
        //     dispatch(actions.setAssessmentFilters(state.assessmentFilters))

        navigate(app.markingWordsAssessments)
    }

    const cleanData = () => {
        dispatch(actions.setWords(null))
        dispatch(actions.setIsCloseSttoper(true))
        // dispatch(actions.setStatusFilters({}))
    }

    const cleanMarkingWords = () => {
        onPause()
        let nWords = []
        words.forEach((word) => nWords.push(new SourceWordVM(word)))
        dispatch(actions.setWords(nWords))
    }

    //TODO דחוף
    const saveMarkingWords = async () => {
        const nWords = await saveWordsRQ({ assessment, words })
        const wordsWithoutUpdateTypeDelete = nWords.filter(word => word.update_type != updateType.delete)
        let data = new AssessmentRM({
            endPoint: assessment.endPoint,
            timePoint: assessment.timePoint,
            words: wordsWithoutUpdateTypeDelete,
        })
        return data
    }

    const markingWordsComplete = async () => {
        dispatch(actions.setIsCloseSttoper(true))
        const data = await saveMarkingWords()
        await markingWordsCompleteRQ({ id: assessment.id, data })
    }

    const markingWordsFinish = async () => {
        await markingWordsComplete()
        goAdminAssessments()
    }

    const markingWordsSaveAndNext = async () => {
        await markingWordsComplete()
        dispatch(actions.setStatusFilters(state.statusFilters))

        // const adminAssessments = await refetchAdminAssessments()
        const adminAssessments = queryClient.getQueriesData({ queryKey: [qc.adminAssessments] })
            ?.data

        let assessmentIndex = adminAssessments?.findIndex((ass) => ass.id === assessment.id)
        let assessmentLength = adminAssessments?.length

        if (assessmentIndex < assessmentLength - 1) {
            selectAndViewAssessment(adminAssessments[assessmentIndex + 1])
            dispatch(actions.setWords(null))
        }
        else goAdminAssessments()
    }

    const updateBrokenFileStatus = async () => {
        await updateBrokenFileStatusRQ(assessment)
        goAdminAssessments()
    }

    return {
        markingWordsSaveAndNext,
        cleanData,
        markingWordsComplete,
        markingWordsFinish,
        cleanMarkingWords,
        goAdminAssessments,
        updateBrokenFileStatus,

        markingWordsCompleteLoading,
        saveWordsLoading,
        updateBrokenFileStatusLoading
    }
}

export default MarkingWordsCrud