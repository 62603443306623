import React from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'
import { isEmpty, pick } from 'lodash'
import { WrapIndication } from './Style'
import { deviationRatingIndex, naLabel, ratingListLabel, ratingListTheme } from 'keys/rating'
import { statuses } from 'keys'
import { Done, NotificationsNone, Remove } from '@mui/icons-material'
import { CellWithTooltipStyles } from 'components/Table/Style'

const RateAndAccuracy = (props) => {
    const { row, column } = props
    const value = row.original
    const { meta } = column.columnDef
    const { rating, status } = value || {}
    const { scoreRatingMethods } = useSelector(state => state.rating)

    const indicationIcon = {
        "noraml": {
            icon: Done,
            color: "success"
        },
        "warning": {
            color: "error",
            icon: NotificationsNone,
        },
        "nodata": {
            icon: Remove,
            color: "ligthGray"
        }
    }

    const { warning, noraml, nodata } = indicationIcon

    const ratingFields = pick(rating, [meta.id, meta.index])
    let ratingIndex = ratingFields[meta.index]
    let ratingId = ratingFields[meta.id]
    let currentScoreRatingMethod
    let indication

    if (status === statuses.brokenFile)
        currentScoreRatingMethod = {
            label: naLabel
        }

    else {
        if (isEmpty(scoreRatingMethods))
            currentScoreRatingMethod = {
                themeColor: ratingListTheme[ratingIndex - 1],
                label: ratingListLabel[ratingIndex - 1]
            }
        else {
            currentScoreRatingMethod = scoreRatingMethods?.[ratingId]
            ratingIndex = currentScoreRatingMethod?.index
        }

        indication = ratingIndex ? (ratingIndex <= deviationRatingIndex ? noraml : warning) : nodata
    }

    const showIcon = indication?.icon

    return (
        <CellWithTooltipStyles size={showIcon ? "sm" : "md"}>
            <Tooltip title={currentScoreRatingMethod?.label} placement='left'>
                <WrapIndication>
                    {showIcon ?
                        <indication.icon
                            {...indication}
                            fontSize='small'
                            color={currentScoreRatingMethod?.themeColor || 'ligthGray'}
                        /> :
                        currentScoreRatingMethod?.label}

                </WrapIndication>
            </Tooltip>
        </CellWithTooltipStyles>
    )

}

export default RateAndAccuracy