import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { app } from 'components/Router/routes'
import { secretStorage, toastConfig } from 'config'
import { CustomUserRM } from 'models/RM'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { application, assessmentRQ } from 'store/hooks'
import { registerFetch } from 'store/middlewares/auth.crud'
import { FlexCenter } from 'styles/GlobalStyle'
import { WrapMessage } from './Style'
import { SuccessToast } from '.'
import { updateUserBalanceFetch } from 'store/middlewares/user'
import { initialize, reset, submit } from 'redux-form'
import { useDispatch } from 'react-redux'

const ThankYou = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { mutateAsync: bulkCreateByLevel } = assessmentRQ.useBulkCreateByLevel()

    const { mutateAsync: parentRegistration } = useMutation({
        mutationFn: registerFetch,
        onSuccess: () => {
            const registerForm = secretStorage.getItem("registerForm")
            toast(<SuccessToast {...registerForm} />, toastConfig)
            navigate(app.login)
            secretStorage.removeItem("registerForm")
        },
        onError: (error) => {
            console.log(error)
            toast.error("שגיאה", toastConfig)
        }
    })
    const { mutateAsync: updateUserBalance } = useMutation({
        mutationFn: updateUserBalanceFetch,
        onSuccess: async () => {
            const formValues = secretStorage.getItem("registerForm")
            const user = secretStorage.getUser()
            let userData = { ...user, balance: formValues.new_balance }
            secretStorage.setUser(userData)

            await bulkCreateByLevel(formValues.test_taker_id)
            navigate(app.allAssessments)
        },
        onError: (error) => {
            console.log(error)
            toast.error("שגיאה", toastConfig)
        },
        onSettled: () => {
            secretStorage.removeItem("registerForm")
            dispatch(reset("registerForm"))
            dispatch(initialize("registerForm"))
            dispatch(submit("registerForm"))
        }
    })

    const register = async () => {
        const formValues = secretStorage.getItem("registerForm")

        if (formValues)
            if (formValues.new_balance)
                updateUserBalance({ test_taker_id: formValues.test_taker_id, balance: formValues.new_balance })
            else {
                const customUser = new CustomUserRM({ ...formValues, user_type: "parent" })
                parentRegistration(customUser)
            }
    }

    useEffect(() => {
        register()
    }, [])


    return (
        <FlexCenter>
            <WrapMessage>
                התשלום עבר בהצלחה, הינך מועבר להשלמת הפרטים
                <CircularProgress color='secondary' />
            </WrapMessage>
        </FlexCenter>
    )
}

export default ThankYou