import dayjs from "dayjs"
import { gender as g } from "keys"
import { map, pick } from "lodash"

class TestTaker {
    constructor(testTaker, user_type) {
        const { t_class, languages, test_taker_id, school, grade, group, affiliation, first_name, tz, last_name, dob = new Date(), address, gender } = testTaker
        this.first_name = first_name
        this.test_taker_id = test_taker_id
        this.last_name = last_name
        this.dob = dayjs(dob).format('YYYY-MM-DD')
        this.address = address
        this.gender = gender?.id || g.female
        this.languages = map(languages, 'id')
        this.tz = tz

        this.affiliation = affiliation?.id
        this.group = group?.id
        this.grade = grade.id
        this.t_class = t_class?.id
        if (school)
            this.school = pick(school, ["institution_code", "name"])

        if (user_type === 'parent')
            this.t_class = t_class?.name
    }
}

export default TestTaker