import { Box } from '@mui/material'
import { Card } from 'components/Form/Styled'
import styled from 'styled-components'
import { CustomSection, WrapImg } from 'styles/GlobalStyle'
import { bg, black } from 'styles/theme'
import { styled as muiStyled } from '@mui/material/styles';
import { thirdly } from 'styles/theme'
import { Center, CenterStyle } from 'styles/GlobalCss'
import { thirdly3 } from 'styles/theme'
import { thirdly2 } from 'styles/theme'
import { display, fontSize, fontWeight } from '@mui/system'
import { useMemo } from 'react'
import { useMediaQuery } from '@mui/material';
import { keyBy } from 'lodash'
import { dark } from 'styles/theme'

const radius = 25

const bpValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1480,
    xxl: 1780,
    xxxl: 2000,
    xxxxl: 2200
}
const { xs, sm, md, lg, xl, xxl, xxxl, xxxxl } = bpValues

const breakpoints = [
    { name: 'xxxxl', value: `@media (min-width:${xxxxl}px)`, upSize: xxxxl / xxxl },
    { name: 'xxxl', value: `@media (min-width:${xxxl}px)`, upSize: xxxl / xxl },
    { name: 'xxl', value: `@media (min-width:${xxl}px)`, upSize: xxl / xl },
    { name: 'xl', value: `@media (min-width:${xl}px)`, upSize: xl / lg },
    { name: 'lg', value: `@media (min-width:${lg}px)`, upSize: lg / md },
    { name: 'md', value: `@media (min-width:${md}px)`, upSize: md },
    { name: 'sm', value: `@media (min-width:${sm}px)`, upSize: sm },
];

const CardStyles = muiStyled(Box)(({ button, disabled, alignCenter, color, bg, height, theme, $systemUI, cartoonHeight, cartoonWidth }) => {
    let cardW = 280

    return {
        border: `1px solid ${thirdly3}`,
        borderRadius: radius,
        cursor: !disabled && button && 'pointer',
        alignItems: alignCenter && "center",
        backgroundColor: bg || "white",
        width: cardW,
        display: "flex",
        flexDirection: "column",
        justifyContent: alignCenter ? "center" : "space-between",
        padding: 25,
        boxSizing: "border-box",
        height: height || "100%",
        fontSize: 30,
        fontWeight: 700,
        color: color || dark,
    }
});

const GeneralDashboardStyles = muiStyled(Box)(({ theme, $systemUI, cartoonHeight, cartoonWidth }) => {

    // const mediaQueries = breakpoints.map(bp => useMediaQuery(bp.value));
    // const bpIndex = mediaQueries.findIndex(media => media);
    // const breakpoint = breakpoints[bpIndex].upSize

    // let cardW = 245
    // let cardGap = 20
    // let cardHeaderH = 350
    // if (bpIndex != -1) {
    //     cardW = breakpoint * cardW
    //     cardGap = breakpoint * cardGap
    //     cardHeaderH = breakpoint * cardHeaderH
    // }
    let cardW = 280
    let cardGap = 20
    let cardHeaderH = 400

    return {
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        height: "100%",
        alignItems: "center",
        '.dashboard': {
            padding: 15,
            display: "flex",
            flexDirection: "column",
            rowGap: 15,
            width: cardW * 4 + cardGap * 3,
            height: '100%',
            justifyContent: "center"
        },
        '.dashboard-cartoon-card': {
            backgroundColor: thirdly2,
            borderRadius: radius,
            width: cardW
        },
        '.dashboard-card': {
            border: `1px solid ${thirdly3}`,
            borderRadius: radius,
            backgroundColor: "white",
            width: cardW,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
            padding: 15
        },

        '.dashboard-cartoon': {
            width: cardW
        },
        '.card-item': {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        '.card-icon': {
            height: '30%',
            ...CenterStyle
        },
        '.card-label': {
            flexGrow: 1,
            fontSize: 17,
            ...CenterStyle
        },
        '.card-btn': {
            height: '30%',
            ...CenterStyle
        },
        '.dashboard-video': {
            borderRadius: radius,
            width: cardW * 3 + cardGap * 2
        },
        '.card-header': {
            display: "flex",
            columnGap: cardGap,
            justifyContent: "center",
            height: cardHeaderH,
        },
        '.card-content': {
            display: "flex",
            columnGap: cardGap,
            justifyContent: "center",
            // flexGrow: 1,
            height: cardHeaderH / 1.4,
        },
        '.dashboard-cartoon': {
            position: "relative",
            right: 15,
            width: cartoonWidth,
            height: cartoonHeight
        },
        '.dashboard-title': {
            height: 80,
            padding: 30,
            width: cardW / 2,
            fontSize: 19
        },
    }
});

const StudentDashboardStyles = muiStyled(Box)(({ theme, $systemUI, cartoonHeight, cartoonWidth }) => {
    let cardW = 300
    let cardGap = 20
    let contentH = 500

    return {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
        width: "100%",
        rowGap: 50,
        '.logout': {
            width: "100%",
            display: "flex",
            justifyContent: "end",
        },
        '.start-a-task': {
            display: "flex",
            columnGap: 10,
            fontWeight: 300
        },
        '.left-cards': {
            display: "flex",
            flexDirection: "column",
            rowGap: cardGap,
            height: "100%"
        },
        '.dashboard-content': {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1
        },
        '.dashboard-cards-content': {
            alignItems: "center",
            padding: 15,
            display: "flex",
            columnGap: cardGap,
            rowGap: cardGap,
            width: cardW * 4 + cardGap * 3,
            height: contentH,
            justifyContent: "center"
        },
        '.cartoon-img': {
            position: "relative",
            right: 75,
            top: 27,
            width: 300,
            height: 300
        },
        '.task-img': {
            position: "relative",
            left: 75,
            width: 150,
            height: 150
        }
    }
});

const DashboardStyles = muiStyled(Box)(({ theme, $systemUI, cartoonHeight, cartoonWidth }) => {
    return {
    }
});

const Dashboard = styled.div`
padding:15px;
display: flex;
flex-direction: column;
row-gap: 15px;
width:95%;
`

const WrapHeader = styled.div`
display: flex;
column-gap: 10px;
justify-content:space-between;
`

const WrapContent = styled.div`
display: flex;
column-gap:20px;
`

const WrapTitle = styled.div`
width:140px;
`

const Title = styled.div`
color:${black};
font-size:20px;
`

const WrapVideo = styled.div`
display: flex;
`

const WrapCartoon = styled(WrapImg)`
position: relative;
right: 112px;
`

export { WrapCartoon, WrapVideo, Dashboard, GeneralDashboardStyles, StudentDashboardStyles, DashboardStyles, WrapHeader, WrapContent, WrapTitle, Title, CardStyles }