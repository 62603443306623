import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { MenuStyles, ToolbarColumnStyles, PopperStyles, PaperStyles } from './Style'
import { FilterList, Toc } from '@mui/icons-material';
import { FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';
import {
  flexRender
} from '@tanstack/react-table';

const ToolbarColumnsButton = ({ table }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <ToolbarColumnStyles>
      <Tooltip title="הצג/הסתר עמודות">
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleToggle}
          id="composition-button"
        >
          <FilterList />
        </IconButton>
      </Tooltip>
      <PopperStyles
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <PaperStyles>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuStyles
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {table.getAllLeafColumns().map((column) => (
                    <MenuItem key={column.id}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={column.getToggleVisibilityHandler()}
                            color='secondary'
                            checked={column.getIsVisible()}
                          />}
                        label={flexRender(
                          typeof column.columnDef.header === 'string' ?
                            column.columnDef.header :
                            column.columnDef.meta.label
                        )}
                      />
                    </MenuItem>
                  ))}
                </MenuStyles>
              </ClickAwayListener>
            </PaperStyles>
          </Grow>
        )}
      </PopperStyles>
    </ToolbarColumnStyles>
  );
}

export default ToolbarColumnsButton
