import { ClassRM } from "models/RM"
import { AlertCrud } from "components/Alerts/hooks"
import { useDispatch } from "react-redux"
import { alerts } from "keys"
import { reset } from "redux-form"
import { useMutation } from "@tanstack/react-query"
import { createClassFetch } from "store/middlewares/testTakers.crud"
import { toast } from "react-toastify"
import { toastConfig } from "config"
import { pulldowns, testTakerRQ } from "store/hooks"
import { useSelector } from "react-redux"
import { mapKeys } from "lodash"
import { actions } from "store"

const useCreateClass = () => {
    const formName = "createClass"

    const { headerFilters } = useSelector(state => state.header)

    const dispatch = useDispatch()
    const { setAlerts } = AlertCrud()

    const { refetch: refetchClasses } = testTakerRQ.useGetTClassess()

    const { mutateAsync: createClassRQ } = useMutation({
        mutationFn: createClassFetch,
        onSuccess: async () => {
            setAlerts(alerts.createClass, false)
            toast(" הכיתה נוצרה בהצלחה , התנתק והתחבר מחדש", toastConfig)
            dispatch(reset(formName))

            // const res = await refetchClasses()
            // console.log("res", res)
            // if (res.data) {
            //     pulldowns.updatePulldownStorage("classes", res.data.data)

            //     if (headerFilters?.grade) {
            //         dispatch(actions.setTClasses(res.data.data))
            //     }
            // }
        }
    })

    const createClass = async (values) => {
        const classRM = new ClassRM(values)
        await createClassRQ(classRM)
    }

    return createClass
}

export default useCreateClass