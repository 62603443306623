import React from 'react'
import { Left, MuiButton, Right, Center, NumPoints, WrapWordsHeader, ReplyIcon, WrapAssessmentName, WrapMarkWordsContent, WrapHeaderActions, WrapFooter, WrapMarkWords, WrapScreenMarkWords } from './Style'
import { Button, fieldsTyps } from 'components/Form'
import { CustomFab, CustomSection, SpaceBetween, WrapHeader } from 'styles/GlobalStyle'
import { WordsCrud } from './hooks'
import { Print } from '@mui/icons-material'
import { Icon, SvgIcon } from '@mui/material'
import { useSelector } from 'react-redux'
import { assessmentHooks } from 'components/School/Assessment'
import { statuses } from 'keys'
import { useReactToPrint } from 'react-to-print'
import { useNavigate } from 'react-router-dom'

const GeneralHeaderActions = ({ name }) => <WrapAssessmentName>{name}</WrapAssessmentName>

const Header = (props) => {
    const { name, customHeader, category, contentToPrint, print, words, headerActions, hidePoints, goRatingBtn, hideBackBtn, customGoBack } = props
    const { assessment } = useSelector(state => state.assessment)
    const navigate = useNavigate()

    const handlePrint = useReactToPrint({
        documentTitle: "Go-Read",
        removeAfterPrint: true,
        pageStyle: { background: 'red', color: "red", padding: '30px' }
    });

    const nextAssessmentBtn = assessmentHooks.useNextAssessmentBtn()

    const points = category.points(words?.length)

    return (
        <CustomSection align="center" $noShadow height={60} $flex justify="space-between">
            <WrapWordsHeader>
                <Left side={"start"}>
                    {!assessment.reading_assessment_name &&
                        <WrapAssessmentName>{name}</WrapAssessmentName>}
                    <SpaceBetween />
                    {
                        customHeader?.left?.map((act, index) => {
                            const Component = fieldsTyps[act.component]
                            return (
                                <Component {...act} key={index} />
                            )
                        })
                    }
                </Left>
                <Center side={"start"}>
                    {
                        customHeader?.center?.map((act, index) => {
                            const Component = fieldsTyps[act.component]
                            return (
                                <Component {...act} key={index} />
                            )
                        })
                    }
                </Center>
                <Right side={"end"}>
                    {
                        customHeader?.right?.map((act, index) => {
                            const Component = fieldsTyps[act.component]
                            return (
                                <Component {...act} key={index} />
                            )
                        })
                    }
                    {assessment?.sub_status === statuses.wordsComplete && <Button {...nextAssessmentBtn} />}
                    {!print &&
                        <CustomFab
                            size='small'
                            color='thirdly'
                            onClick={() =>
                                handlePrint(null, () => contentToPrint.current)}
                        >
                            <Print />
                        </CustomFab>}
                    {goRatingBtn && <Button label='דו"ח אישי' />}
                    {/* {!hidePoints &&
                        points &&
                        <NumPoints>
                            {`${points} נקודות`}
                        </NumPoints>} */}
                </Right>
            </WrapWordsHeader>
        </CustomSection >
    )
}

export default Header
