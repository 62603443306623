import { statuses } from "keys"
import { pick } from "lodash"
import { useMutation } from "@tanstack/react-query"
import { useSelector, useDispatch } from "react-redux"
import { actions } from "store"
import { continueTranscriptionFetch, startAudioCuttingFetch, startTranscriptionFetch } from "store/middlewares/markingWords.crud"
import { AdminAssessmentsCrud } from "components/Admin/AdminAssessments/hooks"

const useStartTranscription = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const { wavesurfer } = useSelector(state => state.wavesurfer)
    const { isCloseSttoper } = useSelector(state => state.markingWords)

    const { openBackToTranscriptionConfirmation, backToTranscriptionLoading } = AdminAssessmentsCrud()

    const { mutateAsync: startTranscriptionRQ, data: startTranscriptionData, isPending: startTranscriptionLoading } = useMutation({ mutationFn: startTranscriptionFetch })
    const { mutateAsync: continueTranscriptionRQ, isPending: continueTranscriptionLoading } = useMutation({ mutationFn: continueTranscriptionFetch })

    const startTranscription = async (assessment) => {
        const res = await startTranscriptionRQ(assessment)
        const assessmentDTO = pick(res?.data, ["status", "sub_status"])
        console.log(assessmentDTO)
        dispatch(actions.updateAssessment(assessmentDTO))
        dispatch(actions.setIsCloseSttoper(false))
        wavesurfer?.play()
    }

    const continueTranscription = async () => {
        await continueTranscriptionRQ(assessment)
        dispatch(actions.setIsCloseSttoper(false))
        wavesurfer?.play()
    }

    const audioCutting = {
        [statuses.inProgress]: {
            [statuses.transcriptionInProgress]: {
                label: "המשך תעתוק",
                action: continueTranscription,
                loading: continueTranscriptionLoading,
                disabled:
                    //  startAudioCuttingData && 
                    !isCloseSttoper || continueTranscriptionLoading
            },
            [statuses.transcriptionComplete]: {
                label: "החזר לתעתוק",
                action: openBackToTranscriptionConfirmation,
                loading: backToTranscriptionLoading,
                disabled: backToTranscriptionLoading
            },
            [statuses.audioCuttingComplete]: {
                label: "התחל תעתוק",
                action: startTranscription,
                loading: startTranscriptionLoading,
                disabled: startTranscriptionLoading
            }
        },
        [statuses.backToTranscription]: {
            [statuses.audioCuttingComplete]: {
                label: "התחל תעתוק",
                action: startTranscription,
                loading: startTranscriptionLoading,
                disabled: startTranscriptionLoading
            }
        },
        [statuses.brokenFile]: {
            "default": {
                label: "החזר לתעתוק",
                action: openBackToTranscriptionConfirmation,
                loading: backToTranscriptionLoading,
                disabled: backToTranscriptionLoading
            }
        }
    }

    const markWordsValue = audioCutting[assessment.status]?.[assessment.sub_status || "default"]

    const startCutting = {
        label: markWordsValue?.label || "התחל תעתוק",
        color: "secondary",
        action: () => {
            markWordsValue?.action(assessment)
        },
        disabled: markWordsValue?.disabled || !markWordsValue,
        component: 'button',
        width: "200px",
        disabledColor: "gray2",
        loading: markWordsValue?.loading,
        hide: markWordsValue?.hide
    }

    return startCutting
}

export default useStartTranscription