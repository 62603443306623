import { findIndex } from "lodash"


class MarkingWordsAssessmentVM {

    constructor(assessment, words) {
        const { assessment_def_name, assessment_def_visible_type, reading_assessment_def, id, time_point_word,

            time_point,
            words_count,
            errors_count,
            correct_words_count,
            words_in_time_point,
            correct_words_in_time_point,
            // words,
            omission_count,
            end_point_word
        } = assessment

        this.id = id
        this.assessment_def_name = assessment_def_name
        this.assessment_def_visible_type = assessment_def_visible_type
        this.reading_assessment_def = reading_assessment_def
        this.time_point_word = time_point_word


        //for finish the assessment before the end - save the index of last word
        // this.end_index = words_count + omission_count - 1
        // let word_end = words[this.end_index]?.word_def_id
        // const words_count_with_omissions = words_count + omission_count
        // if (words_count_with_omissions < words.length) {
        //     this.end = word_end
        // }

        const end_point = words.find(word => word.id === end_point_word)
        if (end_point)
            this.endPoint = {
                word_def_id: end_point.word_def_id,
                index: end_point.index
            }

        const time_point_def_id = words.find(word => word.id === time_point_word)?.word_def_id
        this.timePoint = {
            word_def_id: time_point_def_id,
            // value: "60"
            value: time_point
        }
    }
}

export default MarkingWordsAssessmentVM