import axios from 'axios';
import { cleanStorage, getToken } from './storage';
import { navigateTo } from 'components/Router/navigationService';
import { app } from 'components/Router/routes';
import secretStorage from './secretStorage';
import store from 'store/store';
import { toast } from 'react-toastify';
import toastConfig from './toast';

// Create an instance of axios
const api = axios.create({
    'Content-Type': 'application/json; charset=UTF-8'
    // baseURL: process.env.REACT_APP_SERVER_PORT // Your API base URL
});

// Add request interceptor
api.interceptors.request.use(
    config => {
        // Retrieve the token from localStorage
        const token = secretStorage.getItem("user")?.token;
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add response interceptor
api.interceptors.response.use(
    response => response,
    error => {
        // Handle errors, e.g., redirect to login if unauthorized
        if (error.response)
            if (error.response && error.response.status === 403) {

                if (error.response.data.detail === "Authentication credentials were not provided.") {
                    toast.dismiss()
                    toast.error("שגיאת הרשאה", toastConfig)
                    store.dispatch({ type: "RESET_ALL_REDUCERS" })
                    secretStorage.clean()
                    navigateTo(app.login);
                }
                else {
                    store.dispatch({ type: "RESET_ALL_REDUCERS" })
                    secretStorage.clean()
                    navigateTo(app.login);
                }

                // Handle unauthorized errors here
                // Example: redirect to login page or clear local storage
            }
            else if (error.response.status === 502) {
                toast.error("שגיאת מערכת נסה שוב בעוד מספר שניות", toastConfig)

                // Handle unauthorized errors here
                // Example: redirect to login page or clear local storage
            }
        return Promise.reject(error);
    }
);

const requestAI = axios.create({
    baseURL: process.env.REACT_APP_AI_SERVER_PORT,
    headers: {
        "X-API-Key": "Go123TestTranscription_310_8779"
    }
})

export { requestAI }

export default api;