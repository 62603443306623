
const useIdField = () => {

    const idField = {
        accessorKey: 'id',
        header: "ID",
    }

    return idField
}

export default useIdField
