import moment from "moment"
import semesters from "keys/semesters"

class AssessmentUtils {

    // Function to determine current semester
    getSemester(nDate) {
        const date = new Date(nDate)
        const formattedDate = moment(date).format('MM-DD');

        for (const [semester, value] of Object.entries(semesters)) {
            for (const range of value.range) {
                const startDate = moment(range.start, 'MM-DD');
                const endDate = moment(range.end, 'MM-DD');

                if (moment(formattedDate, 'MM-DD').isBetween(startDate, endDate, null, '[]')) {
                    return value.id;
                }
            }
        }

        return '1';
    }


    getCurrentSemester() {
        return this.getSemester(new Date())
    }

}

export default new AssessmentUtils()