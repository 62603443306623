import React, { useCallback, useContext } from 'react'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { queryClient as qc, semesters } from 'keys'
import { NormalizerContext } from './NormalizerContext'
import { getFluencyStatsFetch } from 'store/middlewares/rating.crud'
import { GraphStyles } from './Style'
import { Chip, IconButtonGroup, IconButtons } from 'components/Form'
import { thirdly } from 'styles/theme'
import { ReactComponent as TableIcon } from 'assets/icons/Table.svg';
import { ReactComponent as ChildrenIcon } from 'assets/icons/Children.svg';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { chain, isEqual, last, map, reduce, sumBy } from 'lodash'
import { Table } from 'components'
import { graphsHooks } from '../LinguisticLab/Graphs'
import StatsBar from 'components/StatsBar'
import { normalizerHooks } from '.'

const ScoreRatingItem = React.memo((props) => {
    const { activeScoreRatingIndex, changeActiveScoreItem, stats, updateStats, updateGraphRating, scoreRatingOptions, index, editScoreRatingOption, key, scoreRating } = props
    const { assessmentFilters } = useContext(NormalizerContext)
    const handleOnDragEnd = normalizerHooks.useHandleOnDragEnd(index);

    const columns = graphsHooks.useColumns()

    const fluencyStatsOptions = queryOptions({
        queryKey: [qc.fluencyStats, scoreRating.grade, scoreRating.semester],
        queryFn: async () => {
            const filters = {
                ...assessmentFilters,
                category: assessmentFilters.category,
                grade_of_allocation: scoreRating.grade,
                semester: scoreRating.semester
            }
            let { data } = await getFluencyStatsFetch({ assessmentFilters: filters })
            let graph = data.graph
            updateStats(graph)
            return data
        },
        refetchIntervalInBackground: false,
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 0
    })

    const fluencyStats = useQuery(fluencyStatsOptions)

    const table = chain(stats)
        .values() // קח את הערכים מהאובייקט
        .groupBy('rating_index')
        .reduce((acc, items) => {
            const countSum = sumBy(items, 'count');
            const percentSum = sumBy(items, 'percent');
            const min_value = items[0].key
            const max_value = last(items).key

            acc.range[items[0].rating_index] = ` ${max_value} - ${min_value}`
            acc.count[items[0].rating_index] = countSum;
            acc.percent[items[0].rating_index] = percentSum.toFixed()

            return acc;
        }, { range: {}, count: {}, percent: {}, }) // התחל עם אובייקטים ריקים
        .value(); // קבל את התוצאה

    const fluencyTable = {
        results: map(table, (value, key) => ({ ...value, key }))
    }

    const isActiveItem = activeScoreRatingIndex == index

    return (
        <>
            <GraphStyles edit={isActiveItem}>
                <div className='sc-header-item'>
                    <div className='sc-detail'>
                        <Chip borderColor={thirdly}>{scoreRating.grade_name}</Chip>
                        <Chip borderColor={thirdly}>{semesters[scoreRating.semester - 1].name}</Chip>
                        {!!fluencyStats.data?.assessment_count && <Chip borderColor={thirdly}>{`${fluencyStats.data?.assessment_count} מבדקים`}</Chip>}
                    </div>

                    <div className='sc-section-actions'>
                        <IconButtonGroup
                            selected={scoreRatingOptions?.showTable}
                            icon={TableIcon}
                            action={() => editScoreRatingOption(index, "showTable")}
                            iconSize={{
                                width: 20,
                                height: 20
                            }}
                        />
                        <IconButtonGroup
                            selected={scoreRatingOptions?.showChildren}
                            icon={ChildrenIcon}
                            action={() => editScoreRatingOption(index, "showChildren")}
                            iconSize={{
                                width: 20,
                                height: 20
                            }}
                        />
                        <IconButtonGroup
                            selected={index == activeScoreRatingIndex}
                            icon={EditIcon}
                            action={() => changeActiveScoreItem(index)}
                            iconSize={{
                                width: 20,
                                height: 20
                            }}
                        />
                    </div>
                </div>
                <div className='sc-table'>
                    {fluencyTable && scoreRatingOptions?.showTable &&
                        <Table
                            hideSorting
                            hidePagination
                            hideToolbar
                            data={fluencyTable}
                            columns={columns}
                        />}
                </div>
                <div className='wrap-graph'>
                    <div className='graph'>
                        <StatsBar
                            // showArrows
                            showFooter
                            handleOnDragEnd={handleOnDragEnd}
                            setShowStartBar={() => editScoreRatingOption(index, "showStartBar")}
                            setShowEndBar={() => editScoreRatingOption(index, "showEndBar")}
                            isDrag={isActiveItem}
                            showStartBar={scoreRatingOptions?.showStartBar}
                            showEndBar={scoreRatingOptions?.showEndBar}
                            graphKey={key}
                            text={"אחוז התלמידים"}
                            graphText={"מספר מילים לדקה"}
                            disabled={false}
                            stats={stats}
                            sourceStats={fluencyStats.data?.graph}
                            updateStats={updateStats}
                            scoreRating={scoreRating}
                        />
                    </div>
                </div>
            </GraphStyles>
            {scoreRatingOptions?.showChildren &&
                <GraphStyles edit={isActiveItem}>
                    <StatsBar
                        // fullWidth
                        showArrows
                        // showFooter
                        handleOnDragEnd={handleOnDragEnd}
                        setShowStartBar={() => editScoreRatingOption(index, "showStartBar")}
                        setShowEndBar={() => editScoreRatingOption(index, "showEndBar")}
                        isDrag={isActiveItem}
                        showAllChildren={true}
                        showStartBar={scoreRatingOptions?.showStartBar}
                        showEndBar={scoreRatingOptions?.showEndBar}
                        graphKey={key}
                        text={"אחוז התלמידים"}
                        graphText={"מספר מילים לדקה"}
                        disabled={false}
                        stats={stats}
                        sourceStats={fluencyStats.data?.graph}
                        updateStats={updateStats}
                        scoreRating={scoreRating}
                    />
                </GraphStyles>
            }
        </>
    )
    // }
    // })
    // , (prevProps, nextProps) => {
    //     // השוואה רק על item, לא על otherProp

    //     return isEqual(prevProps.stats, nextProps.stats) &&
    //         isEqual(prevProps.key, nextProps.key) &&
    //         isEqual(prevProps.index, nextProps.index) &&

    //         //  isEqual(prevProps.index, nextProps.index) ||
    //         //     isEqual(prevProps.stats, nextProps.stats) ||
    //         isEqual(prevProps.scoreRatingOptions, nextProps.scoreRatingOptions)
    //     //  ||
    //     //     isEqual(prevProps.scoreRating, nextProps.scoreRating)

});

export default ScoreRatingItem