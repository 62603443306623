import dayjs from "dayjs"


class TestTaker {

    constructor(testTaker) {
        const { gender, languages, test_taker_id, dob, affiliation, affiliation_name, tz, grade_name, grade, t_class_name, affiliation_institution_code, t_class, first_name, last_name } = testTaker

        this.first_name = first_name
        this.last_name = last_name
        this.tz = tz

        this.test_taker_id = test_taker_id
        this.dob = dayjs(new Date(dob))
        this.gender = gender
        this.languages = languages
        if (grade)
            this.grade = { id: grade, name: grade_name }
        if (t_class)
            this.t_class = { id: t_class_name, name: t_class_name }
        if (affiliation)
            this.school = { id: affiliation, name: affiliation_name, institution_code: affiliation_institution_code }
    }
}

export default TestTaker