import React from 'react'
import { pick } from 'lodash'
import { WrapIndication, IndicationLabel } from './Style'
import { naLabel, ratingListLabel, ratingListTheme } from 'keys/rating'
import { gTheme } from 'styles/theme'
import { statuses } from 'keys'
import { Tooltip } from '@mui/material'
import { CellWithTooltipStyles } from 'components/Table/Style'

const RateAndAccuracy = (props) => {
    const { row, column } = props
    const value = row.original
    const { meta } = column.columnDef

    const { rating, status } = value || {}
    const { field, precent, index } = meta || {}

    const ratingFields = pick(rating, [index])
    let ratingIndex = ratingFields[index]
    let currentScoreRatingMethod

    if (status === statuses.brokenFile)
        currentScoreRatingMethod = {
            label: naLabel
        }

    else {
        currentScoreRatingMethod = {
            ratingLabel: ratingListLabel?.[ratingIndex - 1],
            themeColor: ratingListTheme?.[ratingIndex - 1],
            label: rating?.[field] ? `${rating?.[field]}${precent ? "%" : ""}` : ""
        }
    }

    const { label, themeColor, ratingLabel } = currentScoreRatingMethod

    return (
        <CellWithTooltipStyles size={precent ? "md" : "sm"}>
            <Tooltip title={ratingLabel} placement='left'>
                <WrapIndication>
                    <IndicationLabel color={gTheme?.[themeColor]?.main || 'ligthGray'}>
                        {label && `${label}`}
                    </IndicationLabel>
                </WrapIndication>
            </Tooltip>
        </CellWithTooltipStyles>
    )
}

export default RateAndAccuracy