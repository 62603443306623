import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dataGridModel, queryClient as qc } from 'keys';
import { assessmentsHooks } from '.';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { initialPage } from 'keys/pagination';
import { getAssessmentReportFetch } from 'store/middlewares/assessment.crud';
import { Table } from 'components';
import { WrapDataGrid } from 'components/DataGrid/Style';
import { map } from 'lodash';
import AssessmentFiltersHeader from './AssessmentFiltersHeader';

const AssessmentsC = () => {
    const [assessmentFilters, setAssessmentFilters] = useState({
        year: null,
        semester: null,
        assessment_def: null,
        gender: null,
        category: null,
        grade_of_allocation: null
    })
    const { headerFilters } = useSelector(state => state.header)
    const { testTaker } = useSelector(state => state.testTaker)

    const [page, setPage] = useState(initialPage)
    const [sorting, setSorting] = useState([])
    const [searchVal, setSearchVal] = useState("")

    const headerBtns = assessmentsHooks.useHeaderBtns()
    const headerItems = assessmentsHooks.useHeaderItems()
    const columns = assessmentsHooks.useColumns()

    const assessmentOptions = queryOptions({
        queryKey: [qc.report, page, sorting, headerFilters, testTaker],
        queryFn: async ({ signal }) => {
            const { data } = await getAssessmentReportFetch({ assessmentFilters, headerFilters, testTaker, signal, page, sorting, search: searchVal, model: qc.report })
            return data
        }
    })

    const assessments = useQuery(assessmentOptions)

    const refetchData = () => {
        assessments.remove?.()
        assessments.refetch()
    }

    // useEffect(() => {
    //     refetchData()
    // }, [
    //     //onChange header filters
    //     headerFilters,
    //     //for all assessment for specific testTaker
    //     testTaker
    // ])

    return (
        <WrapDataGrid>
            <AssessmentFiltersHeader
                refetchData={refetchData}
                headerItems={[...map(headerItems), ...headerBtns]}
                loading={assessments.isFetching}
                assessmentFilters={assessmentFilters}
                setAssessmentFilters={setAssessmentFilters}
            />
            <Table
                withTitle
                loading={assessments.isFetching && !assessments.data}
                data={assessments.data}
                columns={columns}
                page={page}
                setPage={setPage}
                visibilityModelKey={dataGridModel.assessments}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                sorting={sorting}
                setSorting={setSorting}
                queryKey={assessmentOptions.queryKey}
            />
        </WrapDataGrid>
    );
}

export default AssessmentsC
