import { keyBy } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { actions } from 'store'
import { adminAssessments, allAssessments, app, assessmentDefs, assessments, audioCutting, childDevelopment, dashboard, learningResources, linguisticLab, makingRecordAssessments, markingWords, normalizer, testTakers, textReadability, transcription, users } from "components/Router/routes"
import { replaceId } from "utils/formatter"
import { useContext } from "react"
import { SideBarContext } from "components/SideBar/SideBarContext"

const useHandleMenuItem = () => {
    const { pathname } = useLocation()
    const { controllers } = useSelector(state => state.general)

    const { setSelectedSidebarIndex, setSelectedSubSidebarIndex } = useContext(SideBarContext)

    const dispatch = useDispatch()

    const changeSelectedSidebarItem = (menuItems) => {
        const keyMenuItems = keyBy(menuItems, "path")

        switch (pathname) {
            case app.dashboard:
                setSelectedSidebarIndex(keyMenuItems[dashboard].index)
                break
            case app.users:
                setSelectedSidebarIndex(keyMenuItems[users].index)
                break
            case app.assessments:
                setSelectedSidebarIndex(keyMenuItems[assessments].index)
                break
            case app.testTakers:
                setSelectedSidebarIndex(keyMenuItems[testTakers].index)
                break
            case app.createAssessment:
                setSelectedSidebarIndex(keyMenuItems[allAssessments].index)
                break
            case app.allAssessments:
                setSelectedSidebarIndex(keyMenuItems[allAssessments].index)
                break
            case app.learningResources:
                setSelectedSidebarIndex(keyMenuItems[learningResources].index)
                break
            case app.audioTranscription:
                setSelectedSidebarIndex(keyMenuItems[learningResources].index)
                break
            case app.markingWordsAssessments:
                setSelectedSidebarIndex(keyMenuItems[adminAssessments].index)
                setSelectedSubSidebarIndex(keyMenuItems[adminAssessments].subItems[0].index)
                break
            case app.audioCuttingAssessments:
                setSelectedSidebarIndex(keyMenuItems[adminAssessments].index)
                setSelectedSubSidebarIndex(keyMenuItems[adminAssessments].subItems[1].index)
                break
            case app.transcriptionAssessments:
                setSelectedSidebarIndex(keyMenuItems[adminAssessments].index)
                setSelectedSubSidebarIndex(keyMenuItems[adminAssessments].subItems[2].index)
                break
            case app.textReadability:
                setSelectedSidebarIndex(keyMenuItems[linguisticLab].index)
                setSelectedSubSidebarIndex(keyMenuItems[linguisticLab].subItems[0].index)
                break
            case app.normalizer:
                setSelectedSidebarIndex(keyMenuItems[childDevelopment].index)
                setSelectedSubSidebarIndex(keyMenuItems[childDevelopment].subItems[0].index)
                break
        }

        switch (replaceId(pathname, ":assessmentID")) {
            case app.assessmentViewById:
                setSelectedSidebarIndex(keyMenuItems[assessments].index)
                break
            case app.assessmentsById:
                setSelectedSidebarIndex(keyMenuItems[assessments].index)
                break
            case app.matchingLearningById:
                setSelectedSidebarIndex(keyMenuItems[assessments].index)
                break
            case app.adminAssessmentById:
                setSelectedSidebarIndex(keyMenuItems[adminAssessments].index)
                break
            case app.markingWordsById:
                setSelectedSubSidebarIndex(keyMenuItems[adminAssessments].subItems[0].index)
                setSelectedSidebarIndex(keyMenuItems[adminAssessments].index)
                break
            case app.audioCuttingById:
                setSelectedSubSidebarIndex(keyMenuItems[adminAssessments].subItems[1].index)
                setSelectedSidebarIndex(keyMenuItems[adminAssessments].index)
                break
            case app.transcriptionById:
                setSelectedSubSidebarIndex(keyMenuItems[adminAssessments].subItems[2].index)
                setSelectedSidebarIndex(keyMenuItems[adminAssessments].index)
                break
        }

        switch (replaceId(pathname, ":testTakerID")) {
            case app.createAssessmentRecordById:
                setSelectedSidebarIndex(keyMenuItems[testTakers].index)
                break
            case app.uploadAudioFileById:
                setSelectedSidebarIndex(keyMenuItems[testTakers].index)
                break
        }

        switch (replaceId(pathname, ":assessmentDefID")) {
            case app.assessmentDefsById:
                setSelectedSidebarIndex(keyMenuItems[assessments].index)
                break
        }

        if (pathname.includes(makingRecordAssessments))
            setSelectedSidebarIndex(keyMenuItems[allAssessments].index)
    }

    return changeSelectedSidebarItem

}

export default useHandleMenuItem