import React from 'react'
import AssessmentRecord from 'components/School/MakingRecordAssessments/AssessmentRecord'
import {
    AssessmentDef,
    Page
} from 'components'
import { audioTranscriptionRQ } from 'store/hooks'
import { Button } from 'components/Form'
import { useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { correct, incorrect } from 'keys/types'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { CircularProgress } from '@mui/material'
import { RemoveRedEye } from '@mui/icons-material'
import { useSelector } from 'react-redux'

const AudioTranscription = () => {
    const { mutateAsync: whisperRQ, isPending: whisperLoading } = audioTranscriptionRQ.useWhisper()
    const { mutateAsync: compareRQ, isPending: compareLoading } = audioTranscriptionRQ.useCompare()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { assessmentDef } = useSelector(state => state.assessment)

    const transcribeAndCompareLoading = whisperLoading || compareLoading

    const upload = async (blobURL) => {
        const { data: whisperData } = await whisperRQ(blobURL)

        const requestData = {
            spoken: whisperData.text,
            original: assessmentDef?.title + " " + assessmentDef.text
        }

        const { data: compareData } = await compareRQ(requestData)

        const nWords = []
        compareData.map((word) => {
            const { identical, reference, spoken } = word
            nWords.push({
                status: identical ? correct : incorrect,
                word_def_text: reference,
                transcription: spoken,
                word_def: "AUDIO_TRANSCRIPTION"
            })
        })

        dispatch(actions.setWords(nWords))
        navigate(app.assessmentView)
    }

    return (
        <Page>
            <AssessmentRecord
                customActions={{
                    upload: {
                        onClick: (blobURL) => upload(blobURL),
                        icon: {
                            icon: RemoveRedEye,
                            iconColor: "gray"
                        },
                        label: "",
                        disabled: transcribeAndCompareLoading,
                        loading: transcribeAndCompareLoading
                    }
                }}
            />
        </Page>
    )
}

export default AudioTranscription