import WordsData from './Words.data'
import useAssessmentCategory from './useAssessmentCategory'
import useInsertion from './useInsertion'
import useTimePoint from './useTimePoint'
import useEnd from './useEnd'
import useOmission from './useOmission'
import useError from './useError'
import useRepitition from './useRepitition'

export { WordsData }
export default { useError, useRepitition, useOmission, useEnd, useAssessmentCategory, useInsertion, useTimePoint }