import AudioPlayer from "../AudioPlayer"


const usePlayCell = () => {
    const playField = {
        accessorKey: 'audio_file_url',
        header: "נגן",
        meta: {
            width: "250px",
            height: "35px",
        },
        cell: AudioPlayer,
        enableSorting: false
    }

    return playField
}

export default usePlayCell