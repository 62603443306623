import React, { useCallback, useContext, useEffect } from 'react'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { NormalizerContext } from './NormalizerContext'
import AssessmentFiltersHeader from 'components/School/Assessments/AssessmentFiltersHeader'
import { assessmentsHooks } from 'components/School/Assessments'
import { NormalizerStyles } from './Style'
import { FixedSizeList as List } from 'react-window';
import { Divider } from '@mui/material'
import { thirdly2 } from 'styles/theme'
import { LightButton, Space, WrapHeader } from 'styles/GlobalStyle'
import { AutoComplete } from 'components/Form'
import { normalizerHooks } from '.'
import { changeItem } from 'utils/array'
import { ScoreRatingItem } from '.'
import { useQueryClient } from '@tanstack/react-query'
import { queryClient as qc } from 'keys'
import { chain, cloneDeep, groupBy, last } from 'lodash'

const Normalizer = () => {
    const { stats, setStats, scoreRatingOptions, setScoreRatingOptions, assessmentFilters, setAssessmentFilters, scoreRatingSemesters } = useContext(NormalizerContext)

    const headerItems = assessmentsHooks.useHeaderItems()
    const headerBtns = normalizerHooks.useHeaderBtns()
    const queryClient = useQueryClient()

    const updateGraphs = () => {
        let graph = cloneDeep(stats)
        const ratingFirstObjects = Object.entries(stats).filter(([key, value]) => value.rating_index === 5);

        const lastTenKeys = ratingFirstObjects.slice(-10).map(([key]) => key);
        Object.entries(graph).forEach(([key, value]) => {
            if (value.rating_index === 5 && !lastTenKeys.includes(key)) {
                value.hideStartBar = true;
            }
        });

        let endItems = 0
        Object.entries(graph).forEach(([key, value]) => {
            if (value.rating_index === 1) {
                if (endItems > 9) {
                    value.hideEndBar = true;
                }
                endItems++
            }
        });

        setStats(stats)
    }

    const editScoreRatingOption = useCallback((index, key) => {
        let scOptions = { ...scoreRatingOptions[index] }
        scOptions[key] = !scOptions[key]
        if (key === "edit") {
            // scOptions.showChildren = true

            //edit = false, change the show start and end bar to false
            if (!scOptions.edit) {
                // updateGraphs(index)
                scOptions.showStartBar = false
                scOptions.showEndBar = false
            }
        }

        const nScoreRatingOptions = changeItem(scoreRatingOptions, index, scOptions)
        setScoreRatingOptions(nScoreRatingOptions)
    }, [scoreRatingOptions, stats])

    const groupByRating = (statsToGroup) => {
        const nStats = groupBy(Object.entries(statsToGroup), ([key, value]) => value.rating_index);
        const groupStats = Object.entries(nStats).reduce((acc, [rating, items]) => {
            acc[parseInt(rating)] = items.map(([key, value]) => ({ key, ...value }));
            return acc;
        }, {});

        return groupStats
    }

    const updateGraphRating = useCallback((index, updatedGraph) => {
        let nStats = [...stats]
        let prevIndex = index - 1
        let nextIndex = index + 1

        let nextData = updatedGraph
        while (prevIndex >= 0) {
            let currentData = { ...nStats[prevIndex] }
            let gSourceGraph = groupByRating(currentData)

            for (let i = 5; i > 0; i--) {
                const gPrev = groupByRating(currentData)
                const gNext = groupByRating(nextData)

                if (gPrev[i]) {
                    const graphKey = last(gPrev[i]).key
                    const nextGraphKey = last(gNext[i]).key

                    if (Number(nextGraphKey) < Number(graphKey) && i != 1) {
                        for (let j = nextGraphKey + 1; j <= graphKey; j++) {
                            currentData[j] = {
                                key: j,
                                count: 0, percent: 0,
                                ...currentData[j],
                                rating_id: currentData[graphKey].rating_id - 1,
                                rating_index: currentData[graphKey].rating_index - 1
                            }
                        }
                    }
                }
                else {
                    let startKey = gNext[i][0].key
                    let endKey = last(gNext[i]).key
                    const rating_index = gNext[i][1].rating_index
                    const rating_id = gSourceGraph[rating_index][1].rating_id

                    for (let j = startKey; j <= endKey; j++)
                        currentData[i] = {
                            key: j,
                            count: 0,
                            percent: 0,
                            ...currentData[j],
                            rating_id: rating_id,
                            rating_index: rating_index
                        }
                }
            }
            nStats[prevIndex] = currentData
            prevIndex--
            nextData = currentData
        }

        let prevData = updatedGraph
        while (nextIndex <= stats.length - 1) {
            let currentData = { ...nStats[nextIndex] }
            let gSourceGraph = groupByRating(currentData)

            for (let i = 5; i > 0; i--) {
                const gNext = groupByRating(currentData)
                const gPrev = groupByRating(prevData)

                if (gNext[i]) {
                    const graphKey = last(gNext[i]).key
                    if (!gPrev[i])
                        debugger
                    const prevGraphKey = last(gPrev[i]).key

                    if (Number(prevGraphKey) > Number(graphKey) && i != 1) {
                        for (let j = graphKey; j < prevGraphKey + 1; j++)
                            currentData[j] = {
                                key: j,
                                count: 0,
                                percent: 0,
                                ...nextData[j],
                                rating_id: currentData[graphKey].rating_id,
                                rating_index: currentData[graphKey].rating_index
                            }
                    }
                }
                else {
                    let startKey = gPrev[i][0].key
                    let endKey = last(gPrev[i]).key
                    const rating_index = gPrev[i][1].rating_index
                    const rating_id = gSourceGraph[rating_index][1].rating_id

                    for (let j = Number(startKey); j <= Number(endKey); j++)
                        currentData[j] = {
                            key: j,
                            count: 0,
                            percent: 0,
                            ...currentData[j],
                            rating_id: rating_id,
                            rating_index: rating_index
                        }
                }
            }
            nStats[nextIndex] = currentData
            nextIndex++
            prevData = currentData
        }

        setStats(nStats)
    }, [scoreRatingOptions, stats])

    const refetchData = useCallback(async () => {
        queryClient.removeQueries([qc.fluencyStats])
        await scoreRatingSemesters.refetch()
        queryClient.invalidateQueries([qc.fluencyStats])
    }, [])

    const updateStats = useCallback((index, val) => {
        setStats(prevStats => {
            const nStats = changeItem(prevStats, index, val);
            return nStats;
        });
    }, [stats])

    useEffect(() => {
        return (() => {
            queryClient.removeQueries()
        })
    }, [])

    const Row = ({ index, style }) => {
        const scoreRating = scoreRatingSemesters.data[index];
        return (
            <div style={style}>
                <ScoreRatingItem
                    key={index}
                    updateGraphRating={updateGraphRating}
                    graphKey={index}
                    editScoreRatingOption={editScoreRatingOption}
                    index={index}
                    scoreRating={scoreRating}
                    scoreRatingOptions={scoreRatingOptions[index]}
                    stats={stats[index]}
                    updateStats={(val) => updateStats(index, val)}
                />
            </div>
        );
    };

    return (
        <NormalizerStyles>
            <WrapDataGrid>
                <AssessmentFiltersHeader
                    searchLabel="הצג"
                    disabledSearch={!assessmentFilters.category}
                    refetchData={refetchData}
                    loading={scoreRatingSemesters.isFetching}
                    headerItems={[{
                        ...headerItems.category,
                        multiple: false
                    },
                        // {
                        //     id: 0, placeholder: "שיטה",
                        //     options: ,
                        //     name: "category",
                        //     component: "autoComplete",
                        //     labelKey: "label",
                        //     multiple: true,
                        // }
                    ]}
                    showLoading={true}
                    assessmentFilters={assessmentFilters}
                    setAssessmentFilters={setAssessmentFilters}
                />
                <Divider sx={{ borderColor: thirdly2 }} />
                <Space />
                <div className='sc-actions'>
                    <AutoComplete
                        options={[{ id: "fluency", name: "קצב" }, { id: "accuracy", name: "דיוק" }]}
                        input={{
                            value: { id: "fluency", name: "קצב" }
                        }}
                        disabled
                        placeholder="סוג הבדיקה"
                        border
                        placeholder="סוג בדיקה"
                        width={110}
                    />
                    <div className='sc-header-btns'>
                        {headerBtns.map((act, index) =>
                            <LightButton key={index} size="small" systemUI  {...act} />
                        )}
                    </div>
                </div>
                <Space />
                {/* <div className='sc-sections'>
                    <List
                        height={300} // גובה התצוגה
                        itemCount={scoreRatingSemesters.data?.length || 0}
                        itemSize={300}
                        width={'100%'} // רוחב התצוגה
                    >
                        {Row}
                    </List>
                </div> */}
                <div className='sc-sections'>
                    {scoreRatingSemesters.data?.map((scoreRating, index) => {

                        return (
                            <ScoreRatingItem
                                key={index}
                                updateGraphRating={updateGraphRating}
                                graphKey={index}
                                editScoreRatingOption={editScoreRatingOption}
                                index={index}
                                scoreRating={scoreRating}
                                scoreRatingOptions={scoreRatingOptions[index]}
                                stats={stats[index]}
                                updateStats={(val) => updateStats(index, val)}
                            />
                        )

                    })}
                </div>
            </WrapDataGrid>
        </NormalizerStyles>
    )
}

export default Normalizer