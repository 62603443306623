import React from 'react'
import { Button } from 'components/Form'
import { assessmentRQ } from 'store/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'

const CreateAssessmentBtn = (props) => {
    const { test_taker_id, disabled, numAssessments } = props

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const { mutateAsync: bulkCreateByLevel, isPending: bulkCreateByLevelLoading } = assessmentRQ.useBulkCreateByLevel(test_taker_id)

    return (
        <Button
            className="action_btn"
            direction="ltr"
            label={numAssessments ? "התאימו לי אבחון נוסף" : "התאימו לי אבחון"}
            action={async () => {
                await bulkCreateByLevel()
                if (pathname != app.dashboard)
                    navigate(app.allAssessments)
            }}
            disabled={disabled}
            color="secondary"
            disabledColor="secondary"
            loading={bulkCreateByLevelLoading}
        />
    )
}

export default CreateAssessmentBtn