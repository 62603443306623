import { useCallback } from "react";
import { statuses } from 'keys'
import { actions } from "store";
import { useDispatch } from "react-redux";
import { app } from "components/Router/routes";
import { useNavigate } from "react-router-dom";

const useColumns = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const goPerform = useCallback((item) => {
        const { test_taker_id, assessment_group } = item

        const currentAssessmentGroup = assessment_group.map(ass => {
            return {
                ...ass,
                complete: (!ass.audio_file_url || ass.status === statuses.brokenFile) ? false : true
            };
        });

        const sortedAssessmentGroup = currentAssessmentGroup.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));

        dispatch(actions.setTestTaker({ test_taker_id: test_taker_id }))

        const currentIncompleteAssessment = sortedAssessmentGroup.find((ass) => !ass.complete)
        dispatch(actions.setAssessment(currentIncompleteAssessment))

        navigate(`${app.makingRecordAssessments}?assessment=${currentIncompleteAssessment.id}`, {
            state: {
                assessmentsGroup: sortedAssessmentGroup
            }
        });
    }, [])



    return goPerform
}

export default useColumns