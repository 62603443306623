import React, { useState } from 'react'
import Assessments from './Assessments'

const WarpAssessmentsContext = (props) => {
    const [viewPercent, setViewPercent] = useState({
        fluency: false,
        accuracy: false
    })

    const commonState = {
        viewPercent, setViewPercent
    }

    return (
        <AssessmentsContext.Provider value={commonState}>
            <Assessments {...props} />
        </AssessmentsContext.Provider>
    )
}

export const AssessmentsContext = React.createContext()
export default WarpAssessmentsContext