import React, { useContext, useEffect, useState } from 'react'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { useSelector } from 'react-redux'
import { useTheme } from 'styles'
import { labHooks, WordDefsWithFrequency, WordFrequency } from '.'
import { Tabs } from 'components'
import { isEmpty } from 'lodash'
import { IconButton } from '@mui/material'
import { actions } from 'store'
import { useDispatch } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { queryClient as qc } from 'keys'
import { CustomSection, SpaceBetween, XSSpaceBetween } from 'styles/GlobalStyle'
import { LinguisticLabContext } from './LinguisticLabContext'
import { TabButton } from 'components/Form'
import { GraphicEq, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { thirdly2 } from 'styles/theme'
import { RatingDials } from 'components/Rating'
import { LabStyles } from './Style'
import { assessmentsHooks } from 'components/School/Assessments'
import AssessmentFiltersHeader from 'components/School/Assessments/AssessmentFiltersHeader'
import Graphs from './Graphs'

const LinguisticLab = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const [openGraphs, setOpenGraphs] = useState(false)

    const { selectedTab, setSelectedTab, assessmentFilters, setAssessmentFilters } = useContext(LinguisticLabContext)

    const { Layout } = useTheme()

    const { frequencyItems } = labHooks.constants
    const { data: frequency, refetch: refetchFrequency, isFetching: getFrequencyLoading } = labHooks.useFrequency()

    const tabs = labHooks.useTabs()
    const headerItems = assessmentsHooks.useHeaderItems()

    const header = [
        headerItems.grade_of_allocation,
        headerItems.semester,
        { ...headerItems.category, multiple: false },
        {
            ...headerItems.assessment_def,
            multiple: false,
            input: {
                ...headerItems.assessment_def.input,
                value: isEmpty(assessmentFilters?.assessment_def) ? null : assessmentFilters?.assessment_def
            }
        }
    ]


    const removeQueries = () => {
        queryClient.removeQueries({ queryKey: [qc.assessmentFrequency] });
    }

    const cleanData = () => {
        dispatch(actions.cleanAssessmentFilters({}))
        removeQueries()
    }

    const handleTab = (index) => {
        setSelectedTab(index)
    }

    useEffect(() => {
        return () => {
            cleanData()
        }
    }, [])

    useEffect(() => {
        if (frequency)
            refetchFrequency()
    }, [selectedTab])

    return (
        <LabStyles>
            <WrapDataGrid>
                <AssessmentFiltersHeader
                    loading={getFrequencyLoading}
                    disabledSearch={!assessmentFilters.assessment_def}
                    headerItems={header}
                    refetchData={refetchFrequency}
                    showLoading={true}
                    assessmentFilters={assessmentFilters}
                    setAssessmentFilters={setAssessmentFilters}
                />


                <div className='wrap-accuracy-tabs'>

                    {isEmpty(frequency) ||
                        <div className='accuracy-tabs'>
                            <Tabs tabs={tabs} handleTab={handleTab} />
                        </div>}

                    <div className='open-graph-icon'>
                        <IconButton onClick={() => setOpenGraphs(true)}>
                            <GraphicEq />
                        </IconButton>
                    </div>
                </div>
                {isEmpty(frequency) ||
                    <React.Fragment>
                        <RatingDials
                            buttonSize={{ width: 210 }}
                            levels={frequencyItems} label="דירוג קריאוּת (readability):" />


                        <div style={{ ...Layout.col, ...Layout.lrGap, ...Layout.grow, ...Layout.overflow }}>
                            <CustomSection $border={thirdly2} $shrink height={50} $flex align="center">
                                {`תוצאות מבוססות  על ${frequency?.assessment_count} מבדקים על פי הסינון`}
                            </CustomSection>

                            <div style={{ ...Layout.grow, ...Layout.flex, ...Layout.fullHeight, ...Layout.lcGap, ...Layout.overflow }}>

                                <WordDefsWithFrequency
                                    {...frequency}
                                />

                                {/* <div style={{ width: "140px", flexShrink: 0, ...Layout.alignItemsEnd, ...Layout.flex }}>
                            <Levels levels={frequencyItems} title="אחוזי קריאות:" />
                        </div> */}
                            </div>
                        </div>
                    </React.Fragment>
                }

                {/* Left Drawer Graph*/}
                <Graphs
                    open={openGraphs}
                    setOpen={setOpenGraphs}
                    assessmentFilters={assessmentFilters}
                    wordDefCount={frequency?.word_defs.length} />

                {/* Bottom Drawer Stats */}
                <WordFrequency />
            </WrapDataGrid>
        </LabStyles>
    )
}

export default LinguisticLab
