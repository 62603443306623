import React from "react";
import { WrapParagraph } from './Style'
import { useSelector } from "react-redux";
import { InsertionIcon, Word } from '.'
import { types } from "keys";
import { Add } from "@mui/icons-material";

const Paragraph = (props) => {
    const { words, title, settings, startIndex, showAll } = props
    const { markOption, viewResults } = settings || {}
    const { assessment } = useSelector(state => state.assessment)
    const { endPoint } = assessment || {}

    return (
        <WrapParagraph $title={title} $markOption={markOption}>
            {words?.map((word, index) => {
                const { id, word_def_text, transcription, customStatus, minorError, removed, time_point, is_time_point_word, status, word_def_fe_details = {}, pointTime } = word
                const { break_line } = word_def_fe_details || {}
                let renderData = true
                if (endPoint?.index)
                    renderData = index + startIndex <= endPoint.index - 1 //index starts with 1 do we need to raise 1
                if (!viewResults) renderData = true
                const error = status === types.incorrect


                if (renderData)
                    return (
                        word.word_def_id || word.word_def ?
                            <>
                                <Word
                                    key={id}
                                    removed={removed}
                                    minorError={minorError}
                                    error={error}
                                    customStatus={customStatus}
                                    viewResults={viewResults}
                                    is_time_point_word={is_time_point_word}
                                    time_point={time_point}
                                    transcription={transcription}
                                    word_def_text={word_def_text}
                                />
                                {break_line && <span style={{ width: '100%' }}></span>}
                            </>
                            :
                            <div className="insertion-icon">
                                <InsertionIcon />
                            </div>
                    )
            })}
        </WrapParagraph>
    )
}

export default Paragraph