import React, { useState } from 'react'
import { RateAndAccuracyLabel, WrapAccordion } from './Style'
import { useTheme } from 'styles'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const RateAndAccuracy = (props) => {
    const { data } = props

    const [expand, setExpand] = useState([])
    const { Layout } = useTheme()

    const setExpandItem = (index) => {
        let nExpand = [...expand]
        nExpand[index] = !expand[index]
        setExpand(nExpand)
    }

    return (
        <div>
            {data?.map((ability, index) => {
                return (
                    <React.Fragment>
                        <WrapAccordion expanded={expand[index]}>
                            <AccordionSummary
                                sx={{
                                    ...Layout.fullHeight,
                                    pointerEvents: !ability.expand && "none"
                                }}
                                expandIcon={
                                    <ExpandMore
                                        onClick={() => setExpandItem(index)}
                                    />
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                                disabled={ability.is_future_ability}
                            >
                                <div style={{ ...Layout.rowHCenter, ...Layout.justifyContentBetween, ...Layout.fullWidth }}>
                                    <div style={{ ...Layout.rowHCenter, ...Layout.xlcGap }}>
                                        {ability.label && <RateAndAccuracyLabel>{ability.label}</RateAndAccuracyLabel>}
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails />
                        </WrapAccordion>
                    </React.Fragment>
                )
            }
            )}
        </div>
    )
}

export default RateAndAccuracy