import React from 'react'
import { ReactComponent as ViewAssessmentIcon } from 'assets/icons/ViewAssessment.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { layoutTypes } from 'keys';

const useLayoutTypeBtns = () => {

    const viewBtns = React.useMemo(
        () => [
            {
                key: layoutTypes.column,
                icon: ReportIcon,
                action: () => { },
                fontSize: "small",
                tooltip: "עמודות",
                placement: "top"
            },
            {
                key: layoutTypes.row,
                icon: ViewAssessmentIcon,
                action: () => { },
                fontSize: "small",
                tooltip: "שורות",
                placement: "top"
            }
        ]
        ,
        []
    );

    return viewBtns
}

export default useLayoutTypeBtns