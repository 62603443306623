import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { ReactComponent as ChildrenIcon } from 'assets/icons/Children.svg';
import { ReactComponent as GlobusIcon } from 'assets/icons/Globus.svg';
import { ReactComponent as GraphIcon } from 'assets/icons/Graph.svg'
import { ReactComponent as GraphDisabledIcon } from 'assets/icons/GraphDisabled.svg'
import { useSelector } from 'react-redux';
import { AssessementDistribution } from '..';
import { useContext, useMemo } from 'react';
import { ReportContext } from '../ReportContext';
import { StatsBar } from 'components'


const useViewGraphBtns = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { assessment } = useSelector(state => state.assessment)

    const { accuracyDistribution } = useContext(ReportContext) || {}

    const isDisabeldGraph = accuracyDistribution?.assessment_distribution.length < 2 || !accuracyDistribution

    const viewGraphBtns = useMemo(
        () => [
            {
                key: "single",
                icon: ChildIcon,
                tooltip: testTaker?.first_name,
                placement: "top",
                action: () => { },
                params: {
                    test_taker: assessment.test_taker_id
                },
                component: StatsBar,
                iconSize: {
                    width: 20,
                    height: 20
                }
            },
            {
                key: "t_class",
                icon: ChildrenIcon,
                placement: "top",
                tooltip: "כל הכיתה",
                action: () => { },
                params: {
                    t_class: testTaker.t_class
                },
                component: StatsBar,
                iconSize: {
                    width: 20,
                    height: 20
                }
            },
            {
                key: "grade",
                tooltip: "כל השכבה",
                placement: "top",
                icon: GlobusIcon,
                action: () => { },
                params: {
                    grade_of_allocation: assessment.reading_assessment_grade_of_allocation
                },
                component: StatsBar,
                iconSize: {
                    width: 20,
                    height: 20
                }
            },
            {
                key: "graph",
                icon: isDisabeldGraph ? GraphDisabledIcon : GraphIcon,
                tooltip: "גרף התקדמות",
                action: () => { },
                placement: "top",
                component: AssessementDistribution,
                disabled: isDisabeldGraph,
                iconSize: {
                    width: 20,
                    height: 20
                }
            },
        ], [testTaker, assessment, accuracyDistribution?.assessment_distribution]
    )

    return viewGraphBtns

}

export default useViewGraphBtns