import { AssessmentDefsCrud } from "components/AssessmentDefs/hooks"
import { app } from "components/Router/routes"
import { isEmpty } from "lodash"
import { BatteryRM } from "models/RM"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { actions } from "store"
import { assessmentDefRQ, StoreCrud } from "store/hooks"

const CreateAssessmentsCrud = () => {
    const { getAssessmentDefs } = StoreCrud()
    const { selectedAssessmentDefs } = useSelector(state => state.assessment)
    const { selectedTestTakers } = useSelector(state => state.testTaker)

    const { refetch: refetchAssessmentDefs } = assessmentDefRQ.useGetAllAssessmentDefs()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const init = async () => {
        if (isEmpty(selectedTestTakers))
            navigate(app.testTakers)
        // else getAssessmentDefs && getAssessmentDefs()
        else refetchAssessmentDefs()
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        let items = Array.from(selectedAssessmentDefs);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch(actions.setSelectedAssessmentDefs(items))
    }

    const goPerformAssessments = () => {
        navigate(app.allAssessments)
    }

    const cleanData = () => {
        dispatch(actions.setSelectedAssessmentDefs([]))
        dispatch(actions.setSelectedTestTakers([]))
    }

    const batteryAllocation = async () => {
        dispatch(actions.setCreateBatteryIsRun(true))
        // let nSelectedAssessmentDefs = selectedAssessmentDefs.map((assessmentDef) => assessmentDef.id)

        const battery = new BatteryRM({ test_takers: selectedTestTakers, assessment_defs: selectedAssessmentDefs })
        await dispatch(actions.allocationBattery(battery))
        dispatch(actions.setCreateBatteryIsRun(false))
        cleanData()
        goPerformAssessments()
    }

    const handleLayout = useCallback((val, index) => {
        let nSelectedAssessmentDefs = [...selectedAssessmentDefs]
        nSelectedAssessmentDefs[index] = { ...nSelectedAssessmentDefs[index], layoutType: val }
        dispatch(actions.setSelectedAssessmentDefs(nSelectedAssessmentDefs))
    }, [selectedAssessmentDefs])

    return {
        init,
        handleLayout,
        handleOnDragEnd,
        batteryAllocation,
        cleanData
    }
}

export default CreateAssessmentsCrud