import React from 'react';
import { WrapAudio, AudioWrapper, AudioController } from './Styled'

const AudioPlayer = (props) => {
    const
        { input, width, height } = props,
        { value } = input

        
    return (
        !value ||
        <AudioWrapper width={width} height={height}>
            <AudioController>
                <WrapAudio controls controlsList="novolume nodownload">
                    <source src={value} type="audio/mpeg" />
                    Your browser does not support the audio tag.
                </WrapAudio>
            </AudioController>
        </AudioWrapper>
    );
};

export default AudioPlayer;
