import React from 'react'
import { black } from 'styles/theme'

const StatsBar = (props) => {
    const { color, isDrag, fixed, theme, showChildren, zIndex, disabled, percent: graphPercent, value, colors, hide } = props

    return (
        <div className="half-circle"
            style={{
                // right: showChildren ? 8 : 2,
                background:
                    hide ? "transparent" :
                        `linear-gradient( -90deg, ${colors[0]}, ${colors[1]} 49%,  ${colors[2]} 51%, ${colors[3]} 51% )`
                , borderRadius: 30,
                position: "relative",
                top: 23,
                zIndex: zIndex || 10
            }}
        >
            {!hide &&
                <div style={{
                    textAlign: "center",
                    color: color, alignItems: "center", display: "flex", justifyContent: "center", fontSize: 13, borderRadius: 30, height: 22, width: 22, background: "white"
                }}>
                    {
                        // isDragging ? destination?.destination :
                        (fixed ? Number(value)?.toFixed(fixed) : value)
                    }
                    {graphPercent && "%"}
                </div>
            }
        </div>
    )
}

export default StatsBar