import React, { useState } from 'react'
import { Table } from 'components'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { userRQ } from 'store/hooks'
import { initialPage } from 'keys/pagination'
import { queryClient as qc } from 'keys'
import { userHooks } from '.'
import { useQuery } from '@tanstack/react-query'
import { getUsersFetch } from 'store/middlewares/user.crud'
import { WrapHeader } from 'styles/GlobalStyle'

const Users = () => {
    const [page, setPage] = useState(initialPage)
    const [sorting, setSorting] = useState([])
    const [searchVal, setSearchVal] = useState("")

    const columns = userHooks.useColumns()
    const users = useQuery({
        queryKey: [qc.users, page, sorting],
        refetchIntervalInBackground: false,
        queryFn: async () => {
            const { data } = await getUsersFetch({ page, sorting, search: searchVal, model: qc.users })
            return data
        }
    })

    return (
        <WrapDataGrid>
            <WrapHeader />
            <Table
                withTitle
                loading={users.isFetching && !users.data}
                data={users.data}
                page={page}
                columns={columns}
                setPage={setPage}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                sorting={sorting}
                setSorting={setSorting}
                queryKey={[qc.users, page, sorting]}
            />
        </WrapDataGrid>
    )
}

export default Users
