import React, { useContext, useEffect } from 'react'
import { Divider, IconButton, Tooltip } from '@mui/material';
import { Logout, LogoutOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Logo } from 'components'
import { WrapSideBar, Header, Content, ItemButton, WrapSubItems, WrapFooter, UserName, UserEmail, UserDetails } from './Style'
import { constants } from './hooks'
import { AuthCrud } from 'components/Auth/hooks';
import { SideBarItem } from 'components/Form';
import { app } from 'components/Router/routes';
import { SideBarContext } from 'components/SideBar/SideBarContext';
import { sidebarHooks } from '.';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { authHooks } from 'components/Auth';


const SideBar = () => {
    const { setSelectedSidebarIndex, selectedSidebarIndex, selectedSubSidebarIndex, setSelectedSubSidebarIndex } = useContext(SideBarContext)

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)

    const menuItems = sidebarHooks.useMenuItems()
    const handleMenuItem = sidebarHooks.useHandleMenuItem()
    const logout = authHooks.useLogout()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const { first_name, last_name, email } = user || {}

    const cleanData = () => {
        dispatch(actions.setTestTaker({}))
        dispatch(actions.setAssessment({}))
    }

    const changeSubItem = (subItem) => {
        const { id, path, cleanTestTaker } = subItem
        setSelectedSubSidebarIndex(id)
        cleanTestTaker && cleanData()
        navigate(path)
    }

    const changeItem = (item) => {
        const { id, path, cleanTestTaker } = item
        setSelectedSidebarIndex(id)
        if (item.subItems) {
            changeSubItem(item.subItems[0])
        }
        else {
            cleanTestTaker && cleanData()
            !item.subItems && navigate(path)
        }
    }

    const navigateApp = () => {
        navigate(app.app)
    }

    useEffect(() => {
        handleMenuItem(menuItems)
    }, [pathname])

    return (
        <WrapSideBar>
            <Header>
                <Logo action={navigateApp} width={40} height={40} />
                {user &&
                    <UserDetails>
                        <UserName>
                            {constants.welcome(user?.gender)}
                        </UserName>
                        <UserEmail>
                            {`
                            ${first_name ? first_name : ""} 
                            ${last_name ? last_name : ""}`
                            }
                        </UserEmail>
                    </UserDetails>
                }
            </Header>
            <Content>
                {menuItems.map((item, index) => {
                    const selectedItem = selectedSidebarIndex === item.index

                    return (
                        <div key={index}>
                            <SideBarItem
                                {...item}
                                action={() => changeItem(item)}
                                selected={selectedItem}
                                role="read"
                                permissionHOC="hidden"
                            />
                            {selectedItem &&
                                <WrapSubItems>
                                    {item.subItems?.map((subItem) => {
                                        const selectedItem = selectedSubSidebarIndex === subItem.index

                                        return <SideBarItem
                                            {...subItem}
                                            subItem
                                            action={() => changeSubItem(subItem)}
                                            selected={selectedItem}
                                            hideBorder
                                            role="read"
                                            permissionHOC="hidden"
                                        />
                                    })}
                                </WrapSubItems>
                            }
                        </div>
                    )
                })}
            </Content>
            <WrapFooter>
                <Divider />
                <SideBarItem
                    icon={Logout}
                    action={logout}
                    label="התנתק"
                />
            </WrapFooter>
        </WrapSideBar >
    )
}

export default SideBar