import { gender } from "keys"

const constants = {
    title: "ברוכים הבאים למערכת האבחונים",
    you: {
        [gender.male]: "לְךָ",
        [gender.female]: "לָךְ"
    },
    you2: {
        [gender.male]: "אוֹתְךָ",
        [gender.female]: "אוֹתָךְ"
    }
}

export default constants