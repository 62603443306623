import { AssessmentRM, NestedWordRM, RegionRM } from "models/RM"
import { useCallback, useContext } from "react"
import { useMutation } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { audioCuttingCompleteFetch, audioCuttingSaveDraftFetch, transcriptionCompleteFetch, transcriptionSaveDraftFetch } from "store/middlewares/markingWords.crud"
import { saveRegionsFetch } from "store/middlewares/region.crud"
import { lightDark, secondary } from "styles/theme"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { app } from "components/Router/routes"
import { TranscriptionContext } from "../TranscriptionContext"
import { assessmentRQ, wordsRQ } from 'store/hooks'
import { correct, insertion, na, omission, substitution } from "keys/types"

const useSaveActions = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { setCurrentWord, currentWord, words, vowelCodes, letterAddOns } = useContext(TranscriptionContext)

    const { assessment } = useSelector(state => state.assessment)
    const { state } = useLocation()

    const { mutateAsync: transcriptionSaveDraftRQ, isPending: saveDraftLoading } = useMutation({ mutationFn: transcriptionSaveDraftFetch })
    const { mutateAsync: transcriptionCompleteRQ, isPending: completeLoading } = useMutation({ mutationFn: transcriptionCompleteFetch })
    const { mutateAsync: saveWordRQ, isPending: saveWordLoading } = wordsRQ.useSaveWord()
    const { mutateAsync: saveAssessmentRQ, isPending: saveAssessmentLoading } = assessmentRQ.useSaveAssessment()

    const wordIndex = words?.findIndex((word) => word.word_def_id === currentWord?.word_def_id)

    const goAdminAssessments = () => {
        //TODO now working
        // dispatch(actions.setStatusFilters(state.statusFilters))
        navigate(app.transcriptionAssessments)
    }

    const saveWord = useCallback(async () => {
        let wordRM = new NestedWordRM(currentWord, vowelCodes, letterAddOns)
        await saveWordRQ(wordRM)
    }, [currentWord, wordIndex, vowelCodes])

    const prevWord = useCallback(() => {
        setCurrentWord(words[wordIndex - 1])
    }, [words, currentWord])

    const nextWord = useCallback(() => {
        if (wordIndex != words.length - 1)
            setCurrentWord(words[wordIndex + 1])
    }, [words, currentWord, wordIndex])

    const transcriptionSaveDraft = async () => {
        const data = await saveWord()
        await transcriptionSaveDraftRQ({ id: assessment.id, data })
        dispatch(actions.setIsCloseSttoper(true))
    }

    const transcriptionSaveAndNext = async () => {
        await transcriptionSaveDraft()
        nextWord()
    }

    const finalSave = useCallback(async () => {
        let no_data_count = 0
        let phonemes_decoded_count = 0
        let substitution_count = 0
        let omission_count = 0
        let insertion_count = 0
        let correct_count = 0

        words.forEach((word) => {

            if (word.status === correct)
                correct_count++


            if (word.id) {
                //count correct

                word.phonemes.forEach((phoneme, index) => {
                    const { letter_status, vowel_status, vowel_code, letter_add_ons } = phoneme

                    //count sum decoded
                    phonemes_decoded_count++
                    if (vowel_code)
                        phonemes_decoded_count++

                    //count NA
                    if (vowel_status === na)
                        no_data_count++

                    if (letter_status === na)
                        no_data_count++

                    if (letter_status === insertion) {
                        insertion_count++
                        if (vowel_code && vowel_code.toString() != '15')
                            insertion_count++
                    }

                    else if (letter_status === omission)
                        omission_count++

                    //count changes
                    else {
                        if (letter_status === substitution)
                            substitution_count++
                        if (vowel_status === substitution)
                            substitution_count++
                        letter_add_ons.forEach((add_on) => {
                            if (add_on.letter_add_on_status === substitution)
                                substitution_count++
                        })
                    }

                })
            }
        })

        const assessmentCounters = {
            no_data_count: no_data_count,
            num_differences_count: substitution_count + insertion_count + omission_count,
            phonemes_decoded_count: phonemes_decoded_count,
            substitution_count: substitution_count,
            insertion_count: insertion_count,
            correct_count: correct_count,
            omission_count: omission_count,
            word_accuracy_percentage: Math.trunc((correct_count / words.length) * 100)
        }
        return assessmentCounters
    }, [words])

    const transcriptionComplete = async () => {
        await saveWord()
        const assessmentData = finalSave()
        await transcriptionCompleteRQ({ id: assessment.id, data: assessmentData })
        dispatch(actions.setIsCloseSttoper(true))
        goAdminAssessments()
    }

    const actionBtns = [
        {
            id: 10,
            label: "הקודמת",
            disabledColor: "gray",
            disabled: !wordIndex,
            action: prevWord,
            color: "secondary",
            variant: 'outlined',
            height: "35px"
        },
        {
            id: 11,
            label: "הבאה",
            disabledColor: "gray",
            disabled: wordIndex == words?.length - 1,
            action: nextWord,
            color: "secondary",
            variant: 'outlined',
            height: "35px"
        },
        {
            id: 0,
            label: "שמירה",
            action: transcriptionSaveDraft,
            color: "primary",
            loading: saveDraftLoading,
            width: 110,
            height: "35px",
            disabled: saveDraftLoading,
            disabledColor: "gray2"
        },
        {
            id: 2,
            label: "שמירה ומעבר למילה הבאה",
            action: transcriptionSaveAndNext,
            color: "secondary",
            loading: completeLoading || saveWordLoading,
            width: 200,
            disabled: completeLoading || saveWordLoading,
            themeColor: secondary,
            disabledColor: "gray2",
            height: "35px"
        },
        {
            id: 3,
            label: "שמירת תוצאות המבדק",
            action: transcriptionComplete,
            color: "lightDark",
            loading: saveAssessmentLoading || saveWordLoading || completeLoading,
            width: 80,
            disabled: saveAssessmentLoading || saveWordLoading || completeLoading,
            themeColor: lightDark,
            disabledColor: "gray2",
            height: "35px",
        }
    ]

    return actionBtns
}

export default useSaveActions