import React, { useContext, useEffect } from 'react'
import { Alert } from 'components'
import { ApplyDataConfirmationStyles } from './Style'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { alerts } from 'keys'
import { normalizerHooks } from '.'
import { NormalizerContext } from './NormalizerContext'

const AssessmentCompletionAlert = React.memo((props) => {
    const { handleAlert } = props
    const { activeScoreItemStatus } = useContext(NormalizerContext)

    const dispatch = useDispatch()
    const applyScoreRating = normalizerHooks.useApplyScoreRating()
    const viewUpdatedScoreRating = normalizerHooks.useViewUpdatedScoreRating()
    const initScoreRating = normalizerHooks.useInitScoreRating()

    const submitBtn = (func) => {
        func()
        handleAlert(false)
    }


    const applyChangesBtn = { id: 1, label: "החל שינויים", action: () => submitBtn(applyScoreRating), color: "secondary", }
    const viewChangesBtn = { id: 0, label: "הצג שינויים", action: () => submitBtn(viewUpdatedScoreRating), color: "secondary", }
    const initBtn = { id: 2, label: "בטל ואפס", color: "thirdly", action: () => submitBtn(initScoreRating) }

    const statusBtn = {
        "edit": {
            button: viewChangesBtn,
            title: "לא הצגת שינויים",
            content: "האם ברצונך להציג שינויים?"
        },
        "view": {
            title: "לא החלת שינויים",
            content: "האם ברצונך להכיל שינויים?",
            button: applyChangesBtn
        }
    }

    const item = statusBtn[activeScoreItemStatus || "edit"]

    return (
        <Alert systemUI
            title={item.title}
            footerActions={[
                item.button,
                initBtn
            ]}
            handleAlert={handleAlert} >
            <ApplyDataConfirmationStyles>
                {/* <div className='confirmation-content'>
                    לא בצעת החלת שינויים,
                </div> */}
                <div className='confirmation-content'>
                    {item.content}
                </div>
            </ApplyDataConfirmationStyles>
        </Alert >
    )

});

export default AssessmentCompletionAlert