const get_with_last_assessment = "get_with_last_assessment"
const reports = "reports"
const test_takers = "test_takers"
const test_taker = "test_taker"
const upload_audio_file = "upload_audio_file"
const get_matching_learning = "get_matching_learning"
const get_words_nested = "get_words_nested"
const letter_add_ons = "letter_add_ons"
const vowel_codes = "vowel_codes"
const letter_descriptions = "letter_descriptions"
const confirm_reset_password = "confirm_reset_password"

const go = 'go/'
const reading_assessment = 'reading_assessment/'
const score_rating_methods = 'score_rating_methods/'
const assessments = 'assessments/'
const definitions = 'definitions/'
const testTakers = 'test_takers/'
const assessment = "assessment/"
const admin_assessments = "admin_assessments/"
const categories = "categories/"
const app_auth = "app_auth/"

const api = {
    go: go,
    reading_assessment: reading_assessment,
    assessment: assessment,
    definitions: definitions,
    testTakers: testTakers,
    get_with_last_assessment: get_with_last_assessment,
    reports: reports,
    test_takers: test_takers,
    test_taker: test_taker,
    upload_audio_file: upload_audio_file,
    get_matching_learning: get_matching_learning,
    assessments: "assessments",
    assessment: assessment,
    get_audio_file: "get_audio_file",
    report: "report",
    get_words_and_regions: "get_words_and_regions",
    save_regions: "save_regions",
    login: "login",
    logout: "logout",
    register: "register",
    resetPassword: "reset_password",
    confirm_reset_password: "confirm_reset_password",
    save_new_password: "save_new_password",
    send_verification_email: "send_verification_email",
    verify_email: "verify_email",
    is_verify_email: "is_verify_email",
    validate_tz: "validate_tz",
    validate_email: "validate_email",
    validate_username: "validate_username",
    recover_username: "recover_username",
    use_coupon_code: "use_coupon_code",
    generate_coupon_code: "generate_coupon_code"
}

export { score_rating_methods, letter_descriptions, categories, confirm_reset_password, vowel_codes, letter_add_ons, get_words_nested, app_auth, api, admin_assessments, assessments, get_matching_learning, upload_audio_file, test_taker, test_takers, reports, get_with_last_assessment, testTakers, definitions, go, reading_assessment, assessment }
export default api