import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Label, Error } from 'components/Form'
import { WrapField, WrapFieldContent, BootstrapDatePicker, WrapDatePicker } from './Styled'
import { thirdly } from 'styles/theme';

const CreateDatePicker = (props) => {
    const { placeholder, gridArea, arrayIndex, name, currentDateByDefault, systemUI = true, label, input, className, mandatory, format = "DD MM YYYY" } = props
        , { onChange, value } = input

    const handleChange = (e) => {
        onChange(dayjs(e))
    }

    let dateValue = value || null
    if (!value && currentDateByDefault)
        dateValue = dayjs(new Date())

    return (
        <WrapField className={className} gridArea={`${gridArea}_${arrayIndex}`}>
            <WrapFieldContent>
                {label && <Label label={label} mandatory={mandatory} />}
                <LocalizationProvider name={name} dateAdapter={AdapterDayjs}>
                    <BootstrapDatePicker

                        slotProps={{
                            textField: {
                                sx: {
                                    borderRadius: 35
                                },
                                InputProps: {
                                    disableUnderline: true
                                },
                                size: "small",
                                color: "thirdly",
                                variant: "filled"
                                // placeholder: placeholder ? placeholder : null
                            }
                        }}
                        color={thirdly}
                        label={placeholder}
                        name={name}
                        $systemUI={systemUI}
                        format={format}

                        value={dateValue}
                        onChange={handleChange}
                    />
                </LocalizationProvider>
            </WrapFieldContent>
            <Error {...props} />
        </WrapField>
    );
}

export default CreateDatePicker