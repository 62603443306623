import React from 'react'
import { WrapButtonGroup } from 'components/Form/Styled'
import { WrapBasicButton, WrapFullButton } from './Style'
import { statuses } from 'keys'

const StatusCell = (props) => {
    const { cell } = props
    let value = cell.getValue()
    const { complete, created, inProgress, wordsComplete, wordsInProgress } = statuses

    const progress = value === wordsComplete
    const completed = false

    const mappingStatus = {
        [inProgress]: "בסיסי",
        [complete]: "מורחב",
        [created]: "בסיסי",
        [wordsInProgress]: "בסיסי",
        [wordsComplete]: "בסיסי",
    }

    const getDynamicColor = (status) => {
        if (status)
            return 'contained'
        return 'outlined'
    }

    return (
        <WrapButtonGroup
            aria-label="Vertical button group"
            variant="text"
            size="small"
        >
            <WrapBasicButton color="lightPrimary" variant={getDynamicColor(completed)}>
                {mappingStatus.COMPLETE}
            </WrapBasicButton>
            <WrapFullButton color="lightBlue2" variant={getDynamicColor(progress)}>
                {mappingStatus.IN_PROGRESS}
            </WrapFullButton>
        </WrapButtonGroup>
    )

}

export default StatusCell