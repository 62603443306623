
const scoreRatingFilters = ({ assessmentFilters }) => {
    const { category, method } = assessmentFilters

    let params = {}

    if (category)
        params.category = category?.id || category
    if (method)
        params.method = method?.id || method

    return params
}

export { scoreRatingFilters }