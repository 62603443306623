import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'store'

const useOnPlay = () => {
    const dispatch = useDispatch()
    const { wavesurfer, isPlaying } = useSelector(state => state.wavesurfer)

    const onPlay = useCallback(() => {
        if (!isPlaying) {
            dispatch(actions.setIsPlaying(true))
            if (wavesurfer)
                wavesurfer.play()
        }
    }, [isPlaying, wavesurfer])

    return onPlay
}

export default useOnPlay 