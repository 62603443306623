import { Reply } from "@mui/icons-material";
import { Button, Fab } from "@mui/material";
import { IconButton } from "components/Form";
import { layoutTypes } from "keys";
import styled, { css } from "styled-components";
import { Center, Scroll, Shadow, FabShadow, Disabled, Removed } from "styles/GlobalCss";
import { CustomSection, WrapHeader as GlobalHeader } from "styles/GlobalStyle";
import { gray, error, light, ligthGray, white, dark, yellow, gTheme, primary, secondary, rating4 } from "styles/theme";

const pointTimeWidth = '25px'

const ReplyIcon = styled(Reply)`
transform: scaleX(-1);
`

const WrapWordsContent = styled.div`
flex-grow:1;
${Center};
height:100%;
width:100%;
`

const FabDetail = styled.div`
width:20px;
height:20px;
background:${props => props.$color};
font-size: 10px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 30px;
padding: 2px;
color: white;
font-weight: bold;
${FabShadow};
`

const Time = styled.div`
position: relative;
font-size: 20px;
`


const WordItem = styled.div`
height: 82px;
display: flex;
flex-direction: column;
justify-content: end;
`

const WrapAssessmentName = styled.div`
// flex-grow:1;
text-align:start;
`

const GoPerformAssessments = styled(Fab)`

`

const MuiButton = styled(Button)`
width:310px !important;
display: flex !important;
justify-content: space-around !important;
border-radius: 33px !important;
border: 1px solid #CCE1FF !important;
background: ${white};
color:${dark} !important;
`

const NumPoints = styled.div`
border-radius: 30px;
padding:5px;
width: 50px;
height: 50px;
background: ${yellow};
color:${white};
font-weight: bold;
font-size:14px;
display:flex;
justify-content:center;
align-items:center;

${FabShadow};
`

const WrapStudentParagraph = styled.div`
display: flex;
justify-content: center;
align-items:center;
width:100%;
height:100%;
`

const WrapMarkWords = styled.div`
display: flex;
flex-direction: column;
row-gap: 10px;
flex-grow: 1;
overflow: auto;
background:${white};
${Scroll};



`

const WrapWordText = styled.div`
display: flex;
flex-direction: column;
width:100%
`


const PointTime = styled.span`
font-weight:bold;
width:${pointTimeWidth};
display: flex;
align-items: center;
height:10px;
font-size: 18px;
`

const WrapMarkWordsContent = styled.div`
// height:auto;
${props => props.$disabled && Disabled} 
height: 100%;
`

const WrapHeaderActions = styled.div`
display: flex;
column-gap: 10px;
align-items:center;

`

const WrapScreenMarkWords = styled.div`
display: flex;
flex-direction: column;
// row-gap:10px;
height: 100%;
flex-grow: 1;
box-sizing: border-box;
flex: 1 1 auto;
overflow-y: hidden;

`

const SelectTimePointP = styled.div`
position: relative;
width: 20px;

*:focus {
  outline: none;
}


select{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border:1px solid ${ligthGray};
  color:${dark};
  background:transparent;
  border-radius:2px;
  padding:1px;
}

// border:1px solid red;
`

const SelectTimePoint = styled(SelectTimePointP)`

`

const WrapFooter = styled.div`
display: flex;
justify-content: end;
column-gap: 10px;
height:70px;
align-items: center;
`

const WrapSettings = styled.div`
display:flex;
justify-content: space-between;
width: 100%;
`

const SelectWord = styled.div`
border-radius: 30px;
position: relative;
top: 13px;
${Center};
${Shadow};
z-index: 2;
cursor:pointer;


.error-icon{
  width:12px;
  height:12px;
  color:${props => props.color};
  background:transparent;

}
`
const SelectWordP = styled(SelectWord)`
top: 3px;
left: 0px;
`

const WrapInput = styled.input`
width:27px; 
height:28px;
border:${props => `1px solid ${props.color}`};
color:${props => `${props.color}`};
border-radius:30px;
text-align: center;
`

const WordSettings = styled.span`
display: flex;
column-gap: 10px;
`

const WordBorderCss = css`
border-width: 2px;
border-style:${props => props.$customStatus ? "dashed" : "solid"};
border-color:${props => props.$error
    ? error :
    props.$customStatus ? yellow :
      ligthGray};
border-color:${props => props.$minorError && rating4}; 
${props => props.$removed && Removed}   
`

const Word = styled.div`
${Shadow};
width:100%;
padding:5px;
box-sizing: border-box;
background:${white};
display: flex;
align-items: center;
justify-content: center;
border-radius: 3px;
${WordBorderCss};
`

const WrapMarkWordsAndParagraph = css`
text-align: center;
font-size:33px;
height:100%;
`

const WrapSelectTime = styled.div`

`

const WordP = styled.span`
${WrapMarkWordsAndParagraph};
font-size:${props => props.small ? "20px" : "33px"};
font-weight:${props => props.$isPointTime && 'bold'};
`

const WrapWordList = styled.div`
${props => props.layoutType == layoutTypes.row ?
    `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  `
    :
    `
  column-count: ${props.$markOption ? 6 : 7}; 
  @media only screen and (max-width: 1800px) {
   column-count: ${props.$markOption ? 5 : 6}; 
  }
  @media only screen and (max-width: 1500px) {
   column-count: ${props.$markOption ? 4 : 5}; 
  }
  @media only screen and (max-width: 1300px) {
    column-count: ${props.$markOption ? 3 : 4}; 
  }
  @media only screen and (max-width: 1080px) {
   column-count: ${props.$markOption ? 2 : 3}; 
  }
  `
  }

column-gap: ${props => !props.$markOption && '15px'};
${Scroll}
${WrapMarkWordsAndParagraph};




.insertion-icon{
position: relative;
top: 14px;
${Center}
}
`





const WrapActionBtn = styled.div`
width:${props => props.width}px;
height:30px;
`

//change source
const WrapParagraph = styled.div`
flex-wrap: wrap;
row-gap: 5px;
align-items: end;
display: flex;
margin-top:45px;
width:80%;
column-gap: ${props => props.$markOption ? '10px' : '4px'};
overflow:auto;
${props => props.$title && `
width:100%;
justify-content: center;
font-weight: bold;
overflow:hidden;
`}
${Scroll};

.insertion-icon{
    position: relative;
    bottom: 5px;
}
`

const WrapWordContent = styled.div`
display:flex;
break-inside: avoid;
`

const WrapAddWord = styled.div`
margin-top: 15px;
padding:2px;
display: flex;
align-items: center;
`

const WrapWord = styled.div`
display: flex;
width:200px;
max-width:100%;
column-gap:7px;
display: flex;
align-items: end;
justify-content:center;
`

const WrapResult = styled.div`
display: flex;
justify-content: space-between;
`

const End = styled.div`
width:20px;
height:20px;
background:${secondary};
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 2px;
    color: white;
    font-weight: bold;
${FabShadow};
`

//change source
const WrapWordP = styled.span`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding:4px;
border-radius: 6px;
${props => props.$error &&
    WordBorderCss
  }
${props => props.$customStatus &&
    WordBorderCss
  }

${props => props.$minorError &&
    WordBorderCss
  }

${props => props.$removed && `${Removed}`} 



`

const WrapHeader = styled.div`
height:100px;
`

const HeaderSide = styled.div`
display:flex;
column-gap:7px;
align-items: center;
justify-content: ${props => props.side};
width:33%;
`

const WrapCustomSection = styled(CustomSection)`
height:${props => props.$fullHeight ? "100%" : "auto"};
`

const WrapWordsHeader = styled(GlobalHeader)`
height:50px;
justify-content:space-between;
`

export { WrapWordsHeader, WrapCustomSection, HeaderSide as Left, WrapWordsContent, HeaderSide as Right, HeaderSide as Center, MuiButton, WrapWordContent, WrapInput, WrapAddWord, WrapSettings, End, FabDetail, Time, WrapResult, WordItem, ReplyIcon, GoPerformAssessments, NumPoints, WrapHeader, WrapStudentParagraph, WrapAssessmentName, WrapHeaderActions, WrapWordText, PointTime, WordSettings, WrapSelectTime, SelectTimePointP, WrapWordP, SelectWordP, WordP, WrapScreenMarkWords, SelectTimePoint, WrapFooter, WrapMarkWordsContent, WrapActionBtn, SelectWord, Word, WrapWord, WrapMarkWords, WrapParagraph, WrapWordList }