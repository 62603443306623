import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { ChildrenGraphStyles, WrapGraph, WrapIcon, WrapNumChild, WrapGraphTexts, GraphNumber, WrapGraphItem, WrapChild, LineStyles } from './Style'
import { firstWord } from 'utils/obj'
import DraggableItem from './DraggableItem'
import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { Progress } from 'components/Record'
import { FlexCenter } from 'styles/GlobalStyle'
import { cloneDeep, forEach, isEmpty, isEqual, last, slice, uniqBy } from 'lodash'
import ratingList, { lightRating, ratingText } from 'keys/rating'
import BarItem from './BarItem'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { rating5 } from 'styles/theme'
import { rating1 } from 'styles/theme'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
    defaultDropAnimation,
} from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Draggable from './Draggable'
import { SvgIcon } from '@mui/material'

const StatsBar = (props) => {
    const { showArrows, setShowStartBar, setShowEndBar, showEndBar, showStartBar = true, updateStats, sourceStats, stats, updateGraphRating, fullWidth, graphKey, isDrag, showFooter, graphText, text, viewPercent, fixed, scoreRating, showAllChildren, percent: isPercent, disabled } = props
    const { testTaker = {} } = useSelector(state => state.testTaker)
    const [destination, setDestination] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    let prevItem = {}

    const statsArray = stats ? Object.entries({ ...stats }) : [];
    let groupedStats = statsArray.reduce((acc, [key, value]) => {// השתמש בלולאת reduce כדי לשמור על המפתחות המקוריים
        let rating = value.rating_index?.toString();
        if (!rating) {
            rating = prevItem.rating
        }
        if (!acc[rating]) {
            acc[rating] = { items: [], count: 0, percent: 0 }; // אתחול מערך אם הוא לא קיים
        }
        acc[rating].count += value.count
        acc[rating].percent += value.percent
        acc[rating].items.push({ key, ...value }); // הוסף את האובייקט עם המפתח המקורי
        prevItem = { rating: value.rating_index, key: key }//נשמור את הערך האחרון

        return acc;
    }, {});

    if (showArrows) {
        if (groupedStats[5])
            forEach(slice(groupedStats[5].items, 0, -10), item => {
                item.hideStartBar = true; // הוסף את השדה hide
            });
        if (groupedStats[1])
            forEach(slice(groupedStats[1].items, 10), item => {
                item.hideEndBar = true; // הוסף את השדה hideEndBar
            });
    }

    const sourceStatsEntries = Object.entries?.(sourceStats || {});
    const uniqueRatings = uniqBy(sourceStatsEntries, ([key, value]) => value.rating_id);// שימוש ב-Lodash כדי לשמור רק אובייקטים עם rating_id ייחודיים
    const ratingsObject = Object.fromEntries(uniqueRatings); // המרת המערך חזרה לאובייקט תוך שמירה על המפתחות

    const handleOnDragEnd = (result) => {


        const { active, over } = result;
        const sNumber = active.id, dNumber = over.id

        const nStats = cloneDeep(stats)

        let { rating_index, rating_id } = nStats[sNumber]

        nStats[dNumber].rating_id = nStats[sNumber].rating_id;

        let range = nStats[sNumber].rating_index - nStats[dNumber].rating_index
        if (!nStats[dNumber].rating_index) {
            alert("פעולה אסורה - למשבצת זו אין rating עליך לעבור אחורה וקדימה עד שהמשבצת תקבל צבע ")
            return
        }
        if (range >= 1 || range < -1) {
            alert("דלגת rating - פעולה אסורה")
            return
        }

        // עדכון האוביקטים בטווח למעלה
        let currentNumber = parseInt(sNumber);
        while (currentNumber < dNumber) {
            if (nStats[dNumber].rating_id === rating_id) {
                nStats[currentNumber].rating_id = rating_id + 1
                nStats[currentNumber].rating_index = rating_index + 1
            }
            else {
                nStats[currentNumber].rating_id = rating_id
                nStats[currentNumber].rating_index = rating_index
            }
            currentNumber++;
        }

        // עדכון האוביקטים בטווח למטה
        let prevNumber = parseInt(sNumber) - 1;
        while (prevNumber > 0 && prevNumber >= dNumber) {
            nStats[prevNumber].rating_id = rating_id
            nStats[prevNumber].rating_index = rating_index
            prevNumber--;
        }

        updateGraphRating(nStats)

        // // const ratingFirstObjects = Object.entries(nStats).filter(([key, value]) => value.rating_index === 5 && !value.hideStartBar);

        // // if (ratingFirstObjects.length < 6) {
        // //     // שלב 2: קבע את המפתחות של העשרה האחרונים
        // //     const lastTenKeys = ratingFirstObjects.slice(-5).map(([key]) => key);
        // //     // שלב 3: הוסף hide = true לאיברים שאינם בעשרה האחרונים
        // //     Object.entries(nStats).forEach(([key, value]) => {
        // //         if (value.rating_index === 5 && !lastTenKeys.includes(key)) {
        // //             value.hideStartBar = true; // הוסף את השדה hide
        // //         }
        // //         else value.hideStartBar = false
        // //     });

        // // }


        updateStats(nStats)
        // setTimeout(() => {
        setActiveId(null)
        // }, 2000);
    }

    const handleDragStart = (event) => {
        console.log(event.active.id)
        setActiveId(event.active.id);
        // if (update.destination) {
        //     setDestination({ id: update.draggableId, destination: update.destination.index });
        // }
    };

    const handleDragMove = (event) => {
        setDestination(event.over.id)
    }

    return (
        <ChildrenGraphStyles isDrag={isDrag} showAllChildren={showAllChildren}>
            {graphText &&
                <WrapGraphTexts style={{
                    position: "relative",
                    top: showAllChildren && isDrag && "41px",
                }}>
                    {showAllChildren &&
                        <div className='wrap-graph-text'>
                            <div className='graph-text'>
                                {text}
                            </div>
                        </div>
                    }
                    <div className='wrap-graph-text'>
                        <div className='graph-text'>
                            {graphText}
                        </div>
                    </div>
                    {showFooter && <div className='wrap-graph-text' />}
                </WrapGraphTexts>
            }

            <div style={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                top: showAllChildren && isDrag && "41px",
                display: "flex", flexDirection: "column", overflow: "auto"
            }}>
                <DndContext sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragMove={handleDragMove}
                    onDragEnd={handleOnDragEnd}
                >
                    <WrapGraph showFooter={showFooter} className="characters" disabled={disabled}
                    >
                        {showArrows && <div style={{ height: "69px" }} onClick={setShowStartBar}>
                            {showStartBar ? <ChevronRight color={"rating5"} /> : <ChevronLeft color={"rating5"} />}
                        </div>}

                        {!isEmpty(stats) ? Object.keys(groupedStats).reverse().map((rating, ratingIndex) => {
                            const ratingItems = groupedStats?.[rating]
                            const { items, count, percent } = ratingItems

                            const color = ratingList[rating - 1]
                            const lightColor = lightRating[ratingIndex]
                            const prevColor = ratingList[rating]
                            const prevLightColor = lightRating?.[ratingIndex - 1]

                            // console.log("prevLightColor", rating + 1)

                            return (
                                <>
                                    <span style={{
                                        flexGrow:
                                            fullWidth ?
                                                1
                                                : 0
                                    }}>
                                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                                            <SortableContext
                                                // strategy={verticalListSortingStrategy}
                                                // id={ratingIndex}
                                                items={items}
                                            // strategy={verticalListSortingStrategy}
                                            >
                                                {items?.map((item, index) => {
                                                    const { hideStartBar, hideEndBar, key, hide, percent, count, current_test_taker } = item
                                                    const isSourceItem = ratingsObject[key] && (rating != 5 || index != 0)

                                                    const theme = {
                                                        textColor: ratingText[ratingIndex],
                                                        color: item.rating_id ? color : null,
                                                        prevColor: prevColor,
                                                        lightColor: lightColor,
                                                        prevLightColor: prevLightColor,
                                                        isDrag: isDrag,
                                                        isSourceItem: isSourceItem,
                                                        firstItem: ratingIndex == 0 && index == 0,
                                                        firstRatingItem: ratingIndex != 0 && index == 0
                                                    }


                                                    return (
                                                        ((!hideStartBar || showStartBar) && (!hideEndBar || showEndBar)) && <>
                                                            <Draggable key={key.toString()} id={key.toString()}
                                                                isDraggable={
                                                                    isDrag &&
                                                                    (ratingIndex != 0 &&
                                                                        index == 0
                                                                        //  || showAllChildren || (ratingsObject[key] && (rating != 5 || index != 0)
                                                                        // )
                                                                    )
                                                                }>
                                                                <div style={{
                                                                    // flexGrow: 1,
                                                                    width: ((ratingIndex != 0 && index == 0) || showAllChildren || (ratingsObject[key] && (rating != 5 || index != 0))) ? 42 : 5,
                                                                    display: "flex",
                                                                    flexDirection: "column"
                                                                }}>


                                                                    {
                                                                        isDrag &&
                                                                        <div style={{ width: "100%", height: "100%" }}>
                                                                            <span style={{ width: "100%", height: "100%", display: "flex" }}
                                                                            >
                                                                                <div style={{ width: "100%", height: "100%", display: "flex" }}>
                                                                                    <DraggableItem
                                                                                        theme={theme}
                                                                                        showChildren={showAllChildren}
                                                                                        hide={!(!index && !theme.firstItem)}
                                                                                        fixed={fixed}
                                                                                        colors={[
                                                                                            color,
                                                                                            color,
                                                                                            prevColor,
                                                                                            prevColor
                                                                                        ]}
                                                                                        value={key}
                                                                                    />
                                                                                </div>

                                                                            </span>
                                                                            {/* )
                                                                    }} */}
                                                                            {/* </Draggable> */}
                                                                            {/* {provided.placeholder} */}
                                                                        </div>
                                                                    }
                                                                    {showAllChildren &&
                                                                        <BarItem
                                                                            viewPercent={viewPercent}
                                                                            index={index}
                                                                            theme={theme}
                                                                            value={key}
                                                                            current_test_taker={current_test_taker}
                                                                            percent={percent}
                                                                            count={count}
                                                                            isDrag={isDrag}
                                                                            graphKey={graphKey}
                                                                            fixed={fixed}
                                                                            showAllChildren={showAllChildren}
                                                                            isPercent={isPercent}
                                                                        />
                                                                    }
                                                                </div>
                                                            </Draggable>
                                                            {isSourceItem && isDrag &&
                                                                <div style={{
                                                                    height: 15, transform: 'translateZ(0px)', flexShrink: 0, zIndex: 3
                                                                }}>
                                                                    <div style={{
                                                                        position: "absolute",
                                                                        right: 11,
                                                                        zIndex: 2
                                                                    }}>
                                                                        <DraggableItem
                                                                            theme={theme}
                                                                            showChildren={showAllChildren}
                                                                            zIndex={250}
                                                                            opacity={
                                                                                true
                                                                            }
                                                                            hide={!(!index && !theme.firstItem) && !isSourceItem}
                                                                            fixed={fixed}
                                                                            colors={[
                                                                                ratingList[ratingsObject[key].rating_index - 1],
                                                                                ratingList[ratingsObject[key].rating_index - 1],
                                                                                ratingList[ratingsObject[key].rating_index],
                                                                                ratingList[ratingsObject[key].rating_index],
                                                                            ]}
                                                                            value={key}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </SortableContext>
                                        </span>
                                    </span>

                                </>
                            )
                        }
                        ) :
                            <FlexCenter>
                                <Progress />
                            </FlexCenter>
                        }
                        {showArrows && <div style={{ height: "69px" }} onClick={setShowEndBar}>
                            {showEndBar ? <ChevronLeft color={"rating1"} /> : <ChevronRight color={"rating1"} />}
                        </div>}

                        {activeId &&
                            <DragOverlay>

                                {/* // ?
                                // <div style={{ height: 15, transform: 'translateZ(0px)', flexShrink: 0 }}>
                                // ratingsObject[activeId]
                                //  && (rating != 5 || index != 0)
                                && */}
                                <DraggableItem
                                    showChildren={showAllChildren}
                                    fixed={fixed}
                                    colors={[
                                        ratingList[stats[activeId].rating_index - 1],
                                        ratingList[stats[activeId].rating_index - 1],
                                        ratingList[stats[activeId].rating_index],
                                        ratingList[stats[activeId].rating_index]
                                    ]}
                                    value={destination || activeId}
                                />
                                {/* // : null */}
                            </DragOverlay>
                        }

                    </WrapGraph >
                    {/* )}
                    </Droppable> */}

                </DndContext>
                {/* <div style={{ marginTop: 20, display: "flex", width: "100%", columnGap: 10, justifyContent: "space-between" }}>
                    <div style={{ width: 40 }}>
                        <div className="half-circle"
                            style={{
                                background:
                                    `linear-gradient( -90deg, ${ratingList[0]}, ${ratingList[0]} 49%,  ${ratingList[0]} 51%, ${ratingList[0]} 51% )`
                            }}
                        >
                            <div style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", fontSize: 13, borderRadius: 30, height: 22, width: 22, background: "white" }}>
                                {last(groupedStats["1"]?.items)?.key}
                            </div>
                        </div>
                    </div>
                    {!isEmpty(stats) && Object.keys(groupedStats).map((rating, ratingIndex) => {
                        const currentStats = groupedStats?.[rating]
                        const { items, count, percent } = currentStats

                        const key = groupedStats[rating].items?.[0].key
                        const current_rating_index = stats?.[key].rating_index

                        const color = ratingList[rating - 1]
                        const prevColor = ratingList[rating]

                        console.log(ratingIndex)
                        return (

                            showFooter &&
                            <React.Fragment>

                                <div style={{ background: "#F5F9FF", height: "25px", width: "20%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex", columnGap: 10, width: "100%", justifyContent: "center" }}>
                                        {count ?
                                            <React.Fragment>
                                                <div>
                                                    {`${percent ? percent.toFixed(0) : percent}%`}
                                                </div>
                                                <div className='children-count'>
                                                    <div>
                                                        {count}
                                                    </div>
                                                    ילדים
                                                </div>
                                            </React.Fragment> :
                                            <div>אין ילדים</div>
                                        }

                                    </div>
                                </div>
                                <div style={{ width: 40 }}>
                                    <div className="half-circle"
                                        style={{
                                            background:
                                                `linear-gradient( -90deg, ${ratingIndex === 4 ? ratingList[ratingList.length - 2] : ratingList?.[ratingIndex]}, ${ratingIndex === 4 ? ratingList[ratingList.length - 2] : ratingList?.[ratingIndex]} 49%,  ${ratingIndex === 4 ? ratingList[ratingList.length - 2] : ratingList?.[ratingIndex + 1]} 51%, ${ratingIndex === 4 ? ratingList[ratingList.length - 2] : ratingList?.[ratingIndex + 1]} 51% )`
                                        }}
                                    >
                                        <div style={{ textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center", fontSize: 13, borderRadius: 30, height: 22, width: 22, background: "white" }}>
                                            {key}
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={{ height: 30, display: "flex", columnGap: 13, justifyContent: "center", paddingTop: 4, fontSize: 14 }}>
                                    {count > 0 &&
                                        <React.Fragment>
                                            <div className='children-count'>
                                                ילדים
                                                <div>
                                                    {count}
                                                </div>
                                            </div>

                                            <div>
                                                {`${percent ? percent.toFixed(0) : percent}%`}
                                            </div>
                                        </React.Fragment>
                                    }
                                </div> */}
                {/* </React.Fragment>
                        )
                    }
                    )}

                </div> */}
            </div>
        </ChildrenGraphStyles >
    )
}

export default StatsBar