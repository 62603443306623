import { Box, listClasses, menuItemClasses, MenuList, Paper, paperClasses, Popover, popoverClasses, Popper, tableClasses } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { thirdly3 } from 'styles/theme';
import { ScrollCss } from 'styles/GlobalCss'
import { height } from '@mui/system';
import { paginationH } from 'styles/theme';
import { toolbarH } from 'styles/theme';
import { tabsH } from 'styles/theme';
import { titleH } from 'styles/theme';


const TableStyles = muiStyled(Paper)(({ hideToolbar, theme, $systemUI, withTitle, withTabs, hidePagination }) => ({
    height: `calc(100% - ${hideToolbar ? 0 : toolbarH}px - ${withTabs ? tabsH : '0'}px - ${withTitle ? titleH : '0'}px)`,
    '.MuiToolbar-root': {
        display: "flex",
        justifyContent: "space-between"
    },
    '.MuiTableContainer-root': {
        height: `calc(100% - ${hidePagination ? 0 : paginationH}px - ${hideToolbar ? 0 : toolbarH}px)`
    },
    '.MuiTablePagination-root': {
        height: paginationH,
    },
    '.MuiTablePagination-actions': {
        transform: "rotate(180deg)",
        direction: "rtl"
    },
    '.MuiDataGrid-footerContainer': {
        display: hidePagination && 'none'
    },
    '.MuiTable-root': {
        border: `1px solid ${thirdly3} !important`
    }
}));

const ToolbarColumnStyles = muiStyled(Box)(({ theme, $systemUI, hidePagination }) => ({
    [popoverClasses.root]: {
        zIndex: "1300 !important",
        background: "beige"
    },
    [popoverClasses.paper]: {
        zIndex: "1300 !important",
        background: "beige",
    },

}));

const CellWithTooltipStyles = muiStyled(Box)(({ size }) => ({
    width: size === "sm" ? 25 : 35
}));

const PopperStyles = muiStyled(Popper)(({ }) => ({
    zIndex: 13,
    width: 240,
    [paperClasses.root]: {
        height: "100% !important"
    }
}));


const MenuStyles = muiStyled(MenuList)(({ theme, $systemUI, hidePagination }) => ({
    maxHeight: 400,
    overflow: "auto",
    '.MuiMenuItem-root': {
        height: "35px !important"
    }
}));


const PaperStyles = muiStyled(Paper)(({ theme, $systemUI, hidePagination }) => ({
}));

export { CellWithTooltipStyles, PaperStyles, PopperStyles, MenuStyles, TableStyles, ToolbarColumnStyles }



// const WrapDataGridTable = styled(DataGrid)`
// flex-grow: 1;
// background:${white} !important;
// border: 1px solid ${thirdly3} !important;

// // ${Scroll};
// //  overflow: auto !important;



// .MuiDataGrid-columnHeader:focus .MuiDataGrid-cell:focus{
// // .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
//     outline: none !important;
// }

// `
