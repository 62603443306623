import React, { useEffect, useRef, useState } from 'react'
import { ratingRQ } from 'store/hooks'
import { useSelector } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryClient as qc, statuses } from 'keys'
import { getAccuracyDistributionFetch, getAccuracyStatsByAssessmentFetch, getFactoredAccuracyDistributionFetch, getFactoredAccuracyStatsByAssessmentFetch, getFluencyDistributionFetch, getFluencyStatsByAssessmentFetch, getFluencyStatsFetch } from 'store/middlewares/rating.crud'
import { useParams } from 'react-router-dom'
import { ratingHooks } from '.'
import RatingPage from './ReportPage'

const WrapReportContext = () => {
    const [currentSection, setCurrentSection] = useState()
    const currentSectionRef = useRef()
    const queryClient = useQueryClient()

    const viewGraphBtns = ratingHooks.useViewGraphBtns()

    const [reportDisplay, setReportDisplay] = useState()
    const [viewGraph, setViewGraph] = useState({
        fluency: viewGraphBtns[0].key,
        accuracy: viewGraphBtns[0].key,
        factored_accuracy: viewGraphBtns[0].key
    })

    const params = useParams()
    const { assessment } = useSelector(state => state.assessment)
    const { testTaker } = useSelector(state => state.testTaker)

    const assessmentID = assessment.id || params?.assessmentID

    const { refetch: refetchScoreRating, data: scoreRating = {} } = ratingRQ.useGetScoreRatingMethod()

    const { data: accuracyStats, mutateAsync: refetchAccuracyStats } = useMutation({
        mutationKey: qc.accuracyStatsByAssessment,
        mutationFn: async (params) => {
            const res = await getAccuracyStatsByAssessmentFetch({ id: assessmentID, params })
            return res.data
        },
    })
    const { data: factoredAccuracyStats, mutateAsync: refetchFactoredAccuracyStats } = useMutation({
        mutationKey: qc.factoredAccuracyStats,
        mutationFn: async (params) => {
            const res = await getFactoredAccuracyStatsByAssessmentFetch({ id: assessmentID, params })
            return res.data
        },
    })
    const { data: fluencyStats, mutateAsync: refetchFluencyStats } = useMutation({
        mutationKey: qc.fluencyStats,
        mutationFn: async (params) => {
            const res = await getFluencyStatsByAssessmentFetch({ id: assessmentID, params })
            return res.data
        }
    })
    const { data: fluencyDistribution } = useQuery({
        queryKey: qc.fluencyDistribution,
        queryFn: async () => {
            let params = {
                categories: assessment.reading_assessment_category,
                status: statuses.inProgress,
                sub_status: statuses.wordsComplete,
                test_taker: testTaker.test_taker_id
            }
            const res = await getFluencyDistributionFetch({ id: assessmentID, params })
            return res.data
        },
        enabled: !!(testTaker?.test_taker_id && assessment.id),
        refetchOnWindowFocus: false
    })
    const { data: accuracyDistribution } = useQuery({
        queryKey: qc.accuracyDistribution,
        queryFn: async () => {
            let params = {
                categories: assessment.reading_assessment_category,
                status: statuses.inProgress,
                sub_status: statuses.wordsComplete,
                test_taker: testTaker.test_taker_id
            }
            const res = await getAccuracyDistributionFetch({ id: assessmentID, params })
            return res.data
        },
        enabled: !!(testTaker?.test_taker_id && assessment.id),
        refetchOnWindowFocus: false
    })
    const { data: factoredAccuracyDistribution, } = useQuery({
        queryKey: qc.factoredAccuracyDistribution,
        queryFn: async () => {
            let params = {
                categories: assessment.reading_assessment_category,
                status: statuses.inProgress,
                sub_status: statuses.wordsComplete,
                test_taker: testTaker.test_taker_id
            }
            const res = await getFactoredAccuracyDistributionFetch({ id: assessmentID, params })
            return res.data
        },
        enabled: !!(testTaker?.test_taker_id && assessment.id),
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if (assessment?.id) {
            refetchAccuracyStats(viewGraphBtns[0].params)
            refetchFactoredAccuracyStats(viewGraphBtns[0].params)
            refetchFluencyStats(viewGraphBtns[0].params)
            refetchScoreRating()
        }
    }, [assessment?.id])

    useEffect(() => {
        return (() => {
            queryClient.removeQueries([qc.factoredAccuracyDistribution, qc.fluencyDistribution, qc.accuracyDistribution])
        })
    }, [])

    const commonState = {
        scoreRating,
        factoredAccuracyStats, refetchFactoredAccuracyStats,
        accuracyStats, refetchAccuracyStats,
        fluencyStats, refetchFluencyStats,
        reportDisplay,
        currentSectionRef,
        fluencyDistribution,
        accuracyDistribution,
        factoredAccuracyDistribution,
        currentSection, setCurrentSection,
        viewGraph, setViewGraph,
        setReportDisplay
    }

    return (
        <ReportContext.Provider value={commonState}>
            <RatingPage />
        </ReportContext.Provider>
    )
}

export const ReportContext = React.createContext()
export default WrapReportContext