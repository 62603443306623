import { useMemo } from "react";
import { tableHooks } from "components/Table";
import { systems } from 'keys'


const useColumns = () => {
    const idCell = tableHooks.useIdCell()
    const testTakerNameCell = tableHooks.useTestTakerNameCell()

    const columns = useMemo(
        () => [
            idCell,
            {
                accessorKey: 'username',
                header: "שם משתמש",
            },
            {
                ...testTakerNameCell,
                header: "שם"
            },
            {
                accessorKey: 'email',
                header: "מייל",
            },
            {
                accessorKey: "systems",
                enableSorting: false,
                header: "מערכת",
                cell: info => info.getValue().map(item => systems[item])
            }
        ], []
    )

    return columns
}

export default useColumns