import { statuses } from 'keys'
import { done, error, secondary, yellow } from 'styles/theme';

const useStatuses = () => {
    const { inProgress, backToCutting, backToTranscription, backToMarking, uploaded, uploading, brokenFile, created } = statuses

    const mappingByStatus = {
        [inProgress]: {
            label: "הסתיים",
            border: done
        },
        [backToMarking]: {
            label: "הסתיים",
            border: done
        },
        [backToCutting]: {
            label: "הסתיים",
            border: done
        },
        [backToTranscription]: {
            label: "הסתיים",
            border: done
        },
        [uploaded]: {
            label: "הסתיים",
            border: done
        },
        [uploading]: {
            label: "בהעלאה...",
            border: yellow,
        },
        [created]: {
            label: "לא בוצע",
        },
        ["brokenFile"]: {
            label: "ביצוע חוזר",
            border: secondary
        },
        ["unComplete"]: {
            label: "לא בוצע",
            border: secondary
        },
        ["part"]: {
            label: "חלקי",
            border: yellow
        },
        ["complete"]: {
            label: "הסתיים",
            border: done
        }
    }

    return mappingByStatus
}

export default useStatuses