import { useCallback, useContext } from "react"
import { NormalizerContext } from "../NormalizerContext"
import { useQueryClient } from "@tanstack/react-query"
import qc from "keys/queryClient"


const useInitScoreRating = () => {
    const { scoreRatingSemesters, activeScoreItemStatus, setActiveScoreItemStatus, activeScoreRatingIndex, stats, setStats, scoreRatingOptions } = useContext(NormalizerContext)

    const queryClient = useQueryClient()

    const initScoreRating = useCallback(() => {
        setActiveScoreItemStatus(null)

        const nStats = []

        scoreRatingSemesters.data?.forEach(scoreRating => {
            let currentData = queryClient.getQueryData([qc.fluencyStats, scoreRating.grade, scoreRating.semester])
            nStats.push(currentData.graph)
        });

        setStats(nStats)
    }, [scoreRatingSemesters])

    return initScoreRating
}

export default useInitScoreRating