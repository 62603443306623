import React, { useContext } from "react";
import { WrapWordList, WrapCustomSection, WrapAddWord, WrapWord, Time, WrapResult, FabDetail, WrapWordContent, WrapWordText, WordItem, WrapSettings, Word, SelectWord, SelectTimePoint, PointTime } from './Style'
import { useSelector } from "react-redux";
import { incorrect, na } from "keys/types";
import { InsertionIcon, wordHooks, WordSettings } from ".";
import { WordsData } from "./hooks";
import { Add } from "@mui/icons-material";
import { IconButton } from "components/Form";
import { Tooltip } from "@mui/material";
import { errorHooks } from "./ErrorTypes";
import { WordsContext } from "./Words";
import { layoutTypes, updateType } from "keys";


const WordList = (props) => {
    const { words, settings } = props
    const { markOption, viewResults } = settings || {}
    const { viewResultsSettings } = WordsData()
    const { assessment } = useSelector(state => state.assessment)
    const { endPoint = {}, timePoint = {} } = assessment || {}

    const getTooltipTitle = errorHooks.useGetTooltipTitle()
    const wordInsertion = wordHooks.useInsertion()

    return (
        <WrapWordList layoutType={layoutTypes.row} $markOption={markOption}>
            {words?.map((word, index) => {
                const { removed, minorError, word_def_text, customStatus, transcription, status, word_def_fe_details } = word
                const { break_line } = word_def_fe_details || {}

                let renderData = true
                if (endPoint?.index)
                    renderData = index <= endPoint.index - 1//index starts with 1 do we need to raise 1

                if (!viewResults) renderData = true
                // if (!word.word_def_id) renderData = false

                const settingsValues = {
                    error: status === incorrect && !minorError,
                    minorError: minorError,
                    timePoint: timePoint?.word_def_id === word.word_def_id ? timePoint.value : "",
                    end: endPoint.word_def_id === word.word_def_id,
                    customStatus: customStatus,
                    removed: removed
                }

                const { error: errorVal, minorError: minorErrorVal, timePoint: timePointVal, end: endVal, customStatus: customStatusVal } = settingsValues

                const nextWord = words?.[index + 1 < words?.length ? index + 1 : index]

                let tooltipContent = getTooltipTitle(errorVal, minorError, removed, customStatus)

                if (renderData)
                    return (
                        word.word_def_id ?
                            <WrapWordContent key={index} >
                                <WordItem >
                                    {markOption &&
                                        <WordSettings
                                            index={index}
                                            word={word}
                                            settingsValues={settingsValues}
                                        />}
                                    {viewResults &&
                                        <WordSettings
                                            index={index}
                                            word={word}
                                            settings={viewResultsSettings}
                                        />}
                                    <WrapWord key={index}>
                                        <WrapWordText>
                                            <Tooltip title={tooltipContent} placement="top">
                                                <Word $removed={removed} $customStatus={customStatusVal} $minorError={minorErrorVal} $error={errorVal}>
                                                    {transcription || word_def_text}
                                                </Word>
                                            </Tooltip>
                                        </WrapWordText>
                                    </WrapWord>


                                </WordItem>
                                {markOption &&
                                    <WrapAddWord>
                                        <IconButton
                                            $bg
                                            {...wordInsertion}
                                            color={
                                                (nextWord.word_def_id || nextWord.update_type == updateType.delete)
                                                    ? "transparent" :
                                                    (nextWord.minorError ? "rating4" : "error")}
                                            action={() => wordInsertion.action(word, index)}
                                        />
                                    </WrapAddWord>}

                                {break_line && <div style={{ width: "100%" }} />}
                            </WrapWordContent>
                            :
                            !markOption &&
                            <div className="insertion-icon">
                                <InsertionIcon />
                            </div>
                    )
            })}
        </WrapWordList>
    )
}

export default WordList