import React from 'react'
import { AlertCrud } from "components/Alerts/hooks"
import { AbilityContext } from "components/Permissions/Can"
import { app } from "components/Router/routes"
import { queryClient as qc, alerts } from "keys"
import { isEmpty } from "lodash"
import { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as DashboardChildrenIcon } from 'assets/icons/DashboardChildren.svg';
import { ReactComponent as DashboardAssessmentIcon } from 'assets/icons/DashboardAssessment.svg';
import { ReactComponent as DashboardReportIcon } from 'assets/icons/DashboardReport.svg';
import { DashboardContext } from '../DashboardContext'

const useDashboardCards = () => {
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const { dashboardData } = useContext(DashboardContext)

    const goPerformAssessments = () => navigate(app.allAssessments)
    const goAssessments = () => navigate(app.assessments)
    const goPayments = () => navigate(app.payments)

    const { setAlerts } = AlertCrud()

    const openTestTakerPopup = useCallback(() => {
        setAlerts(alerts.createTestTaker, true)
    }, [])

    const noTestTakers = dashboardData.test_taker_count === 0
    const loading = isEmpty(dashboardData)

    const students = ability.can("read", "student-label") ? "תלמידים" : "ילדים"
    const student = ability.can("read", "student-label") ? "תלמיד" : "ילד"


    const cards = React.useMemo(
        () => [
            {
                id: 0,
                loading: loading,
                label: () =>
                    <div>
                        <div className='center'>{noTestTakers ? "אין לנו" : "יש לכם"}</div>
                        <div className='center bold'>{noTestTakers ? `את פרטי ${students} שלכם במערכת` : `${dashboardData.test_taker_count} ${students} במערכת`}</div>
                    </div>
                ,
                button: {
                    label: `להוספת ${student}`,
                    color: noTestTakers ? "secondary" : "light",
                    action: openTestTakerPopup
                },
                icon: DashboardChildrenIcon
            },
            {
                id: 1,
                loading: loading,
                label: () =>
                    dashboardData &&
                    <div>
                        <div className='center bold'>
                            {dashboardData.pending_assessments_count}{" "}
                            אבחונים
                        </div>
                        <div className='center'>
                            מחכים לביצוע
                        </div>
                    </div>,
                button: {
                    label: "לאיזור האבחונים",
                    color: noTestTakers ? 'light' : "secondary",
                    action: goPerformAssessments,
                    // blink: dashboardData.pending_assessments_count > 0,
                    disabled: !dashboardData.pending_assessments_count,
                    disabledColor: "gray2"
                },
                icon: DashboardAssessmentIcon
            },
            {
                id: 2,
                loading: loading,
                label: () =>
                    dashboardData &&
                    <div>
                        <div className='center bold'>
                            {dashboardData.marking_completed_count}{" "}
                            דוחות חדשים
                        </div>
                        <div className='center'>
                            מחכים לך באזור
                            הדוחות
                        </div>
                    </div>,
                button: {
                    label: "לצפיה בדוחות",
                    color: dashboardData.marking_completed_count ? "secondary" : 'light',
                    action: goAssessments,
                    disabled: !dashboardData.marking_completed_count,
                    disabledColor: "gray2"
                },
                icon: DashboardReportIcon
            },
            {
                id: 3,
                label: () =>
                    <div>
                        <div className='center'>
                            מומלץ לבדוק התקדמות
                        </div>
                        <div className='center bold'>
                            כל 3 חודשים
                        </div>
                    </div>,
                button: {
                    label: "לרכישת אבחון",
                    color: 'light',
                    disabled: true,
                    action: goPayments,
                    disabledColor: "gray2"
                },
                icon: DashboardReportIcon
            }
        ], [dashboardData]
    );

    return cards
}

export default useDashboardCards