import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { alerts, loading as loadingKeys } from 'keys';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { useCallback } from 'react';
import { AlertCrud } from 'components/Alerts/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { queryClient as qc } from 'keys';
import { app } from 'components/Router/routes';
import { useNavigate } from 'react-router-dom';

const useHeaderBtns = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { setAlerts } = AlertCrud()

    const { loading } = useSelector(state => state.general)
    const { selectedTestTakers } = useSelector(state => state.testTaker)

    const printAssessments = async () => {
        try {
            dispatch(actions.setLoading({ [loading.printAssessment]: true }))
            const pdfFile = await dispatch(actions.printAssessment(selectedTestTakers))
            window.open(pdfFile, "PRINT", "height=400,width=600");
            dispatch(actions.setLoading({ [loading.printAssessment]: false }))
        }
        catch {
            window.open(require('mock/Try.pdf'), "PRINT", "height=400,width=600");

            dispatch(actions.setLoading({ [loading.printAssessment]: false }))
            console.log("error printAssessment")
        }
    }

    const goAddChild = useCallback(() => {
        setAlerts(alerts.createTestTaker, true)
    }, [])

    const goBatteryAllocation = (page) => {
        // At first we thought to do if you chose only one examinee 
        // stand on it 
        
        // But it causes problems 
        // And there is no point in doing it
        // The purpose of the Assign Diagnoses button is to assign to many
        // And there is no special treatment if you only assigned a single test_test
        dispatch(actions.setTestTaker({}))
        navigate(`${app.createAssessments}`)
    }

    const headerBtns = [
        {
            id: 0,
            label: 'הדפס דו"ח',
            name: "report",
            action: printAssessments,
            component: "button",
            height: "35px",
            width: '120px',
            color: "rating1",
            disabled: isEmpty(selectedTestTakers),
            disabledColor: 'rating1',
            loading: loading[loadingKeys.printAssessment],
            disabled: true,
        },
        {
            id: 0,
            label: "הקצאת אבחונים",
            name: "battery",
            action: goBatteryAllocation,
            height: "35px",
            width: '140px',
            color: "dark",
            disabled: isEmpty(selectedTestTakers),
            disabledColor: 'dark',
            component: "button",

            feature: "bulk-create",//TODO move to json features
            role: "create",
            permissionHOC: "hidden",

            // customFeature: true,
            // feature: "create-testTkaer"
        },
        {
            id: 2,
            label: "+ הוספת תלמיד",
            action: goAddChild,
            color: "secondary",
            height: "35px",
        }
    ]

    return headerBtns
}

export default useHeaderBtns