import { useMemo } from "react";
import { tableHooks } from "components/Table";
import { systems } from 'keys'
import { IconButton, IconButtonGroup } from "components/Form";
import { CallMissedOutgoing, RemoveRedEyeOutlined, Replay } from "@mui/icons-material";
import { adminAssessmentsHooks } from "components/Admin/AdminAssessments";
import { currentDateTime, decimalToTime } from "utils/formatter";
import { markingWordsHooks } from "..";
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import { ReactComponent as GoIcon } from 'assets/icons/Go.svg';
import { ReactComponent as BackToIcon } from 'assets/icons/BackTo.svg';


const useColumns = () => {
    const selectAndViewAssessment = adminAssessmentsHooks.useSelectAndMarking()

    const { action, loading } = markingWordsHooks.useBackToConfirmation()

    const columns = useMemo(
        () => [
            {
                accessorKey: 'update_date',
                header: "עדכון אחרון",
                cell: info => currentDateTime(info.getValue()),
            },
            {
                accessorKey: 'marking_words_update_by_username',
                header: 'סומן ע"י',
            },
            {
                accessorKey: 'marking_words_duration',
                header: "משך סימון",
                cell: info => decimalToTime(info.getValue()),
            },
            {
                accessorKey: 'marking_words_start_date',
                header: " התחלת סימון",
                cell: info => currentDateTime(info.getValue()),
            },
            {
                accessorKey: 'marking_words_end_date',
                header: " סיום סימון",
                cell: info => `${currentDateTime(info.getValue()) || ""}`,
            },
            {
                accessorKey: 'enter_marking_words',
                header: "כניסה",
                enableSorting: false,
                cell: ({ row }) => {

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="לכניסה"
                            selected={true}
                            icon={GoIcon}
                            action={() => {
                                selectAndViewAssessment(row.original)
                            }}
                        />
                    );
                }
            },
            {
                accessorKey: 'view_marking_words',
                header: "צפייה",
                enableSorting: false,
                cell: ({ row }) => {

                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="לצפיה"
                            icon={VisibilityIcon}
                            selected={true}
                            action={() => {
                                selectAndViewAssessment(row.original)
                            }}
                        />
                        // <IconButton
                        //     icon={<RemoveRedEyeOutlined color='rating1' />}
                        //     label="Edit"
                        //     className="textPrimary"
                        //     action={() => {
                        //         selectAndViewAssessment(row.original)
                        //     }}
                        //     color="secondary"
                        // />
                    );
                }
            },
            {
                accessorKey: 'back_to_marking',
                header: "החזר לסימון",
                enableSorting: false,
                cell: ({ row }) => {

                    const disabled = loading
                    return (
                        <IconButtonGroup
                            color="bg"
                            tooltip="החזר לסימון"
                            icon={BackToIcon}
                            selected={!disabled}
                            disabled={disabled}
                            action={() => action(row.original, true)}
                        />
                    )
                }
            }
        ], []
    )

    return columns
}

export default useColumns