import { useCallback, useContext } from "react";
import { NormalizerContext } from "../NormalizerContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { saveScoreRatingMethodsFetch } from "store/middlewares/rating.crud";
import { last, uniqBy } from "lodash";
import { toast } from "react-toastify";
import { toastConfig } from "config";
import qc from "keys/queryClient";

const useApplyScoreRating = () => {
    const { stats, scoreRatingSemesters, activeScoreItemStatus, setActiveScoreItemStatus } = useContext(NormalizerContext)
    const queryClient = useQueryClient()

    const { isPending, mutateAsync: saveScoreRatingMethods } = useMutation({
        mutationFn: saveScoreRatingMethodsFetch,
        onSuccess: () => {
            toast('עדכון הנתונים בוצע בהצלחה', toastConfig)
        },
        onError: () => {
            toast.error("שגיאה", toastConfig)
        }
    })

    const saveScoreRating = useCallback(async () => {
        const nScoreRating = []
        console.log(scoreRatingSemesters)
        stats?.forEach((stat, index) => {
            const sourceStatsEntries = Object.entries?.(stat || {});
            const uniqueRatings = uniqBy(sourceStatsEntries, ([key, value]) => value.rating_id);// שימוש ב-Lodash כדי לשמור רק אובייקטים עם rating_id ייחודיים
            const keysArray = uniqueRatings
                .filter(innerArray => innerArray[1].rating_id !== null)
                .map(innerArray => ({ value: innerArray[0], id: innerArray[1].rating_id }));

            const rangesArray = keysArray.map((item, index) => {
                const min = item.value
                const max = keysArray[index + 1] ? keysArray[index + 1].value : item.value; // אם אין ערך הבא, נשתמש באותו ערך
                return { min, max, id: item.id };
            }).filter(range => range.min !== range.max); // מסנן טווחים שבהם min שווה ל-max
            if (!last(keysArray))
                debugger
            //the last rating always finish with 999
            else rangesArray.push({ min: last(keysArray).value, max: '999', id: last(keysArray).id })
            //the low rating always starts with 1
            rangesArray[0].min = '1'

            rangesArray.forEach(rating => {
                nScoreRating.push(rating)
            });

        });
        console.log(nScoreRating)

        setActiveScoreItemStatus(null)

        await saveScoreRatingMethods(nScoreRating)
        queryClient.invalidateQueries([qc.fluencyStats])
    }, [scoreRatingSemesters, stats])

    return saveScoreRating
}

export default useApplyScoreRating