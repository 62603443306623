import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, pick } from 'lodash'
import { WrapIndication, IndicationLabel } from './Style'
import { deviationRatingIndex, naLabel, ratingListLabel, ratingListTheme } from 'keys/rating'
import { gTheme } from 'styles/theme'
import { statuses } from 'keys'
import { Done, NotificationsNone, Remove } from '@mui/icons-material'

const RateAndAccuracyLabel = (props) => {
    const { row, column } = props
    const value = row.original
    const { meta } = column.columnDef
    const { rating, status } = value || {}
    const { scoreRatingMethods } = useSelector(state => state.rating)

    const indicationIcon = {
        "noraml": {
            icon: Done,
            color: "success"
        },
        "warning": {
            color: "error",
            icon: NotificationsNone,
        },
        "nodata": {
            icon: Remove,
            color: "ligthGray"
        }
    }

    const { warning, noraml, nodata } = indicationIcon

    const ratingFields = pick(rating, [meta?.id, meta?.index])
    let ratingIndex = ratingFields[meta?.index]
    let ratingId = ratingFields[meta?.id]
    let currentScoreRatingMethod

    if (status === statuses.brokenFile)
        currentScoreRatingMethod = {
            label: naLabel
        }

    else {
        if (isEmpty(scoreRatingMethods))
            currentScoreRatingMethod = {
                themeColor: ratingListTheme[ratingIndex - 1],
                label: ratingListLabel[ratingIndex - 1]
            }
        else {
            currentScoreRatingMethod = scoreRatingMethods?.[ratingId]
            ratingIndex = currentScoreRatingMethod?.index
        }
    }

    const indication = ratingIndex ? (ratingIndex <= deviationRatingIndex ? noraml : warning) : nodata

    return (
        <WrapIndication>
            <IndicationLabel color={gTheme?.[currentScoreRatingMethod?.themeColor]?.main || 'ligthGray'}>
                {currentScoreRatingMethod?.label}
            </IndicationLabel>
        </WrapIndication>
    )

}

export default RateAndAccuracyLabel