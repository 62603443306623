import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { queryClient as qc } from 'keys'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import { getGradesFetch, getTClassesFetch, getTestTakerFetch, getTestTakersFetch, getTestTakersOptionsFetch, getWithLastAssessmentsFetch, getAllGradesFetch, getLanguagesFetch } from "store/middlewares/testTakers.crud"
import { convertSelectToValue } from "utils/formatter"
import { getAffiliationsFetch } from "store/middlewares/affiliation.crud"

//get test_taker by ID
const useGetTestTaker = () => {
    const dispatch = useDispatch()
    const { testTaker } = useSelector(state => state.testTaker)
    const params = useParams()
    const testTakerID = isEmpty(testTaker) ? params.testTakerID : testTaker.test_taker_id

    return useQuery(
        {
            queryKey: qc.testTaker,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            refetchOnMount: false,
            refetchInterval: false,
            queryFn: async ({ signal }) => {
                const { data } = await getTestTakerFetch({ id: testTakerID, signal, })
                dispatch(actions.setTestTaker(data))
            }
        })
}

//get classes by params
const useGetTClassess = (formValues) => {
    const dispatch = useDispatch()
    const { headerFilters } = useSelector(state => state.header)
    let group, affiliation, grade

    const filterValues = formValues || headerFilters

    if (filterValues) {
        group = convertSelectToValue(filterValues.group)
        affiliation = convertSelectToValue(filterValues.affiliation)
        grade = convertSelectToValue(filterValues.grade)
    }

    return useQuery({
        queryKey: qc.tClassess,
        queryFn: () => getTClassesFetch(
            convertSelectToValue(filterValues)
        ),
        enabled: false
    })
}

//get affiliation by params
const useGetAffiliations = (formValues) => {
    const dispatch = useDispatch()
    const { headerFilters } = useSelector(state => state.header)
    let group, affiliation

    const filterValues = formValues || headerFilters

    if (filterValues) {
        group = convertSelectToValue(filterValues.group)
        affiliation = convertSelectToValue(filterValues.affiliation)
    }

    return useQuery({
        queryKey: qc.tClassess,
        queryFn: async () => {
            const { data } = await getAffiliationsFetch(
                convertSelectToValue(filterValues)
            )
            dispatch(actions.setAffiliations(data))
        },
        enabled: false
    })
}

//get grades by params
const useGetGrades = ({ enabled, formValues }) => {
    const { headerFilters } = useSelector(state => state.header)
    let group, affiliation

    const filterValues = formValues || headerFilters

    if (filterValues) {
        group = convertSelectToValue(filterValues.group)
        affiliation = convertSelectToValue(filterValues.affiliation)
    }

    return useQuery({
        queryKey: qc.grades,
        queryFn: async () => {
            const { data } = await getGradesFetch(
                convertSelectToValue(filterValues)
            )
            return data
        },
        refetchOnWindowFocus: false,
        enabled: enabled ? enabled : false,
        retry: 0,
    })
}

const useGetAllGrades = () => {
    return useQuery({
        queryKey: qc.allGrades,
        queryFn: async () => {
            const { data } = await getAllGradesFetch()
            return data
        },
        refetchOnWindowFocus: false,
        retry: 0
    })
}

const useGetLanguages = () => {
    const dispatch = useDispatch()

    return useQuery({
        queryKey: qc.languages,
        queryFn: async () => {
            const { data } = await getLanguagesFetch()
            dispatch(actions.setLanguages(data))
        },
        refetchOnWindowFocus: false,
        retry: 0
    })
}

//get test_takers by params
const useGetTestTakersOptions = (formValues) => {
    const dispatch = useDispatch()
    const { headerFilters } = useSelector(state => state.header)

    const filterValues = formValues || headerFilters

    return useQuery({
        queryKey: [qc.testTakers],
        queryFn: async () => {
            const { data } = await
                getTestTakersOptionsFetch(
                    convertSelectToValue(filterValues))
            dispatch(actions.setTestTakers(data))
            return data
        },
        enabled: true,
        refetchOnWindowFocus: false
    })
}

//get test_takers 
const useGetTestTakers = () => {
    return useQuery({
        queryKey: [qc.testTakers],
        queryFn: getTestTakersFetch,
        enabled: false
    })
}

const useUpdateOrCreateChildren = () => {

    return useMutation({
        // mutationFn: updateOrCreateChildrenFetch
    })
}

const useGetWithLastAssessments = () => {
    const { headerFilters } = useSelector(state => state.header)

    return useQuery(
        {
            queryKey: qc.getWithLastAssessments,
            queryFn: async ({ signal }) => {
                const { data } = await getWithLastAssessmentsFetch({ signal, headerFilters })
                return data
            },
            retry: 0
        })
}

export default { useGetLanguages, useGetAllGrades, useUpdateOrCreateChildren, useGetTestTakers, useGetAffiliations, useGetGrades, useGetWithLastAssessments, useGetTestTaker, useGetTClassess, useGetTestTakersOptions }