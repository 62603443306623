import { Typography } from "@mui/material"
import { ratingList } from "keys"
import { ratingListLabel } from "keys/rating"


const useFields = () => {
    const fields = [
        {
            field: 'index',
            headerName: "דירוג",
            width: 120,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ value }) => <Typography sx={{ color: ratingList[value - 1] }}>{ratingListLabel[value - 1]}</Typography>
        },
        {
            field: 'count',
            headerName: "מספר",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
        },
        {
            field: 'percent',
            headerName: "אחוז",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            valueGetter: ({ value }) => `${value?.toFixed(1)}%`
        }
    ]


    return fields

}

export default useFields