import axios from 'config/axiosConfig'

import api, { assessment, go, reading_assessment, assessments, report, reports, test_takers, admin_assessments, definitions, categories } from 'keys/apis';
import { keyBy, map } from 'lodash';
import { AssessmentVM } from 'models/VM';
import { assessmentUtils } from 'helpers';
import { semesters, statuses } from 'keys';
import setFilters from 'config/filters';

export const getAudioFileFetch = async ({ assessmentID, signal }) => {
    if (assessmentID)
        return await axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/${assessmentID}/download_audio_file/`,
            {
                signal,
                responseType: 'blob'
            })
}

export const getAssessmentsFetch = ({ model, page, search, sorting, headerFilters, assessmentFilters, statusFilters, testTaker, signal }) => {
    let params = assessmentUtils.getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params = {
        ...params, ...setFilters({ page, search, sorting, model })
    }
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}assessments/`, {
        signal,
        params: params
    })
}

export const bulkCreateByLevelFetch = (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}bulk_create_by_level/`, {
        ...values
    })
}

//for save assessment in transcription , now not in use
export const saveAssessmentFetch1 = ({ assessmentID, assessment }) => {
    if (assessmentID)
        axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${assessmentID}/save/`, {
            ...assessment
        })
}

export const saveAssessmentFetch = (assessment) => {
    if (assessment)
        axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${assessment.id}/update_performing_assessment/`, {
            ...assessment
        })
}

export const getAssessments = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_ASSESSMENTS') {
        const currentTestTaker = getState().testTaker.testTaker
        const { controllers } = getState().general
        const { headerFilters } = getState().header
        const { assessmentFilters, statusFilters } = getState().assessment

        const params = assessmentUtils.getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, currentTestTaker)

        controllers[api.assessments]()

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}reading_assessment/assessments/`, {
                params: params
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getAdminAssessmentsFetch = ({ model, page, sorting, search, signal, headerFilters, assessmentFilters, statusFilters, testTaker }) => {
    let params = assessmentUtils.getAssessmentFilters(headerFilters, assessmentFilters, statusFilters)
    params = {
        ...params, ...setFilters({ model, page, search, sorting })
    }
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}`, {
        signal,
        params: params
    })
        .then((res) => {
            const assessments = []
            res.data.results.forEach((assessment) => {
                assessments.push(new AssessmentVM(assessment))
            })
            return { ...res, data: { ...res.data, results: assessments } }
        })
        .catch((error) => error)
}

export const getDefinitionTypes = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_DEFINITION_TYPES') {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}definition_types/`)
                .then(response => {
                    resolve(keyBy(response.data, 'id'))
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getAssessmentDefs = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_ASSESSMENT_DEFS') {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}reading_assessment/definitions/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getAssessmentDefsFetch = ({ signal, headerFilters, assessmentFilters }) => {
    const params = assessmentUtils.getAssessmentFilters(headerFilters, assessmentFilters)

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}reading_assessment/definitions/`, {
        params: params
    })
}

export const getProgressIndication = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_PROGRESS_INDICATION') {
        const id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_progress_indication/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const printAssessment = ({ dispatch, getState }) => next => action => {
    if (action.type === 'PRINT_ASSESSMENT') {
        const id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}${test_takers}/${293}/get_report/`, {
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}

export const createAssessment = ({ dispatch, getState }) => next => action => {
    if (action.type === 'CREATE_ASSESSMENT') {
        let nAssessment = action.payload

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}create_instance/`, {
                ...nAssessment
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}

export const updateAssessmentStatus = ({ dispatch, getState }) => next => action => {
    if (action.type === 'UPDATE_ASSESSMENT_STATUS') {
        const { status, sub_status } = action.payload
        const { assessment: readingAssessment } = getState().assessment
        const { id = action.payload?.id } = readingAssessment || {}

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/update_status/`, {
                status: status,
                sub_status: sub_status
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const getDashboardFetch = ({ signal }) => {
    const currentYear = new Date().getFullYear()
    const currentSemester = assessmentUtils.getCurrentSemester()

    const params = {
        semester_and_year: `${currentSemester}_${currentYear}`
    }
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}dashboard/`, {
        signal,
        params: params
    })
}

export const allocationBattery = ({ dispatch, getState }) => next => action => {
    if (action.type === 'ALLOCATION_BATTERY') {
        let { test_takers, assessment_defs } = action.payload

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}bulk_create/`, {
                test_takers: test_takers,
                assessment_defs: assessment_defs
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const allocationBatteryFetch = ({ test_takers, assessment_defs }) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}bulk_create/`, {
        test_takers: test_takers,
        assessment_defs: assessment_defs
    })
}

export const deleteAssessmentFetch = ({ assessmentID, wordID }) => {
    return axios.delete(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${assessmentID}/delete_assessment/`)
}

export const getCategoriesFetch = () => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${categories}options/`)
}

export const getFrequencyFetch = ({ signal, factored, testTaker, headerFilters, assessmentFilters, statusFilters }) => {
    let params = assessmentUtils.getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params.status = statuses.inProgress
    params.sub_status = statuses.wordsComplete
    params.factored = factored

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_frequency/`, {
        signal,
        params: params
    })
}


export const getAbilitiesGroupFetch = ({ params }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}abilities_weight/`, {
        params: params
    })
}


export const getFrequencyByWordDefFetch = ({ signal, testTaker, headerFilters, assessmentFilters, statusFilters, word_def }) => {
    let params = assessmentUtils.getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params.word_def = word_def
    params.status = statuses.inProgress
    params.sub_status = statuses.wordsComplete

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}get_frequency_by_word_def/`, {
        signal,
        params: params
    })
}

export const getAssessmentReportFetch = ({ model, page, search, sorting, signal, testTaker, headerFilters, assessmentFilters, statusFilters }) => {
    let params = assessmentUtils.getAssessmentFilters(headerFilters, assessmentFilters, statusFilters, testTaker)
    params = {
        ...params, ...setFilters({ model, page, search, sorting })
    }
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${reports}/`, {
        signal,
        params: params
    })
}

export const uploadAudioFileFetch = ({ id, file, grade_of_allocation, file_uploaded_date }) => {

    let formData = new FormData();
    formData.append("record_file", file);

    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/upload_audio_file/`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
}

export const getAssessmentFetch = async ({ id, signal }) => {
    return await axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/`, {
        signal
    })
}


export const getAssessmentDefFetch = async ({ id, signal }) => {
    return await axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${definitions}${id}/`, {
        signal
    })
}


