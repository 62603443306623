import { Add } from "@mui/icons-material"
import { useContext } from "react"
import { WordsContext } from "../Words"
import { statuses, types, updateType } from "keys"
import { useQueryClient } from "@tanstack/react-query"
import { queryClient as qc } from 'keys'
import { addItem, changeItem, removeItem } from "utils/array"
import { actions } from "store"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const useInsertion = () => {
    const dispatch = useDispatch()
    const { words } = useSelector(state => state.words)
    // const { words } = useContext(WordsContext)
    const queryClient = useQueryClient();

    const addWord = (word, index) => {
        const nextWord = words?.[index + 1]
        let nWords

        // first click - insertion as error
        if (
            index + 1 == words.length ||
            nextWord.word_def_id ||
            (!nextWord.word_def_id && nextWord.update_type === updateType.delete)
        ) {
            const newWord = {
                transcription: "",
                status: types.incorrect,
                reading_assessment: word.reading_assessment,
                index: index + 1,
                update_type: updateType.update,
            }
            nWords = addItem(words, index + 1, newWord)
        }

        //second click - for insertion as minor error
        else if (!nextWord.word_def_id && !nextWord.minorError) {
            nWords = changeItem(words, index + 1, {
                ...nextWord, minorError: true
            })
        }

        //third click
        else {
            // let update_type = updateType.delete
            // if (nextWord.update_type == updateType.delete)
            //     update_type = updateType.update

            if (nextWord.id) {
                // nextWord.update_type = updateType.delete

                nWords = changeItem(words, index + 1, {
                    ...nextWord, minorError: false,
                    update_type: updateType.delete,
                    errors: []
                })
            }
            else {
                nWords = removeItem(words, index + 1)
            }


        }
        dispatch(actions.setWords(nWords))
    }

    const wordInsertion = {
        action: addWord,
        icon: <Add fontSize="small" />,
        size: "small",
        width: 30,
        height: 30
    }

    return wordInsertion
}

export default useInsertion