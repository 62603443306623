import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions } from 'store'

import { assessmentRQ, assessmentDefRQ } from 'store/hooks'

const Page = (props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const { assessment } = useSelector(state => state.assessment)

    const { } = assessmentDefRQ.useGetAssessmentDef()
    const { } = assessmentRQ.useGetAssessment()

    const audioFile = assessmentRQ.useGetAudioFile()
    const ass = assessmentRQ.useGetAssessment()

    const cleanData = () => {
        dispatch(actions.cleanTestTaker())
        dispatch(actions.cleanAssessment())
    }

    useEffect(() => {
        if (!isEmpty(assessment)) {
            if (!assessment.audio_file && assessment.audio_file_url) {
                audioFile.remove?.()
                audioFile.refetch?.()
            }
        }
    }, [assessment])

    useEffect(() => {
        if (params.testTakerID)
            dispatch(actions.editTestTaker({ test_taker_id: params.testTakerID }))
    }, [params.testTakerID])

    useEffect(() => {
        if (params.assessmentID)
            ass.refetch?.()
    }, [params.assessmentID])

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    return props.children

}
export default Page